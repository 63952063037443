/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonButton,IonRippleEffect, IonRow, IonImg, IonRouterLink, IonCard, 
         IonPage, IonProgressBar, IonCardTitle, IonTextarea } from '@ionic/react'

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpFrmItem      from '../form_components/cmp_frm_item'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'
import CodiceFiscale   from 'codice-fiscale-js'

export default class PageRegisterStepUser extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : 'gate.user.username',
                        name      : 'username',
                        value     : "",
                        type      : "email",
                        label     : "L_GATE_USER_USERNAME",
                        required  : true,
                        regex     : "email",
                        keyboard  : "email"
                    },
                    {
                        dn        : 'gate.user.personal_id',
                        name      : "personal_id",
                        value     : "",
                        type      : "personal_id",
                        label     : "L_GATE_USER_PERSONAL_ID",
                        error     : "",
                        required  : true,
                        regex     : "string-alphanumeric-capital",
                        min_value : 3,
                        max_value : 30,
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.user.name',
                        name      : "name",
                        value     : "",
                        type      : "name",
                        label     : "L_GATE_USER_NAME",
                        error     : "",
                        required  : true,
                        regex     : "string-proper-noun",
                        min_value : 3,
                        max_value : 30,
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.user.surname',
                        name      : "surname",
                        value     : "",
                        type      : "surname",
                        label     : "L_GATE_USER_SURNAME",
                        error     : "",
                        required  : true,
                        regex     : "string-proper-noun",
                        min_value : 3,
                        max_value : 30,
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.user.password',
                        name      : "password",
                        value     : "",
                        type      : "password",
                        label     : "L_GATE_USER_PASSWORD",
                        error     : "",
                        required  : true,
                        regex     : "password",
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.user.password2',
                        name      : "password2",
                        value     : "",
                        type      : "password",
                        label     : "L_GATE_USER_RPT_PASSWORD",
                        error     : "",
                        required  : true,
                        regex     : "password",
                        keyboard  : "text"
                    }
                ]
            },

            ready          : false,

            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.stepNumber = 0
        this.steps      = 4

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onValidate                   = this.onValidate.bind(this)
        this.nextStep                     = this.nextStep.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        if(!$a.isNull(this.props.history.location.state))
        {
            if(!$a.isNull(this.props.history.location.state.data))
            {
                this.state.form.fields[0].value = this.props.history.location.state.data.username
                this.state.form.fields[1].value = this.props.history.location.state.data.personal_id
                this.state.form.fields[2].value = this.props.history.location.state.data.name
                this.state.form.fields[3].value = this.props.history.location.state.data.surname
                this.state.form.fields[4].value = this.props.history.location.state.data.password
                this.state.form.fields[5].value = this.props.history.location.state.data.password2
            }
        }
    }

    onChange(el)
    {
        console.log("onChange",el)
        let value = el.target.value
        let id    = parseInt(el.target.id)
        let regex = $a.RegEx[this.state.form.fields[id].regex]

        if(el.target.name === "personal_id")
        {
            if(!$a.isNull(value))
            {
                value = value.toUpperCase()
                value = value.match(regex)

                if(!$a.isNull(value))
                {
                    value = value.join("")
                }
                else
                {
                    value = this.state.form.fields[id].value
                }
            }
        }

        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return
            }
        }

        for (const f of formFields)
        {
            if(f.name === "personal_id")
            {
                let cf = {}
                try{
                    let rcf = new CodiceFiscale(f.value)
                    cf  = new CodiceFiscale(rcf.toJSON())

                    console.log("CF", cf)
                }
                catch(e)
                {
                    cf.code=""
                }

                if(cf.code !== f.value)
                {
                    this.context.set("loading",false)

                    let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                    this.context.set("error",true)
                    this.context.set("error_message", msg)
                    this.state.ready = false
                    return
                }
            }
            else if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)
                
                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return
            }
        }

        this.state.ready = true
        console.log('Form is ready')
    }

    async nextStep(e)
    {
        e.preventDefault();
        e.stopPropagation();

        console.log("onLogin")

        this.onValidate(this.state.form.fields);

        if(this.state.ready === true)
        {
            let data = {}

            // if we have predefined data update them with this form data
            if(this.props.history.location.state !== undefined)
            {
                if(this.props.history.location.state.data !== undefined)
                {
                    data = this.props.history.location.state.data
                }
            }

            data.username          = this.state.form.fields[0].value
            data.personal_id       = this.state.form.fields[1].value
            data.name              = this.state.form.fields[2].value
            data.surname           = this.state.form.fields[3].value
            data.password          = this.state.form.fields[4].value
            data.password2         = this.state.form.fields[5].value
            data.settings          = {}
            data.settings.language = $a.String.formatLanguage(i18n.translator.language)

            this.props.history.replace({pathname: '/register/step/two', state: {data:data}})
        }
    }

    render()
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/>
                    <IonProgressBar value={this.stepNumber/this.steps} style={{maxWidth:500, height:10, borderRadius:5, margin:"auto"}}/>
                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_REGISTRATION.L_CREDENTIALS')}
                                </IonCardTitle>
                            </IonRow>

                            <CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>
                            <CmpFrmItem id="4" item={this.state.form.fields[4]} onChange={this.onChange}/>
                            <CmpFrmItem id="5" item={this.state.form.fields[5]} onChange={this.onChange}/>

                            <IonTextarea note style={{ padding:'2%', height:150 }} >
                                {i18n.t('L_PASSWORD_COMPLEXITY')}
                            </IonTextarea>

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_REGISTRATION.L_USER_DETAILS')}
                                </IonCardTitle>
                            </IonRow>

                            <CmpFrmItem id="2" item={this.state.form.fields[2]} onChange={this.onChange}/>
                            <CmpFrmItem id="3" item={this.state.form.fields[3]} onChange={this.onChange}/>
                            <CmpFrmItem id="1" item={this.state.form.fields[1]} onChange={this.onChange}/>

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.nextStep}>
                                    {i18n.t('L_CONTINUE')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>
                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonTextarea note style={{ padding:'2%', height: 80 }} >
                            {i18n.t('L_REGISTRATION.L_INFO') }
                            <a href={"mailto:" + i18n.t('SupportMail')}>{i18n.t('SupportMail')}</a>
                        </IonTextarea>

                        <p style={{fontSize:"small"}} className="ion-text-center">

                            <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                            <br/><br/>

                            {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                        </p>
                    </IonCard>

                    <br/>

                </IonContent>
            </IonPage>
        )
    }
}