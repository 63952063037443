/* global i18n:true, React:true,useContext:true */
import '../../global.js'

import { useState, useEffect } from 'react';


import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonBadge, IonCardContent,IonButton, IonIcon, IonContent } from '@ionic/react';
import {IonRow,IonCol} from '@ionic/react';
import {IonList,IonItem,IonLabel} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

import {MyContext} from './MyContext'

const Widget = ({height="420px",...props}) =>
{
    //const [list, setList] = useState([]);
    const myContext = useContext(MyContext);

    useEffect(() =>
    {
        async function onLoad(v)
        {
            await myContext.setTaskFilter("dm_task_status",v)
            console.log(3333333333,myContext.filterTaskData)

            await myContext.onFilterTaskData()
            console.log(3333333333,myContext.filteredTaskData)
        }
        onLoad(0)//pending
    },[]);


    function getItems()
    {
        console.log(myContext.filteredTaskData)

        let items = []
        let data  = myContext.filteredTaskData

        for (let i=0; i< data.length; i++)
        {
            items.push(
                <IonItem key={"task-widget-item-"+i}>
                    <IonLabel style={{fontSize:"80%"}}>{data[i].username},{data[i].address}</IonLabel>
                </IonItem>
            )
        }

        return items
    }

    return(
        <IonCard style={{height: "340px"}}>

            <IonCardHeader style={{}}>
                <IonCardTitle>{i18n.t("L_GRAPHS.L_APPOINTMENTS_PENDING")}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent style={{height:"60%"}}>
                <IonContent>
                    <IonList style={{ overflow: "hidden", overflowY: "auto"}}>
                       {getItems()}
                    </IonList>
                </IonContent>
            </IonCardContent>

            <IonRow>
                <IonCol>
                    <IonButton fill={"clear"} className={'ion-float-right'} href={"/Appointment/List"}>
                        {i18n.t("L_DETAILS")}
                        <IonIcon slot="end" icon={chevronForwardOutline} />
                    </IonButton>
                </IonCol>
            </IonRow>

        </IonCard>                   
    )
}

export default Widget