/* global $c:true, $a:true, i18n:true, React:true, AppContext:true, useContext:true*/

import '../../global.js'
import {MyContext} from './MyContext'

import {IonPage, IonContent, IonGrid, IonRow, IonCol,IonCard, IonCardContent } from '@ionic/react';

import {CbHeader} from '../cb.header'
import Table      from './Table'
import Filter     from './Filter'
import MyCalendar from './MyCalendar'

const Container =()=>
{
    const appContext = useContext(AppContext);
    const myContext  = useContext(MyContext);

    return (

        <IonPage>
        {appContext.showAs == "page" &&

            <CbHeader title ={i18n.t('L_TAB_BAR.L_APPOINTMENTS')} />
        }
            <IonContent >

            <IonGrid>
                <IonRow>
                    <IonCol size={2.5}>
                        <IonRow>
                            <IonCol>
                                <Filter/>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <MyCalendar />
                            </IonCol>
                        </IonRow>
                    </IonCol>

                    <IonCol size={9.5} >

                        <IonCard style={{}}>
                            <IonCardContent>
                                <Table />
                            </IonCardContent>
                        </IonCard>
                        
                    </IonCol>

                </IonRow>
            </IonGrid>

            </IonContent>

        </IonPage>
    )
}
export default Container