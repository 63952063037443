/* global $a:true, i18n:true, React:true, AppContext:true */
//import './global.js'

import { Plugins }            from '@capacitor/core'
import { Redirect, Route,withRouter }   from 'react-router-dom'
import { IonReactRouter }     from '@ionic/react-router'
import { IonApp, getPlatforms, IonRouterOutlet }        from '@ionic/react'
import qs                     from 'query-string'

import { routes }                 from './AppRoutes'
import AppTabs                    from './AppTabs'
import PageAppointmentUpdate      from './pages/appointment/status.update'
import PageLogin                  from './pages/authentication/page.login'
import PageRegisterStepUser       from './pages/authentication/page.register.step.user'
import PageRegisterStepCompany    from './pages/authentication/page.register.step.company'
import PageRegisterTradingAddress from './pages/authentication/page.register.step.address'
import PageRegisterStepHQAddress  from './pages/authentication/page.register.step.hqaddress'
import PageDocuments              from './pages/authentication/page.documents'
import PageVerifyEmail            from './pages/authentication/page.verifyemail'
import PageOTPApp                 from './pages/authentication/page.otp.app'
import PageOTPDelivered           from './pages/authentication/page.otp.delivered'
import PageForgotPassword         from './pages/authentication/page.forgot.password'
import PageChangePwdKey           from './pages/authentication/page.change.pwd.key'
import PageRegisterStepSummary    from './pages/authentication/page.register.step.summary'

class AppLoader extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()
     
        console.log("Component", this.constructor.name)

        this.state = 
        {
            ready : false,
            qst : {}
        }

        this.init              = this.init.bind(this)
        //this.checkToken        = this.checkToken.bind(this)
        this.checkLogin        = this.checkLogin.bind(this)

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    async init()
    {
        console.log(this.constructor.name, "init")

        //this.checkLogin()

        const { Network, App } = Plugins;

        let platform = await getPlatforms()
        await this.context.set("platform",platform[0])

        // let self = this

        let network = await Network.getStatus();
        await this.context.setState({
            connected     :network.connected,
            connectionType:network.connectionType
        })

        this.checkLogin()

        let self = this

        Network.addListener('networkStatusChange', (status) =>
        {
            console.log("Network status changed", status);
            
            self.context.setState({
                connected     :network.connected,
                connectionType:network.connectionType
            })

            this.checkLogin()
        })

        App.addListener('appStateChange', (status) =>
        {
            console.log("App status changed", status);
            if(status.isActive)
            {
                this.checkLogin()
            }
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    checkLogin ()
    {
        console.log(this.constructor.name, "checkLogin")

        if($a.isNull($a.Store.Get("token")))
        {
            this.context.set("loggedIn",false)
            this.setState({ ready: true})
            return
        }

        if(this.context.connected)
        {
            let self = this;

            var c1 = $a.Api.Call("Gate/User/ValidateToken/",{ token: $a.Store.Get("token") })

            c1.then(function(r) 
            {
                if(parseInt(r.error)===52)
                {
                    console.log      ("checkLogin","Token is not valid")
                    self.context.set ("loggedIn",false)
                    self.setState({ ready: true})
                }
                else if(r.result===true)
                {
                    console.log    ("checkLogin","Token is valid")
                    if(!$a.isNull(r.language) && r.language !== i18n.translator.language)
                    {
                        i18n.changeLanguage(r.language)
                    }
                    //self.onLoggedIn(true)
                    self.context.set("loggedIn",true)
                    self.context.set("pendingDocs", r.hasPendingDocuments == 1 ? true : false)
                    self.context.set("XaUser_ID", r.XaUser_ID)
                    self.setState({ ready: true})
                }
            }).catch(function(e)
            {
                //alert(5)
                self.context.set("loggedIn",false)
                console.log("loggedIn","Error validating Token")
                self.setState({ ready: true})

            });
        }
        else
        {
            this.context.set("loggedIn",true)
            this.setState({ ready: true})
        }
    }

    async getParams()
    {
        const { match, location, history } = this.props

        let qst = qs.parse(location.search)
        this.setState({qst: qst, pathname: location.pathname})
        let ref = qst.ref
        if(!$a.isNull(ref))
        {
            await this.context.set("referrer",ref)
        }
        let showAs = qst.showAs
        if(!$a.isNull(showAs))
        {
            await this.context.set("showAs",showAs)
        }
    }
    async componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name, this.props)
        await this.init()
        await this.getParams()
        
        this.setState({ready:true})
    }

    returnPage()
    {
        const routeComponents = routes.map(({path, component}, key) => <Route exact={true} path={path} component={component} key={key} />)

        if(this.state.pathname == "/Appointment/UpdateStatus")
        {
            return <PageAppointmentUpdate params={this.state.qst} callBack={() => this.setState({pathname:"/Home"})}/>
        }
        else if(this.context.loggedIn && !this.context.pendingDocs)
        {
            return <AppTabs routeComponents={routeComponents}/>
        }
        else if(this.context.loggedIn && this.context.pendingDocs)
        {
            return <PageDocuments/>
        }
        else
        {
            return(
                <IonRouterOutlet>

                    <Route path="/"                      component={PageLogin}                 exact={true} />
                    <Route path="/login"                 component={PageLogin}                 exact={true} />
                    <Route path="/register/step/one"     component={PageRegisterStepUser}      exact={true} />
                    <Route path="/register/step/two"     component={PageRegisterStepCompany}   exact={true} />
                    <Route path="/register/step/three"   component={PageRegisterStepHQAddress} exact={true} />
                    <Route path="/register/step/four"    component={PageRegisterTradingAddress}exact={true} />
                    <Route path="/register/step/five"    component={PageRegisterStepSummary}   exact={true} />
                    <Route path="/verifyemail"           component={PageVerifyEmail}           exact={true} />
                    <Route path="/otpapp"                component={PageOTPApp}                exact={true} />
                    <Route path="/otpdelivered"          component={PageOTPDelivered}          exact={true} />
                    <Route path="/forgotpassword"        component={PageForgotPassword}        exact={true} />
                    <Route path="/changepwdkey"          component={PageChangePwdKey}          exact={true} />

                    {/* <Route path="/*" render={() => <Redirect to="/" />}/> */}
                    {/* <Redirect exact from="/*" to="/" /> */}
                    <Route render={() => <Redirect to="/" />} />



                </IonRouterOutlet>

            )
        }
    }
    //, state: {token:$a.Store.Get("token")}})
    render ()
    {
    
        // setupConfig({
        //     rippleEffect: false,
        //     //mode: 'md'
        // });

        return(
            <>
            {this.state.ready ==true &&
                <IonApp>
                    <IonReactRouter>
                        {this.returnPage()}
                    </IonReactRouter>
                </IonApp>
            }
            </>
        )
    }
}

export default withRouter(AppLoader)
//export default (AppLoader)