/* global i18n:true, React:true, $a:true, $c:true,*/
import '../global.js'

import { IonCard, IonCardHeader, IonCardTitle, IonBadge, IonCardContent,IonButton, IonIcon } from '@ionic/react';
import {IonRow,IonCol} from '@ionic/react';

import {useState, useEffect} from 'react';

import { chevronForwardOutline } from 'ionicons/icons';
import { loadStripe } from "@stripe/stripe-js";


async function pressButton(price)
{
    const stripePromise = loadStripe($c.StripePublicKey);
    const stripe        = await stripePromise;

    let r = await $a.Api.Call("Payment/Movements/StripeSessionCreate/",{token:$a.Store.Get("token"), price:price})

    if ($a.Api.ErrorCheck(r))
    {
        this.setState({
            errorState   :true,
            errorCode    :r.error
        })
        return
    }

    const result = await stripe.redirectToCheckout({
        sessionId: r.result.stripeSessionId,
    });

    if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
    }
}

export const WidgetTopup = ({height="420px",...props}) =>
{
    const balance = props.accountBalance / 100
    const [count, setCount] = useState(0)
    const [color, setColor] = useState(0)

    var parsedUrl   = new URL(window.location.href)
    let topupresult = parsedUrl.searchParams.get('topupsuccess')

    let durationBalance = 0
    let diff            = 1
    let durationColor   = 0
    let startDiffColor  = 10

    if(topupresult === null)
    {
        // Visit home without topup -> increment balance, keep font black
        durationBalance = 20
        durationColor   = 0
        diff            = 1
        startDiffColor  = -10
    }
    else if (topupresult === "true")
    {
        // Visit home on successful topup -> increment balance, 
        // change font from black to green and back to black
        durationBalance = 20
        durationColor   = 30
        diff            = 1
        startDiffColor  = 5
    }
    else if (topupresult === "false")
    {
        // Visit home on failed topup -> show balance (no increments),
        // keep font black
        durationBalance = 0
        durationColor   = 0
        diff            = balance
        startDiffColor  = 0
    }

    useEffect(() => 
    {
        let start      = 0
        let startColor = 1
        let pauseColor = 0
        let diffColor  = startDiffColor

        const accountBalance = balance

        if(start === accountBalance)
        {
            return
        }

        // timer for font color changing
        let timerColor = setInterval(() => 
        {
            if(diffColor > 0)
            {
                // change color to green
                if(startColor > 255)
                {
                    startColor = 255
                    //diffColor = (-1) * diffColor
                    diffColor  = 0
                }
                else
                {
                    startColor += diffColor
                }

                setColor(startColor)
            }

            if(diffColor === 0)
            {
                // pause green for 2 seconds
                if (pauseColor < 2000)
                {
                    pauseColor += durationColor
                }
                else
                {
                    diffColor = (-1) * startDiffColor
                }
            }

            if(diffColor < 0)
            {
                // go back to black
                if(startColor > 0)
                {
                    startColor += diffColor
                }
                else
                {
                    startColor = 0
                }
                setColor(startColor)
            }

            if(startColor === 0)
            {
                clearInterval(timerColor)
            }
        }, durationColor)

        // timer for account balance changing
        let timerBalance = setInterval(() => 
        {
            if(start !== accountBalance)
            {
                start += diff
                setCount(start)
            }

            if(start === accountBalance)
            {
                clearInterval(timerBalance)
            }
        }, durationBalance)

    }, [balance])


    return(
        <IonCard style={{height: height}}>
                <IonCardContent style={{}} className={"ion-no-padding ion-padding-top"}>
                    <IonRow >
                        <IonCol className={"ion-no-padding"}>
                            <IonCard>
        
                                <IonCardHeader style={{}}>
                                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                        
                                        <IonCardTitle style={{width:"50%"}} >
                                            {i18n.t('L_ACCOUNT_BALANCE')}
                                        </IonCardTitle>
                                        
                                        <IonButton fill={"clear"} className={'ion-float-right'} href={"/Billing"}>
                                            {i18n.t('L_ACCOUNT_BALANCE_DETAILS')}
                                            <IonIcon slot="end" icon={chevronForwardOutline} />
                                        </IonButton>
                                    </div>
                                </IonCardHeader>
        
                                <IonCardContent>
                                    <IonBadge color="light" style={{fontSize:"175%",width:"100%", color:"rgb(0,"+color+",0)"}} className={"ion-padding"}>€ {(count).toFixed(2)}</IonBadge>
                                </IonCardContent>

                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard style={{}}>

                                <IonCardHeader style={{}}>
                                    <IonCardTitle>{i18n.t('L_ACCOUNT_BALANCE_TOPUP')}</IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent className={"ion-no-padding ion-padding-bottom"}>
                                    <IonRow class="ion-justify-content-around" >
                                        <IonButton fill={"outline"} size={"small"} onClick={() => pressButton($c.StripeTopUpKeys.EUR50)}>€ 50</IonButton>
                                        <IonButton fill={"outline"} size={"small"} onClick={() => pressButton($c.StripeTopUpKeys.EUR100)}>€ 100</IonButton>
                                    
                                        <IonButton fill={"outline"} size={"small"} onClick={() => pressButton($c.StripeTopUpKeys.EUR150)}>€ 150</IonButton>
                                        <IonButton fill={"outline"} size={"small"} onClick={() => pressButton($c.StripeTopUpKeys.EUR200)}>€ 200</IonButton>
                                    </IonRow>
                                </IonCardContent>

                            </IonCard>
                        </IonCol>
                    </IonRow>
        
                </IonCardContent>

            </IonCard>
    )
}

