/* global $c:true, $a:true, i18n:true, React:true, AppContext:true, useState:true,useContext:true */

import '../../global.js'
import {MyContext} from './MyContext'

import DataTable, { createTheme } from 'react-data-table-component';

import {IonLabel,IonRow,IonIcon,IonCol, IonCard, IonCardHeader, IonCardContent } from '@ionic/react'

import TableActions   from './TableActions'
import CmpErrorAlert  from './../cmp_error_alert'

import 
{   
    checkmarkDoneSharp as iconAccepted, 
    closeCircleSharp   as iconRejected, 
    alertCircleSharp   as iconPending,
    walkSharp          as iconByPerson,
    videocamSharp      as iconByVideo,
    mail               as iconMail,
    call               as iconCall,
    person             as iconPerson,
    calendar           as iconCalendar,
    home               as iconAddress
} 
from 'ionicons/icons';

import Moment from 'moment'
import MomentTZ from 'moment-timezone';

const Table = () =>
{
    const appContext = useContext(AppContext);
    const myContext = useContext(MyContext);
    let error_number = 0
    let error_state  = false

    function getAddress(appointment)
    {
        //console.log(appointment)
        return(
            $a.isEmpty(appointment.address)? " ": $a.Dom.decodeHTMLEntities(appointment.address)
        )
    }

    function getStatus(appointment)
    {
        let statusIcons = 
        {
            "0": { "name":iconPending , "color":'warning' },
            "1": { "name":iconAccepted, "color":"success" },
            "2": { "name":iconRejected, "color":"danger"  }
        }

        return(
            <IonIcon icon={statusIcons[appointment.dm_task_status].name} style ={{fontSize:24}} color={statusIcons[appointment.dm_task_status].color}/>
        )
    }

    function getType(appointment, showTitle)
    {
        let type      = i18n.t("TASK.D_TASK_TYPE."+appointment.dm_task_type)
        let stype     = $a.isEmpty(appointment.tools) ? "In Person" : "Video Chat"
        let stypeIcon = $a.isEmpty(appointment.tools) ? iconByPerson : iconByVideo

        let tools     = ""

        if(!$a.isEmpty(appointment.tools))
        {
            for (let i=0; i < appointment.tools.length; i++)
            {
                tools += appointment.tools[i]
                i+1 !=appointment.tools.length ? tools += ",":tools += ""
            }
        }

        return ( 
            <div style={{display:"flex",flexDirection:"column"}}>
                {showTitle &&
                <IonLabel className="ion-text-capitalize">{type}</IonLabel>
                }
                <IonLabel><IonIcon icon ={stypeIcon} color={"primary"} style={{paddingRight:"8px"}}/>{stype +" "+ tools}</IonLabel>
            </div>
        )
    }

    function getCustomer(appointment)
    {

        return(
            <div style={{display:"flex",flexDirection:"column"}}>
                <IonLabel>
                    <IonIcon icon ={iconPerson} color={"primary"} style={{paddingRight:"8px"}}/>
                    {(!appointment.username.includes("@") && appointment.username === "Unregistered") ? "User unregistered" : appointment.name}
                </IonLabel>
                <IonLabel>
                    <IonIcon icon ={iconMail} color={"primary"} style={{paddingRight:"8px"}}/>
                    {(!appointment.username.includes("@") && appointment.username === "Unregistered")  ? "User unregistered" :  appointment.username}
                </IonLabel>
                {!$a.isEmpty(appointment.phone_number) && appointment.phone_number != " " &&
                    <IonLabel>
                        <IonIcon icon ={iconCall} color={"primary"} style={{paddingRight:"8px"}}/>
                        {(!appointment.username.includes("@") && appointment.username === "Unregistered")  ? "User unregistered" :  appointment.phone_number}
                    </IonLabel>
                }
            </div>
        )
    }

    function getTime(appointment)
    {
        let a = appointment

        console.log(a.timezone)

        let start_date = a.start_date
        let start_time = a.start_time
        let timezone   = a.timezone

        let loc = ""
        
        if($a.isEmpty(a.tools))
        {
            // For physical visit always show the time in timezone of the property
            loc = Moment(start_date + " " + start_time)
        }
        else
        {
            // For virtual visit always show the time in timezone where user currently is
            let timezonedate    = MomentTZ.tz(start_date + " " + start_time, timezone)
            loc = Moment(timezonedate).local()
        }

        //console.log(loc)
        return Moment(loc).local().format("dddd, LL, HH:mm")
    }

    function onError(e)
    {
        error_state  = true
        error_number = e.error
        appContext.set("error_code", e.error)
        appContext.set("error",true)
    }

    function getActions(appointment)
    {
        return(
            <TableActions appointment={appointment} onError={(e) => onError(e)} />
        )
    }

    const columns =
    [
        {
            name     : i18n.t("TASK.TABLE.HEADERS.STATUS"),
            selector : row => getStatus(row),
            width    : "64px",
            cell     : (row) => <div style={{}}>{getStatus(row)}</div>
        },
        {
            name     : i18n.t("TASK.TABLE.HEADERS.TYPE"),
            minWidth : "150px",
            selector : row => row.type,
            cell     : (row) => <div>{getType(row, true)}</div>
        },
        {
            name     : i18n.t("TASK.TABLE.HEADERS.TIME"),
            selector : row => getTime(row),
            sortable : true,
            minWidth : "150px",
            cell     : (row) => <IonLabel> {getTime(row)} </IonLabel>
        },
        {
            selector : row => row.username,
            name     : i18n.t("TASK.TABLE.HEADERS.CUSTOMER"),
            minWidth : "300px",
            sortable : true,
            cell     : (row) => <div>{getCustomer(row)}</div>
        },
        {
            name     : i18n.t("TASK.TABLE.HEADERS.ADDRESS"),
            selector : row => row.address,
            sortable : true,
            minWidth : "200px",
            cell     : (row) => <IonLabel style={{textTransform: "capitalize"}} class="item-text-wrap"> {getAddress(row)} </IonLabel>
        },
        {
            name     : i18n.t("TASK.TABLE.HEADERS.ACTIONS"),
            selector : row => getActions(row),
            width    : "240px",
            cell     : (row) => <div>{getActions(row)}</div>
        }
    ]

    createTheme('solarized', {
        text: {
            primary: "var(--ion-text-color)"
            //secondary: '#2aa198',
        },
        background: {
            default: "var(--bbc-table-background-color)"
        },

        divider: {
            default: "var(--ion-color-medium)",
        }
    });

    const customStyles = 
    {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            }
        },
        headCells:
        {
            style: 
            {                
            textTransform:"uppercase"
            },
        },
        
        cells:
        {
            style: 
            {    
                lineHeight: "180%",
                //width  : "64px"
                //border:"1px solid green"
            },
        }
    };

    const ExpandableComponent = ({ data }) => 
    (
        <IonRow>
            <IonCol size={6}>
                <IonCard>
                    <IonCardHeader >
                        <div style={{display: "flex", justifyContent:"center", marginBottom:10}}>{getType(data, false)}</div>
                    </IonCardHeader>

                    <IonCardContent >

                        <IonRow>
                            <IonLabel>
                                <IonIcon icon ={iconCalendar} color={"primary"} style={{paddingRight:"8px"}}/>
                                {getTime(data)}
                            </IonLabel>
                        </IonRow>

                        <IonRow>
                            <IonLabel style={{textTransform: "capitalize"}}> 
                                <IonIcon icon ={iconAddress} color={"primary"} style={{paddingRight:"8px"}}/>
                                {getAddress(data)}
                            </IonLabel>
                        </IonRow>

                        <div style={{marginTop:20}}>{getCustomer(data)}</div>

                        <div style={{display: "flex", justifyContent:"center", marginBottom:10, marginTop:10}}>
                            {getActions(data)}
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    )

    return(
        <>
        <CmpErrorAlert errorState = {error_state} errorCode={error_number} errorMessage={""}/>
        <DataTable
            columns                 = {columns}
            data                    = {myContext.filteredTaskData}
            theme                   = "solarized"
            customStyles            = {customStyles}
            expandableRowsComponent = {<ExpandableComponent />}
            highlightOnHover
            overflowY
            noHeader
            fixedHeader
            pagination
            responsive
            expandableRows
        />
        </>
    )
}

export default Table