/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonButton,IonRippleEffect,IonItem,IonText, IonLabel, IonRow, IonImg, IonCheckbox, IonCard, IonPage} from '@ionic/react'

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'
import HtmlToReact     from 'html-to-react'

export default class PageLogin extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            isChecked      : false,
            isLoading      : false,
            documents      : [],
            documentID     : 0,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.getDocs                      = this.getDocs.bind(this)
        this.acceptDocument               = this.acceptDocument.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
        this.getDocs()
    }

    async getDocs()
    {
        this.context.set("loading",true)

        let r = await $a.Api.Call("Gate/Document/ReadPending/",{token:$a.Store.Get("token")})

        this.context.set("loading",false)

        if ($a.Api.ErrorCheck(r))
        {
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        if(r.result.length === 0)
        {
            this.context.set("pendingDocs", false)
        }

        this.setState({documents : r.result})
    }

    async acceptDocument()
    {
        let data = 
        {
            token          : $a.Store.Get("token"),
            XaDocument_ID  : this.state.documents[this.state.documentID].id
        }

        let r = await $a.Api.Call("Gate/Document/Accept/",data)

        if ($a.Api.ErrorCheck(r))
        {
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        if(this.state.documentID < this.state.documents.length - 1)
        {
            this.setState({documentID:this.state.documentID + 1, isChecked: false})
        }
        else
        {
            this.context.set("pendingDocs", false)
        }

        if(!$a.isNull(this.scrollRef))
        {
            this.scrollRef.scrollTop = 0
        }
    }

    render()
    {
        let document = ""
        if(!$a.isNull(this.state.documents))
        {
            if(this.state.documents.length > 0 && this.state.documentID < this.state.documents.length)
            {
                document =  $a.Dom.decodeHTMLEntities(this.state.documents[this.state.documentID].document)
            }
        }

        var htmlToReactParser = new HtmlToReact.Parser();
        var reactElement = htmlToReactParser.parse(document);

        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/><br/>
                    
                    <IonCard style = {{width:700,margin:"auto", marginBottom:150}} className="ion-justify-content-center">

                        <div 
                            ref = {ref => this.scrollRef=ref} 
                            id="privacy" 
                            style={{margin:20, overflowY: "scroll", height:300, borderWidth:1, borderColor:"#DEDEDE", borderStyle: "solid", borderRadius:5}}
                        >
                            <div id="privacy-2" style={{margin:10}} >
                                {reactElement}
                            </div>
                        </div>

                        <IonRow className="ion-justify-content-center">
                            <IonItem key={"checkbox"}>
                                <IonCheckbox slot="start" checked={this.state.isChecked} onClick={() => this.setState({isChecked:!this.state.isChecked})} />
                                <IonLabel className="ion-text-wrap">{i18n.t('L_TC_PART_1')}</IonLabel>
                            </IonItem>
                        </IonRow>

                        <IonRow className="ion-justify-content-center">
                            <IonButton color="primary" style={{width:"40%", marginTop:40, marginBottom:20 }} disabled={!this.state.isChecked} onClick={this.acceptDocument}>
                                {i18n.t('L_SUBMIT')}
                                <IonRippleEffect type="unbounded"></IonRippleEffect>
                            </IonButton>
                        </IonRow>
                    </IonCard>

                </IonContent>
            </IonPage>
        )
    }
}