/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */

import { IonButton,IonRippleEffect, IonRow, IonPage, IonContent, IonImg} from '@ionic/react';
import { IonCard,IonCardHeader,IonCardTitle, IonCardContent } from '@ionic/react'

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpFrmItem    from '../form_components/cmp_frm_item'
import CmpErrorAlert from '../cmp_error_alert'
import CmpLoading    from '../cmp.loading'


export default class PageForgotPassword extends React.Component
{
    static contextType = AppContext

    constructor(props)
    {
        super(props)

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : 'gate.user.username',
                        name      : 'username',
                        value     : "",
                        type      : "email",
                        label     : "L_GATE_USER_USERNAME",
                        required  : true,
                        regex     : "email",
                        min       : 5,
                        max       : 50,
                        keyboard  : "email"
                    }
                ]
            },
        };

        this.submit             = this.submit.bind(this);
        this.onChange           = this.onChange.bind(this);
    }


    onValidate()
    {
        for (const f of this.state.form.fields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                return
            }
        }

        for (const f of this.state.form.fields)
        {
            if(f.name === "username")
            {
                if(f.value.indexOf("@") < 0)
                {
                    // FRIMM users are not in format of email but emails are created by adding suffix "@replat.info"
                    f.regex = "email-prefix"
                }
                else
                {
                    f.regex = "email"
                }
            }

            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)
                
                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                return
            }
        }

        this.state.ready = true
    }

    submit()
    {
        this.onValidate();

        if(this.state.ready === true)
        {
            this.context.set("loading",true)

            let self = this;

            let username = self.state.form.fields[0].value
            if(username.indexOf("@") < 0)
            {
                username += "@replat.info"
            }

            let data = {
                username               : username,
            };

            var c1 = $a.Api.Call("Gate/User/ResetPassword/",data)
            c1.then(function(r) 
            {
                self.context.set("loading", false)

                if ($a.Api.ErrorCheck(r))
                {
                    self.context.set("error_code", r.error)
                    self.context.set("error"     , true)
                }
                else
                {
                    self.props.history.replace({pathname: '/changepwdkey', state: {data:data}})
                }

            }).catch(function(e)
            {
                //Ale.Ui.Loader.Hide();
            });
        }
    };

    onChange(e)
    {
        let value  = e.target.value;
        let id     = parseInt(e.target.id)

        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    render() 
    {
        return (

            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/><br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonCardHeader>
                            <IonCardTitle>{i18n.t("L_FORGOT")}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                            {i18n.t("L_FORGOT_T")}
                        </IonCardContent>

                        <IonCardContent>
                            <CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>

                            <br/>

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"120px", paddingTop:10}} onClick={this.submit}>
                                    {i18n.t('L_OK')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    }
}