//******************************************************************************
//
// Alessandro Mariotti
// alessandromariotti@hotmail.com
//
//******************************************************************************
import axios   from 'axios';
import config  from '../app.json';

var Ale = {};

Ale.RegEx =
{
    //STANDARDS
    "number-integer"              : /^[0-9-]+$/,
    "number-float"                : /^[0-9.-]+$/,
    "boolean-number"              : "[0-1]{1}",
    "string-alphanumeric"         : /[a-zA-Z0-9]+$/,
    "string-alphanumeric-capital" : /[A-Z0-9]+$/,
    "string-strict"               : "[^;:<>!?\"=\\/\\}\\{\\(\\)\\[\\]]*",//no script punctuation
    "string-free"                 : "(.*)",
    "string-proper-noun"          : /[a-zA-Z \'-]+$/,

    //SPECIALS
    "email-prefix"       : /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$/,
    "email"              : /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    "password"           : /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([a-z]|[A-Z]|[0-9]|[!#$%&()*+,.:;<=>?@-]){8,64})$/,
    "otp"                : "[0-9]{6}",
    "phone-number"       : "[0-9 .]{5,32}",
    "vat-number"         : "^(ATU[0-9]{8}|BE0[0-9]{9}|BG[0-9]{9,10}|CY[0-9]{8}L|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|(EL|GR)[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|HU[0-9]{8}|IE[0-9]S[0-9]{5}L|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10})$",
    "coordinate"         : "-?\\d*\\.{0,1}\\d{5,24}",
    "language-two-letter": "[a-z]{2}"
}

Ale.isNull = function (field)
{
    if (typeof field === "object")
    {
        if(field!=null && field!=undefined )
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    if (typeof field === "number" || typeof field === "boolean")
    {
        field = field.toString();
    }
    else if(typeof field === "string")
    {
        field = field.toLowerCase();
    }

    if(field!=="" && field!==undefined && field!==null && field.toUpperCase()!=="UNDEFINED" && field.toUpperCase()!=="NULL")
    {
        return false;
    }
    else
    {
        return true;
    }
};

Ale.isEmpty = function(obj)
{
    for(var key in obj)
    {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}




//******************************************************************************
//
// DATA VALIDATION
//
//******************************************************************************

Ale.Form = function (){};

Ale.Form.Get = function (class_name,json_form)
{
    let fields_for_form_array = []
    let fields_array = json_form

    for (let i=0; i<fields_array.length; i++)
    {
        let field_for_form = {}
        let my_field = fields_array[i]

        field_for_form.dn        = my_field.dn
        field_for_form.name      = Ale.String.ReplaceAll(my_field.dn, class_name + ".", "")
        field_for_form.value     = ""
        field_for_form.type      = my_field.type
        field_for_form.label     = "L_" + Ale.String.ReplaceAll(my_field.dn.toUpperCase(), ".", "_")

        field_for_form.required  = parseInt(my_field.required)
        field_for_form.regex     = my_field.reg_exp
        field_for_form.regex_msg = "R_" + Ale.String.ReplaceAll(my_field.dn.toUpperCase(), ".", "_")
        field_for_form.min_value = my_field.min_value
        field_for_form.max_value = my_field.max_value

        fields_for_form_array.push(field_for_form)
    }

    return fields_for_form_array
}

/**
Check the required fields for a form
 
OBJECT FORM:

    form:
    {
        fields :
        [
            {
                name      : "username",
                value     : "",
                type      : "",
                label     : "L_EMAIL",
                required  : true,
                regex     : "none",
                regex_msg : "R_EMAIL",
                value_min : 5,
                value_max : 50
            },
            {
                name     : "password",
                value    : "",
                type     : "",
                label    : "L_PASSWORD",
                error    : "",
                required : true
            },
            ...
        ]
    }

 */

Ale.Form.Validate = function(){};
/**
Check the required fields for a form
 
OBJECT FORM:

    form:
    {
        fields :
        [
            {
                name      : "username",
                value     : "",
                type      : "",
                label     : "L_EMAIL",
                required  : true,
                regex     : "none",
                regex_msg : "R_EMAIL",
                value_min : 5,
                value_max : 50
            },
            {
                name     : "password",
                value    : "",
                type     : "",
                label    : "L_PASSWORD",
                error    : "",
                required : true
            },
            ...
        ]
    }

 */


Ale.Form.Validate.Size = function(el)
{

    //console.log("Ale.Form.Validate.Size -> ","dn:",el.dn,"name:",el.name,"min_value:",el.min_value,"max_value:",el.max_value,"regex:",el.regex,"value:", el.value)

    if(typeof el.value === 'number' || el.type === 'number')
    {
        console.log("Ale.Form.Validate.Type","isNumber");
        
        if(el.value < el.min_value || el.value > el.max_value)
        {
            return false
        }
    }
    else
    {
        console.log("Ale.Form.Validate.Type","isString");

        if(el.value.length < el.min_value || el.value.length > el.max_value)
        {
            return false
        }
    }

    return true
}

Ale.Form.Validate.Required = function(el)
{
    console.log("Ale.Validate.Required -> ", el)

    if (el.required==true && Ale.isNull(el.value))
    {
        //console.log("Ale.Validate.Required ", false)
        return false
    }
    else
    {
        return true
        //console.log("Ale.Validate.Required", true)
    }
}

Ale.Form.Validate.Type = function (el)
{
    console.log("Ale.Form.Validate.Type -> ",el)

    if(el.hasOwnProperty('required'))
    {
        if(!el.required && Ale.isNull(el.value))
        {
            return true
        }
    }

    if(el.hasOwnProperty('min_value') && el.hasOwnProperty('max_value'))
    {
        if(!Ale.isNull(el.min_value) && !Ale.isNull(el.max_value))
        {
            if(!Ale.Form.Validate.Size(el)) return false 
        }
    }

    if(Ale.isNull(Ale.RegEx[el.regex]))
    {
        console.log("Ale.Form.Validate.Type -> ","Regex not valid:",el.regex);
        //alert("the regex does not exist" + el.regex)
        return false
    }

    if(!RegExp(Ale.RegEx[el.regex]).test(String(el.value)))
    {
        return false
    }

    return true
}


/**
 * 
    var t=Ale.Sleep(5000);
    t.then(function() {
        console.log("ciao");

    });
 *
 */

Ale.Sleep =function (ms)
{
    return new Promise(resolve => setTimeout(resolve, ms));
};

//******************************************************************************
//
// DOM FUNCTIONS
//
//******************************************************************************
Ale.Dom = function(){};

Ale.Dom.decodeHTMLEntities = function (text)
{
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"'],
        ['#58',':'],
        ['#46','.']
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
    {
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);
    }

    return text;
}


/*
Ale.Dom.AddContent = function (selector,content)
{
    $(selector).html(content);
    Ale.Label.Parse(selector);
};
*/

//******************************************************************************
//
// API FUNCTIONS
//
//******************************************************************************

Ale.Api = function(){};


Ale.Api.Get = async function (Url,Data)
{
    console.log(`Ale.Api.Get: Action -> ${Url} - Data -> ${Data}`);

    if(Ale.isNull(Data))
    {
        Data="";
    }

    try
    {
        return axios.get(Url + Data)
        .then(function(response){

             console.log(response.data);
             return response.data;
         })
         .catch(function (error) {
             console.log(error);
             return error.response.data;
         });

    }
    catch (e)
    {
        console.log("Ale.Api.Get: ERROR -> "+ Url + " Code: " + e.code + " Message: " + e.message);
        throw new Error("Error-Ajax");
    }
  }


/**
 * Funzione per effettuare una chiamata alle API di AleMicro2
 *
 * ESEMPIO:
 *
 *      var p = Ale.Api.Call("Api",{"data":"data"} );
 *
 *      p.then(function(result) {
 *          console.log(JSON.stringify(result));
 *      });
 */

Ale.Api.Call = async function (Action,Data,Url="")
{
    if(Ale.isNull(Data))
    {
        Data="{}";
    }

    //var t0 = performance.now();
    try
    {
        let url = ""
        if(Url==="")
        {
            url = config.ApiUrl+Action
        }
        else
        {
            url = Url+Action
        }

        return axios.post(url, Data)
        .then(function(response){

           // if(!Ale.CheckNull(response.headers.location==""))
            //{
              //  console.log("redirecting",JSON.stringify(response.headers.location));
                //window.location = response.headers.location;
            //}
            //else
            //{
            console.log(Action, Data, response.data );
            
            return response.data;
            //}
        })
        .catch(function (error) {
            console.log(error);
            //Ale.Ui.Loader.Hide();
            return error.response.data;
        });
    }
    catch (e)
    {
        //Ale.Ui.Loader.Hide();
        console.log("Ale.Api.Call: ERROR -> "+ Action + " Code: " + e.code + " Message: " + e.message);
        throw new Error("Error-Ajax");
    }

//    var t1 = performance.now();
  //  var dt = (t1 - t0);

    //console.log("Ale.Api.Call: Ex Time -> " + dt);
}

/**
 * Gestisce l'errore nelle risposte API visualizzando il messaggio
 * contenuto nel file label e lanciando una eccezione
*/

Ale.Api.ErrorHandle = function (result)
{
    var ErrorNumber="";
    var ErrorDescription="";

    //$.each( result, function( key, val ) {
    for (var key in result)
    {
        if (key==="error")
        {
            console.log("Ale.Api.ErrorHandle: ERROR: Key -> " + result.error + " Description ->" + result.description);

            ErrorNumber=result.error;
            ErrorDescription=result.description;
        }
    };

    if(ErrorNumber!=="")
    {
        Ale.Ui.Loader.Hide();
        Ale.Label.Get("Ale.Api.ErrorHandle: Error-"+ErrorNumber);
        //myApp.dialog.alert(Ale.Label.Get("Error-"+ErrorNumber),config.MyApp["name"]);
        throw new Error("Error: "+ErrorNumber+"::"+ErrorDescription );
    }
};


/**
 * Controlla se c'è un errore nella risposta API
 * ma non lo gestisce
*/

Ale.Api.ErrorCheck = function (result)
{
    if(result.error!==undefined) {

        console.log("Ale.Api.ErrorCheck: Error -> ");
        console.log(result.error);
        console.log("Ale.Api.ErrorCheck: Description -> ");
        console.log(result.description);

    return true;
    }
    else
    {
        return false;
    }
};

Ale.String = function() {};

Ale.String.ReplaceAll = function(str, to_replace, replace_with, ignore)
{
    //console.log("AleReplaceAll: "+str +" -> "+ to_replace + " -> " + replace_with);

    return str.replace(new RegExp(to_replace.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(replace_with)=="string")?replace_with.replace(/\$/g,"$$$$"):replace_with);
};

Ale.String.FormatCurrency = function(s)
{
    var b="";
    if(!Ale.isNull(s))
    {
        b = s.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    //Ale.Log("AleFormatCurrency: " + s + " -> "+ b);
    return b;
}

Ale.String.formatLanguage = function(inLang)
{
    if      (inLang == "it") { inLang = "it-IT" }
    else if (inLang == "en") { inLang = "en-UK" }

    return inLang
}

Ale.Store = function() {};

Ale.Store.Set = function (key,value)
{
    window.localStorage.setItem(key, value)
}

Ale.Store.Get = function (key)
{
    var v = window.localStorage.getItem(key)
    return v
}

Ale.Store.Remove = function (key)
{
    window.localStorage.removeItem(key);
}

Ale.Store.ClearAll = function ()
{
    window.localStorage.clear()
}

//******************************************************************************
//
// DATETIME FUNCTIONS
//
//******************************************************************************
/*
Ale.DateTime = function(){};

Ale.DateTime.GetTodayAsYYYYMMGG = function(separator)
{
    var today = new Date().toJSON().slice(0,10).replace(/-/g,separator);
    console.log("Ale.DateTime.GetTodayAsYYYYMMGG: Date: "+today);
    return today;
}

Ale.DateTime.GetNextDayAsYYYYMMGG = function(date,separator)
{
    var day = new Date(date);
    var nextDay = new Date(day);

    nextDay.setDate(day.getDate()+1);
    var n= nextDay.toJSON().slice(0,10).replace(/-/g,separator);

    console.log("Ale.DateTime.GetNextDayAsYYYYMMGG: Date: "+date + " Next: "+n);

    return n;
}

Ale.DateTime.GetPrevDayAsYYYYMMGG = function(date,separator)
{
    var day = new Date(date);
    var nextDay = new Date(day);

    nextDay.setDate(day.getDate()-1);
    var n= nextDay.toJSON().slice(0,10).replace(/-/g,separator);

    console.log("Ale.DateTime.GetNextDayAsYYYYMMGG: Date: "+date + " Next: "+n);

    return n;
}

Ale.DateTime.GetTime = function(separator)
{
    var d = new Date();
    var h = d.getHours();
    var m = d.getMinutes();
    
    if(h<10)
    {
        h="0"+h;
    }
    
    if(m<10)
    {
        m="0"+m;
    }
    
    var t = h + separator + m;
    
    console.log("Ale.DateTime.GetTime: " + t);

    return t;    
};


Ale.DateTime.ConvertDate = function(date,format)
{


 //  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    var s = date;
    var t, d = null;

    t = s.split(/[- :]/);
    d = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);

    if (window.Intl && typeof window.Intl === 'object')
    {
        if(format =="DDMMYYYY")
        var options = { day: 'numeric',month: 'numeric', year: 'numeric'};
        d = d.toLocaleDateString(navigator.language,options);
    }
    else
    {
        d = d.toLocaleDateString();
    }

    console.log("Ale.DateTime.ConvertDate: " + this + " -> " + d);

    return d;


}
*/
/*
 * da :
 * 
 * 2018-10-22
 * 
 * a:
 * 22-10
 * 
 * Nella lingua del browser
 * 
 */
/*
Ale.DateTime.GetDateAsDDMM = function(date)
{
    var s = date;
    var t, d = null;

    t = s.split(/[- :]/);
    d = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);

    if (window.Intl && typeof window.Intl === 'object')
    {
        var options = { month: 'numeric', day: 'numeric'};
        d = d.toLocaleDateString(navigator.language,options);
    }
    else
    {
        d = d.toLocaleDateString();
    }

    console.log("AleToDate: " + this + " -> " + d);

    return d;
};
*/
//******************************************************************************
//
// LABELS FUNCTIONS
//
//******************************************************************************
//Ale.Label = function() {};

/**
 * Recupera il valore di una label :
 *
 * name Label name
 *

Esempio di una label:

"App/Property/Share/Message"

Esempio di chiamata:
Ale.Label.Get(App/Property/Share/Message");

 *
 */
/*
Ale.Label.Get = function (name) {

    //console.log("Label Name : "+name);
    //console.log("Label Value: "+label[name]);

    if(label[name]!==undefined) {

        return label[name];

    } else if(label[name.charAt(0).toUpperCase() + name.slice(1)]!==undefined) {

        return label[name.charAt(0).toUpperCase() + name.slice(1)];

    } else {

        console.log("Ale.Label.Get: Error -> Label doesn't exist: "+name);
        return name;
    }
};

Ale.Label.Domain = function() {};

*/
/**
 * Recupera il valore di una label da un dominio:
 *
 * domain Domain name
 * id valore dell'opzione
 *
Esempio di donini di label:

 "is_active":
 [
     {"value":"1","title":"Attivi"},
     {"value":"0","title":"Non Attivi"}
 ],

Example:
Ale.Label.Domain.getTitleByValue("is_active",1);
 *
 */
/*
Ale.Label.Domain.getTitleByValue = function (domain,id)
{
    var labels = label[domain];

    if(labels!==undefined) 
    {

        for (var i = 0; i<labels.length;i++)
        {
            if (labels[i].value == id)
            {
                console.log("Ale.Label.GetFromDomain domain : "+ domain +" For id: " + id +" value: " + labels[i].title);
                return labels[i].title;
            }
        }

        console.log("Ale.Label.GetFromDomain domain : "+ domain +" For id: " + id +" value: undefined");
        return domain+"/"+id;
    }
    else
    {
        console.log("Ale.Label.GetFromDomain domain does not exist: "+ domain);
    }
};
/*
Ale.Label.Domain.getValueByTitle = function (domain,title)
{

    let labels = label[domain];

    if(labels!==undefined) 
    {
        for (let i = 0; i<labels.length;i++)
        {
            if (labels[i].title.toLowerCase() == title.toLowerCase())
            {
                console.log("Ale.Label.Domain.getValueByTitle domain : "+ domain +" For title: " + title +" title: " + labels[i].value);
                return labels[i].value;
            }
        }

        console.log("Ale.Label.Domain.getValueByTitle domain : "+ domain +" For title: " + title +" value: undefined");
        return domain+"/"+title;
    }
    else
    {
        console.log("Ale.Label.Domain.getValueByTitle domain does not exist: "+ domain);
    }
};


Ale.Label.Parse = function (selector)
{
    var d="*";

    if (!Ale.isNull(selector))
    {
        d=selector;
    }

    let myFields = document.querySelectorAll("[data-ale-label]");

    for(var i=0;i<myFields.length;i++)
    {
        
        let tn = myFields[i].tagName;
        let lb = myFields.item(i).getAttribute("data-ale-label");

        if(tn == "DIV")
        {
            //div,option,a,th,td,li
            myFields.item(i).innerHTML=Ale.Label.Get(lb);
        }
    }
};
*/
export default Ale