/* global $a:true, i18n:true, React:true */
import '../../global.js'
import AppContext from '../../AppContext'
import { IonToggle, IonItem,IonLabel } from '@ionic/react';

/*


<CmpFrmItem key="0" item={this.state.form.fields[0]} onChange={this.onChange}/>


{
    dn        : 'gate.user.username',
    name      : 'username',
    value     : "",
    type      : "email",
    label     : "L_GATE_USER_USERNAME",
    required  : true,
    regex     : "email",
    regex_msg : "R_GATE_USER_USERNAME",
    value_min : 5,
    value_max : 50,
    keyboard : "email",
}
*/

/*
    keyboard -> "decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url" | undefined
    type     -> "date" | "email" | "number" | "password" | "search" | "tel" | "text" | "time" | "url"
*/


class CmpFrmItem extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            position : "stacked" //floating fixed stacked
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
    }

    render()
    {
        return (
            <IonItem>
                <IonToggle
                        key           = { this.props.id }
                        id            = { this.props.id }
                        dn            = { this.props.item.dn }
                        checked       = { this.props.item.checked }
                        disabled      = { this.props.item.disabled }
                        required      = { this.props.item.required }
                        color         = "primary"
                        onIonChange   = { this.props.onChange }
                        onIonBlur     = { this.props.onDismiss }
                />

                <IonLabel 
                        key = {"label-"+this.props.id}
                        //position = { this.state.position}
                >
                        { i18n.t(this.props.item.label) }
                        
                        { //this.props.item.required &&
                            //<ion-text color="danger">&nbsp;{i18n.t('L_REQUIRED_SIGN')}</ion-text>
                        }

                </IonLabel>

            </IonItem>
        )
    }
}

export default CmpFrmItem