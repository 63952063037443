/* global $a:true, React:true, AppContext:true, i18n:true, $c:true*/

import { Route, Redirect }  from 'react-router-dom'

import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import {home, calendar,business, card } from 'ionicons/icons';

export default class AppTabs extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state = 
        {
            ready : false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name, this.props)
    }

    render ()
    {
        return(
        <>
            {this.context.showAs == "widget" &&
                <IonRouterOutlet id="menuContent">
                    {this.props.routeComponents}
                    <Route path="/" render={() => <Redirect to="/Home" />} exact={true} />
                    <Route path="/Appointment/UpdateStatus" render={() => <Redirect to="/Appointment/List" />} exact={true} />
                    {/*
                        <Route path="/users/:id" render={(props) => <UserPage {...props} />}/>  
                        this.props.match.params.id
                    */}

                </IonRouterOutlet>
            }

            {this.context.showAs == "page" &&
                <IonTabs>

                    <IonRouterOutlet id="menuContent">
                        {this.props.routeComponents}
                        <Route path="/" render={() => <Redirect to="/Home" />} exact={true} />
                        {/*
                            <Route path="/users/:id" render={(props) => <UserPage {...props} />}/>  
                            this.props.match.params.id
                        */}

                    </IonRouterOutlet>
                    
                    <IonTabBar slot="bottom">

                        <IonTabButton tab="home" href="/Home">
                            <IonIcon icon={home} />
                            <IonLabel> {i18n.t('L_TAB_BAR.L_HOME')}</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="billing" href="/Billing">
                            <IonIcon icon={card} />
                            <IonLabel>{i18n.t('L_TAB_BAR.L_BILLING')}</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="appointments" href="/Appointment/List">
                            <IonIcon icon={calendar} />
                            <IonLabel>{i18n.t('L_TAB_BAR.L_APPOINTMENTS')}</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="property" href="/Property/List">
                            <IonIcon icon={business} />
                            <IonLabel>{i18n.t('L_TAB_BAR.L_PROPERTIES')}</IonLabel>
                        </IonTabButton>

                    </IonTabBar>

                </IonTabs>
            }
         </>       
        )
    }
}
