/* global React:true */


/**
 * 
 *  Get the value:
 *      this.context.loggedIn
 * 
 *  Set the value
 *     this.context.set("loggedIn", true)
 *
 * Set multiple values 
 *     this.context.setState({ error: true,  error_code:r.error, loading: false })
 *
 */

import './global.js'
import AppContext from './AppContext';

export default class AppProvider extends React.Component
{

    constructor()
    {
        super()

    this.state =
    {
        connected      : true, // internet connection
        connectionType : "",   // internet connection type 

        referrer       : "babacasa", //referrer of the call
        showAs         : "page", //page,widget

        loggedIn       : true, // check if the token is valid with the server
        pendingDocs    : false, // check if any documents are pending approval
        registerStep   : "",    // if user in registration path - we store the step here
        platform       : "",

        set: (key,value) =>
        {
            //console.log (key,"old_value",this.state[key])
            console.log("CONTEXT:", this.state)
            console.log ("CONTEXT setState:",key,value)
            this.setState({ [key]: value})
            console.log("Context State", this.state)
        },

        setState: (obj) =>
        {
            console.log("CONTEXT:",this.state)
            console.log ("CONTEXT setState:",obj)
            this.setState(obj)
            console.log("CONTEXT:", this.state)
        }
    }
}

    componentDidMount()
    {
    }

    render()
    {
        console.log("AppProviderRender")
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}