/* global  $a:true, i18n:true, React:true, AppContext:true, useState:true,useContext:true */

import '../../global.js'
import {MyContext} from './MyContext'


import {IonButton,IonIcon,IonRow,IonActionSheet} from '@ionic/react'

import 
{   
    checkmarkDoneSharp as iconAccepted, 
    closeCircleSharp   as iconRejected,
    calendarSharp      as iconCalendar
}
from 'ionicons/icons';

import iconGmail  from '../../theme/img/iconGmail.svg'
import iconOutlook from '../../theme/img/iconOutlook.svg'


import {handleGmail, handleMicrosoft} from './Utils'
import i18n from '../../i18n.js';



const TableActions = ({ appointment={}, onError }) =>
{

    //const appContext = useContext(AppContext);
    const myContext = useContext(MyContext);
    const [showAddToCalendar,setShowAddToCalendar] = useState(false)

    //1:accepted  2:rejected
    async function updateStatus(status)
    {
        // schedule for this property exists - update
        let data   =
        {
            token          : $a.Store.Get("token"),
            id             : appointment.id,
            dm_task_status : status
        }

        let r = await $a.Api.Call("Calendar/Task/UpdateStatus/",data)

        if ($a.Api.ErrorCheck(r))
        {
            onError(r)
            return
        }

        var index = myContext.originalTaskData.findIndex(e => e.id == appointment.id)

        if(r.result !== true)
        {

            let id           = appointment.id 
            let name         = r.result.name
            let username     = r.result.username // email
            let phone_number = r.result.phone_number


            myContext.originalTaskData[index]["id"]             = id
            myContext.originalTaskData[index]["name"]           = name
            myContext.originalTaskData[index]["username"]       = username
            myContext.originalTaskData[index]["phone_number"]   = phone_number
            myContext.originalTaskData[index]["phone_number"]   = phone_number
            myContext.originalTaskData[index]["dm_task_status"] = "1"
        }
        else
        {
            myContext.originalTaskData[index]["dm_task_status"] = "2"
        }

        await myContext.onFilterTaskData()
    }



/*
PrProperty_ID: "3192"
XaUserOu_ID: "40311"
address: "Via Roccamena, 13, 00132 Roma RM, Italia"
created: "2020-11-08 23:52"
dm_task_status: "1"
dm_task_type: "1"
end_date: "2020-11-10"
end_time: "04:00"
id: 292
latitude: "41.8822"
localCalendarEventId: ""
longitude: "12.6641"
name: "Krystian2"
start_date: "2020-11-10"
start_time: "15:00"
timezone: "Europe/Rome"
title: "Property tour in person"
updated: "2020-11-08 23:56"
username: "krystian@pasich2.com"
tools:[]
*/
    if(appointment.username === "Unregistered")
    {
        return ("")
    }
    else if(appointment.dm_task_status == 0)//pending
    {
        return(
            
            <IonRow style={{}}>
                
                <IonButton size={"small"} fill={"outline"} color={"success"} style={{fontSize:"85%"}} onClick={()=> updateStatus(1)}>
                    <IonIcon slot="start" icon={iconAccepted}/> 
                    {i18n.t("TASK.TABLEACTIONS.ACCEPT")}
                </IonButton>
                <IonButton size={"small"} fill={"outline"} color={"danger"} style={{fontSize:"85%"}} onClick={()=> updateStatus(2)}>
                    <IonIcon slot="start" icon={iconRejected} />
                    {i18n.t("TASK.TABLEACTIONS.REJECT")}
                </IonButton>
            </IonRow>
            
        )
    }
    else if(appointment.dm_task_status == 1) //accepted
    {
        return(
            <>
        <IonButton size={"small"} fill={"outline"} color={"secondary"} style={{fontSize:"85%"}} onClick={()=>setShowAddToCalendar(true)} >
            <IonIcon slot="start" icon={iconCalendar}/> 
            {i18n.t("TASK.TABLEACTIONS.ADDTOCALENDAR")}
        </IonButton>

        <IonActionSheet
            isOpen={showAddToCalendar}
            onDidDismiss={() => setShowAddToCalendar(false)}
            //mode ={"ios"}
            //cssClass='bc-calendar-action-sheet'
            
            buttons=
            {[
                {
                    text: 'Gmail',
                    icon: iconGmail,
                    handler: () =>
                    {
                        handleGmail(appointment)
                    }
                },
                {
                    text: 'Hotmail - Live',
                    icon: iconOutlook,
                    handler: () =>
                    {
                        handleMicrosoft(appointment,"hotmail")
                    }
                },
                {
                    text: 'Office 365',
                    icon: iconOutlook,
                    handler: () =>
                    {
                        handleMicrosoft(appointment,"office365")
                    }
                },
                {
                    text: 'Cancel',
                    //icon: close,
                    role: 'cancel',
                    handler: () =>
                    {
                        console.log('Cancel clicked');
                    }
                }
            ]}
      >
      </IonActionSheet>
      </>
        )
    }
    else if(appointment.dm_task_status == 2) //rejected
    {
        return ("")
    }
}

export default TableActions