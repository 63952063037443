/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
import '../../global.js'

import { IonPage, IonContent, IonCard, IonCardHeader, IonCardTitle, IonText, IonRow,
         IonCardSubtitle, IonIcon, IonRouterLink, IonButton, IonActionSheet, IonCardContent } from '@ionic/react';

import {CbHeader} from '../cb.header'
import i18n from '../../i18n.js';

import { videocam, walk, person, call, mail, calendarSharp as iconCalendar, cashOutline, homeOutline } from 'ionicons/icons';
import iconGmail  from '../../theme/img/iconGmail.svg'
import iconOutlook from '../../theme/img/iconOutlook.svg'

import {handleGmail, handleMicrosoft} from '../task/Utils'

import Moment from 'moment'
import MomentTZ from 'moment-timezone';

import CmpLoading            from '../cmp.loading'


export default class PageAppointmentStatusUpdate extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            res               : {},
            showAddToCalendar : false,

            isLoading         : false,
        }

        this.componentDidMount            = this.componentDidMount.bind(this)
    }

    async componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
        this.callApi()
    }


    async callApi()
    {
   
        let data = 
        {
            task_token     : this.props.params.token,
            id             : this.props.params.id,
            dm_task_status : this.props.params.status
        }

        this.setState({isLoading:true})

        let res = await $a.Api.Call("Calendar/Task/UpdateStatusByToken/", data)

        if ($a.Api.ErrorCheck(res))
        {
            res.status = "error"
            this.setState({isLoading:false, res: res})
        }
        else
        {
            this.setState({isLoading:false, res: res.result})
        }
    }

    render()
    {
        let a = this.state.res

        let start_date = a.start_date
        let start_time = a.start_time
        let timezone   = a.timezone

        let loc = ""

        if($a.isEmpty(this.state.res.tools))
        {
            // For physical visit always show the time in timezone of the property
            loc = Moment(start_date + " " + start_time)
        }
        else
        {
            // For virtual visit always show the time in timezone where user currently is
            let timezonedate    = MomentTZ.tz(start_date + " " + start_time, timezone)
            loc = Moment(timezonedate).local()
        }

        return (
            <IonPage>
                <CbHeader title ={i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_HEADER')} />

                <IonContent ref = {ref => this.scrollRef=ref} fullscreen="true">
                    <CmpLoading    isLoading  = {this.state.isLoading} />

                    <br/>
                    <br/>

                    { this.state.res.status == "1" &&
                    <IonCard style = {{maxWidth:400,margin:"auto"}}>

                        <IonCardHeader style={{}}>
                            <IonCardTitle style={{justifyContent:"center", display: "flex"}}>{this.state.res.personal == "0" ? i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_HEADER_VIRTUAL') : i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_HEADER_PERSONAL')}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardHeader>
                            <IonCardSubtitle >
                                <IonIcon style={{marginRight:5, display: "inline-block", verticalAlign: "middle"}} color={"primary"} icon={homeOutline} /> 
                                <IonText style={{display: "inline-block", verticalAlign: "middle"}}>{$a.Dom.decodeHTMLEntities(this.state.res.address)}</IonText>
                            </IonCardSubtitle>

                            <IonCardSubtitle >
                                <IonIcon style={{marginRight:5, display: "inline-block", verticalAlign: "middle"}} color={"primary"} icon={cashOutline} /> 
                                 <IonText style={{display: "inline-block", verticalAlign: "middle"}}>{parseFloat(this.state.res.price).toLocaleString() + " " + i18n.t("L_CCY."+this.state.res.currency)}</IonText>
                            </IonCardSubtitle>

                            <IonCardTitle style={{fontWeight:"500", marginTop:30}}>
                                <IonIcon style={{marginRight:5, display: "inline-block", verticalAlign: "middle"}} color={"primary"} icon={ this.state.res.personal == "1" ? walk : videocam} />
                                <IonText style={{display: "inline-block", verticalAlign: "middle"}}>{Moment(loc).local().format("dddd, LL, HH:mm")}</IonText>
                            </IonCardTitle>

                        </IonCardHeader>

                        <IonCardContent >

                            <IonRow style ={{width:"100%",paddingLeft:10}}>
                                <IonText>{i18n.t("L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_CONTACT_DETAILS")}</IonText>
                            </IonRow>

                            <div style ={{width:"100%", paddingLeft:10}}>
                                <IonIcon color={"primary"} icon={person} style={{marginRight:10, display: "inline-block", verticalAlign: "middle"}} />
                                <IonText style ={{ display: "inline-block", verticalAlign: "middle"}}>
                                    {this.state.res.name + " " + this.state.res.surname }
                                </IonText>
                            </div>

                            <div style ={{width:"100%",paddingLeft:10}}>
                                <IonIcon color={"primary"} icon={call} style={{marginRight:10, display: "inline-block", verticalAlign: "middle"}}/>
                                <IonText style ={{ display: "inline-block", verticalAlign: "middle"}}>
                                    {this.state.res.phone_number}
                                </IonText>
                            </div>

                            <div style ={{width:"100%",paddingLeft:10}}>
                                <IonIcon color={"primary"} icon={mail} style={{marginRight:10, display: "inline-block", verticalAlign: "middle"}}/>
                                <IonText style ={{ display: "inline-block", verticalAlign: "middle"}}>
                                    {this.state.res.username}
                                </IonText>
                            </div>

                        </IonCardContent>

                        { this.state.res.personal == "0" &&
                            <IonCardContent >

                                <div style ={{width:"100%",paddingLeft:10}}>
                                    <div>
                                    <IonText>
                                        { i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_VIRTUAL') + ". " + i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_PHONE') }
                                    </IonText>
                                    </div>
                                    <div>
                                    <IonText>
                                        { this.state.res.phone_number.toString() }
                                    </IonText>
                                    </div>
                                    <div>
                                    <IonText>
                                        { i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_TOOLS') + " " + this.state.res.tools.join(", ")}
                                    </IonText>
                                    </div>
                                </div>

                            </IonCardContent>
                        }
                        
                        <IonCardContent style={{justifyContent:"center", display: "flex"}}>

                            <IonButton size={"small"} fill={"outline"} color={"secondary"} style={{fontSize:"85%"}} onClick={()=>this.setState({showAddToCalendar:true})} >
                                <IonIcon slot="start" icon={iconCalendar}/> 
                                {i18n.t("L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_ADDTOCALENDAR")}
                            </IonButton>

                            <IonButton size={"small"} fill={"outline"} color={"primary"} style={{fontSize:"85%", marginLeft:30}} onClick={this.props.callBack} >
                                {i18n.t("L_APPOINTMENT_UPDATE_STATUS.L_ACCEPTED.L_OK")}
                            </IonButton>

                        </IonCardContent>

                        <IonActionSheet
                            isOpen={this.state.showAddToCalendar}
                            onDidDismiss={() => this.setState({showAddToCalendar:false})}
                            //mode ={"ios"}
                            //cssClass='bc-calendar-action-sheet'
                            
                            buttons=
                            {[
                                {
                                    text: 'Gmail',
                                    icon: iconGmail,
                                    handler: () =>
                                    {
                                        handleGmail(this.state.res)
                                    }
                                },
                                {
                                    text: 'Hotmail - Live',
                                    icon: iconOutlook,
                                    handler: () =>
                                    {
                                        handleMicrosoft(this.state.res,"hotmail")
                                    }
                                },
                                {
                                    text: 'Office 365',
                                    icon: iconOutlook,
                                    handler: () =>
                                    {
                                        handleMicrosoft(this.state.res,"office365")
                                    }
                                },
                                {
                                    text: 'Cancel',
                                    //icon: close,
                                    role: 'cancel',
                                    handler: () =>
                                    {
                                        console.log('Cancel clicked');
                                    }
                                }
                            ]}
                        >
                        </IonActionSheet>

                    </IonCard>
                    }

                    { this.state.res.status == "2" &&
                    <IonCard style = {{maxWidth:400,margin:"auto"}}>

                        <IonCardHeader style={{}}>
                            <IonCardTitle style={{justifyContent:"center", display: "flex"}}>{i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_REJECTED.L_HEADER')}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardHeader>
                            <IonCardSubtitle >
                                <IonIcon style={{marginRight:5, display: "inline-block", verticalAlign: "middle"}} color={"primary"} icon={homeOutline} /> 
                                <IonText style={{display: "inline-block", verticalAlign: "middle"}}>{$a.Dom.decodeHTMLEntities(this.state.res.address)}</IonText>
                            </IonCardSubtitle>

                            <IonCardSubtitle >
                                <IonIcon style={{marginRight:5, display: "inline-block", verticalAlign: "middle"}} color={"primary"} icon={cashOutline} /> 
                                <IonText style={{display: "inline-block", verticalAlign: "middle"}}>{parseFloat(this.state.res.price).toLocaleString() + " " + i18n.t("L_CCY."+this.state.res.currency)}</IonText>
                            </IonCardSubtitle>

                            <IonCardSubtitle>
                                <IonIcon style={{marginRight:5, display: "inline-block", verticalAlign: "middle"}} color={"primary"} icon={ this.state.res.personal == "1" ? walk : videocam} />
                                <IonText style={{display: "inline-block", verticalAlign: "middle"}}>{Moment(loc).local().format("dddd, LL, HH:mm")}</IonText>
                            </IonCardSubtitle>

                        </IonCardHeader>


                        <IonCardContent style={{justifyContent:"center", display: "flex"}}>

                            <IonButton size={"small"} fill={"outline"} color={"primary"} style={{fontSize:"85%", marginLeft:30}} onClick={this.props.callBack} >
                                {i18n.t("L_APPOINTMENT_UPDATE_STATUS.L_REJECTED.L_OK")}
                            </IonButton>

                        </IonCardContent>

                    </IonCard>
                    }

                    { this.state.res.status == "error" &&
                    <IonCard style = {{maxWidth:400,margin:"auto"}} className="ion-justify-content-center">

                        <IonCardHeader style={{}}>
                            <IonCardTitle style={{justifyContent:"center", display: "flex"}}>{i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ERROR.L_HEADER')}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardSubtitle style={{marginLeft:10, marginRight:10}}>
                            <IonText >{i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ERROR.E_' + this.state.res.error)}</IonText>
                        </IonCardSubtitle>

                        <IonCardSubtitle style={{marginLeft:10, marginRight:10, marginTop:10}}>
                            <IonText >{i18n.t('L_APPOINTMENT_UPDATE_STATUS.L_ERROR.L_PRESS_OK')}</IonText>
                        </IonCardSubtitle>

                       <IonCardContent style={{justifyContent:"center", display: "flex"}}>

                            <IonButton size={"small"} fill={"outline"} color={"primary"} style={{fontSize:"85%", marginLeft:30}} onClick={this.props.callBack} >
                                {i18n.t("L_APPOINTMENT_UPDATE_STATUS.L_ERROR.L_OK")}
                            </IonButton>

                        </IonCardContent>

                    </IonCard>
                    }

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                    <p style={{fontSize:"small"}} className="ion-text-center">

                        <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                        <br/><br/>

                        {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                    </p>
                    </IonCard>
                    <br/><br/>
                </IonContent>
            </IonPage>
        )
    }
}