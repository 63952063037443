/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonRow, IonImg, IonRouterLink, IonCard, IonPage, IonProgressBar, 
         IonCardTitle, IonCardSubtitle, IonButton, IonIcon, IonRippleEffect, IonTextarea,
         IonItem, IonCheckbox, IonLabel, IonText, IonAlert } from '@ionic/react'

import {create } from 'ionicons/icons';

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'
import CmpFrmItem      from '../form_components/cmp_frm_item'
import HtmlToReact     from 'html-to-react'
import i18n            from '../../i18n.js'


export default class PageSummary extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {

            ready          : false,
            showAlert      : false,
            isLoading      : false,

            documents      : [],
            documentID     : 0,

            isCheckedDataTrue   : false,
            isCheckedNewsletter : true,
            isCheckedPrivacy    : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.stepNumber = 4
        this.steps      = 4

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.getDocs                      = this.getDocs.bind(this)
        this.register                     = this.register.bind(this)
        this.goToLogin                    = this.goToLogin.bind(this)
        this.getUserDetails               = this.getUserDetails.bind(this)
        this.getUserCompanyDetails        = this.getUserCompanyDetails.bind(this)
        this.getUserCompanyAddress        = this.getUserCompanyAddress.bind(this)
        this.getUserCompanyHQAddress      = this.getUserCompanyHQAddress.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMountAAAA", this.constructor.name,this.props, this.props.history.location.state)

        if($a.isNull(this.props.history.location.state))
        {
            this.props.history.replace({pathname: '/register/step/one'})
            return
        }

        this.getDocs()
    }

    async getDocs()
    {
        this.context.set("loading",true)

        let r = await $a.Api.Call("Gate/Document/GetPrivacy/",{})

        this.context.set("loading",false)

        if ($a.Api.ErrorCheck(r))
        {
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        this.setState({documents : r.result})
    }

    async register()
    {
        // REGISTER
        let data = this.props.history.location.state.data

        data.privacy = 
        {
            signed                 : this.state.isCheckedPrivacy    ? "1" : "0",
            registration_data_true : this.state.isCheckedDataTrue   ? "1" : "0",
            marketing : 
            {
                consent : this.state.isCheckedNewsletter ? "1" : "0"
            }
        }

        data.referrer = this.context.referrer

        this.setState({isLoading:true})

        let r = await $a.Api.Call("Gate/Agent/Register/",data)

        this.setState({isLoading:false})

        if ($a.Api.ErrorCheck(r))
        {
            if(r.error == 50016)
            {
                let msg = i18n.t("E_"+r.error.toString())
                msg += '<a href='+i18n.t("L_AGENT_WEB_SITE")+'>'+i18n.t("E_"+r.error.toString()+"_2")+'</a>'
                console.log("MSG", msg, "E_"+r.error.toString(), r.error)

                this.context.set("error_message", msg)
                this.context.set("error"        , true)
            }
            else
            {
                this.context.set("error_code"     , r.error)
                this.context.set("error_parameter", r.parameter)
                this.context.set("error"          , true)
            }

            return
        }

        this.setState({showAlert: true})
    }

    getUserDetails()
    {
        console.log("getUserDetails",this.props.history.location.state.data)
        let data = this.props.history.location.state.data

        let userDetails = []
        let username = {
            value     : data.username,
            label     : "L_GATE_USER_USERNAME",
            disabled  : true
        }

        let phone_number = [{country_code: "", number: ""}]
        if(!$a.isEmpty(data.phone))
        {
            phone_number = data.phone.filter(obj => {
                return obj.dm_phone_type_id == "2"
            })
        }

        let phone = {
            value     : phone_number[0].country_code + " " + phone_number[0].number,
            label     : "L_GATE_USER_PHONENUMBER",
            disabled  : true
        }

        let name = {
            value     : data.name,
            label     : "L_GATE_USER_NAME",
            disabled  : true
        }

        let surname = {
            value     : data.surname,
            label     : "L_GATE_USER_SURNAME",
            disabled  : true
        }

        let personal_id = {
            value     : data.personal_id,
            label     : "L_GATE_USER_PERSONAL_ID",
            disabled  : true
        }

        userDetails.push(<CmpFrmItem id="userdetails-0" key="userdetails-0" item={username} />)
        userDetails.push(<CmpFrmItem id="userdetails-1" key="userdetails-1" item={phone} />)
        userDetails.push(<CmpFrmItem id="userdetails-2" key="userdetails-2" item={personal_id} />)
        userDetails.push(<CmpFrmItem id="userdetails-3" key="userdetails-3" item={name} />)
        userDetails.push(<CmpFrmItem id="userdetails-4" key="userdetails-4" item={surname} />)

        return userDetails
    }

    getUserCompanyDetails()
    {
        let data = this.props.history.location.state.data

        let name = {
            value     : data.ouName,
            label     : "L_GATE_OU_NAME",
            disabled  : true
        }

        let vat_number = {
            value     : data.vat_number,
            label     : "L_GATE_OU_VAT_NUMBER",
            disabled  : true
        }

        let email = [{email:""}]
        if(!$a.isNull(data.email))
        {
            email = data.email.filter(obj => {
                return obj.dm_email_type_id == "2"
            })
        }

        let pec = {
            value     : email[0].email,
            label     : "L_GATE_OU_REGISTERED_EMAIL",
            disabled  : true
        }

        email = [{email:""}]
        if(!$a.isNull(data.email))
        {
            email = data.email.filter(obj => {
                return obj.dm_email_type_id == "1"
            })
        }

        let contact_email = {
            value     : email[0].email,
            label     : "L_GATE_OU_CONTACT_EMAIL",
            disabled  : true
        }

        let registration_number = {
            value     : data.registration_number,
            label     : "L_GATE_OU_REGISTRATION_NUMBER",
            disabled  : true
        }

        let lang = []
        if(!$a.isNull(data.languages))
        {
            for(let i = 0; i < data.languages.length; i++)
            {
                lang.push(i18n.t("D_GATE_OU_LANGUAGES."+data.languages[i]))
            }
        }

        let languages = {
            value     : lang,
            label     : "L_GATE_OU_LANGUAGES",
            disabled  : true
        }

        let companyDetails = []
        companyDetails.push(<CmpFrmItem id="companydetails-0" key="companydetails-0" item={name} />)
        companyDetails.push(<CmpFrmItem id="companydetails-1" key="companydetails-1" item={vat_number} />)
        companyDetails.push(<CmpFrmItem id="companydetails-2" key="companydetails-2" item={pec} />)
        companyDetails.push(<CmpFrmItem id="companydetails-3" key="companydetails-3" item={contact_email} />)
        companyDetails.push(<CmpFrmItem id="companydetails-4" key="companydetails-4" item={registration_number} />)
        companyDetails.push(<CmpFrmItem id="companydetails-6" key="companydetails-5" item={languages} />)

        return companyDetails
    }

    getUserCompanyAddress()
    {
        let data = this.props.history.location.state.data

        let address = {
            value     : $a.isNull(data.geo) ? "" : data.geo.address,
            label     : "L_ADDRESS",
            disabled  : true
        }

        let companyAddress = []
        companyAddress.push(<CmpFrmItem id="companyaddress-0" item={address} />)

        return companyAddress
    }

    getUserCompanyHQAddress()
    {
        let data = this.props.history.location.state.data

        let address = {
            value     : $a.isNull(data.geohq) ? "" : data.geohq.address,
            label     : "L_ADDRESS",
            disabled  : true
        }

        let companyAddress = []
        companyAddress.push(<CmpFrmItem id="companyhqaddress-0" item={address} />)

        return companyAddress
    }

    goToLogin()
    {
        let data = this.props.history.location.state.data
        this.setState({showAlert: false})
        this.props.history.replace({pathname: '/login', state: {data:data}})
    }

    render()
    {
        if($a.isNull(this.props.history.location.state))
        {
            return (<></>)
        } else if($a.isNull(this.props.history.location.state.data))
        {
            return(<></>)
        }

        let document = ""
        if(!$a.isNull(this.state.documents))
        {
            if(this.state.documents.length > 0 && this.state.documentID < this.state.documents.length)
            {
                document =  $a.Dom.decodeHTMLEntities(this.state.documents[this.state.documentID].document)
            }
        }

        let htmlToReactParser = new HtmlToReact.Parser();
        let reactElement = htmlToReactParser.parse(document);

        let data = this.props.history.location.state.data
        let data_true = i18n.t('L_DATA_TRUE_CONSENT')

        data_true = data_true.replace(":NAME:"  , data.name + " " + data.surname)
        data_true = data_true.replace(":AGENCY:", data.ouName)

        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <IonAlert
                        isOpen       = {this.state.showAlert}
                        onDidDismiss = {() => this.goToLogin()}
                        //cssClass='my-custom-class'
                        header={i18n.t('L_REGISTRATION.L_COMPLETE_HEADER')}
                        //subHeader={'Subtitle'}
                        message={i18n.t('L_REGISTRATION.L_COMPLETE_MESSAGE')}
                        buttons={[i18n.t('L_CONTINUE')]}
                    />

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/>
                    <IonProgressBar value={this.stepNumber/this.steps} style={{maxWidth:500, height:10, borderRadius:5, margin:"auto"}}/>
                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <IonRow id="summary-0" className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_REGISTRATION.L_SUMMARY')}
                                </IonCardTitle>
                            </IonRow>

                            <IonRow id="summary-1" className="ion-justify-content-center" style={{marginBottom:10}}>
                                <IonCardSubtitle style = {{marginTop:10}} color = "primary">
                                    {i18n.t('L_REGISTRATION.L_USER_DETAILS')}
                                </IonCardSubtitle>

                                <IonButton color="primary" fill="clear" style={{position: "absolute", right:"0px",marginTop:"-1px"}} onClick={() => {this.props.history.replace({pathname: '/register/step/one', state: {data:this.props.history.location.state.data}})}}>
                                    <IonIcon icon={create} />
                                </IonButton>
                            </IonRow>

                            {this.getUserDetails()}

                            <IonRow id="summary-2" className="ion-justify-content-center" style={{marginBottom:10}}>
                                <IonCardSubtitle style = {{marginTop:10}} color = "primary">
                                    {i18n.t('L_REGISTRATION.L_COMPANY_DETAILS')}
                                </IonCardSubtitle>

                                <IonButton color="primary" fill="clear" style={{position: "absolute", right:"0px",marginTop:"-1px"}} onClick={() => {this.props.history.replace({pathname: '/register/step/two', state: {data:this.props.history.location.state.data}})}}>
                                    <IonIcon icon={create} />
                                </IonButton>
                            </IonRow>

                            {this.getUserCompanyDetails()}

                            <IonRow id="summary-4" className="ion-justify-content-center" style={{marginBottom:10}}>
                                <IonCardSubtitle style = {{marginTop:10}} color = "primary">
                                    {i18n.t('L_REGISTRATION.L_HQ_ADDRESS')}
                                </IonCardSubtitle>

                                <IonButton color="primary" fill="clear" style={{position: "absolute", right:"0px",marginTop:"-1px"}} onClick={() => {this.props.history.replace({pathname: '/register/step/three', state: {data:this.props.history.location.state.data}})}}>
                                    <IonIcon icon={create} />
                                </IonButton>
                            </IonRow>

                            {this.getUserCompanyHQAddress()}

                            <IonRow id="summary-3" className="ion-justify-content-center" style={{marginBottom:10}}>
                                <IonCardSubtitle style = {{marginTop:10}} color = "primary">
                                    {i18n.t('L_REGISTRATION.L_COMPANY_ADDRESS')}
                                </IonCardSubtitle>

                                <IonButton color="primary" fill="clear" style={{position: "absolute", right:"0px",marginTop:"-1px"}} onClick={() => {this.props.history.replace({pathname: '/register/step/four', state: {data:this.props.history.location.state.data}})}}>
                                    <IonIcon icon={create} />
                                </IonButton>
                            </IonRow>

                            {this.getUserCompanyAddress()}

                            <br/><br/>

                            <IonRow id="summary-3" className="ion-justify-content-center" style={{marginBottom:10}}>
                                <IonCardSubtitle style = {{marginTop:10}} color = "primary">
                                    {i18n.t('L_PRIVACY')}
                                </IonCardSubtitle>
                            </IonRow>

                            <div id="privacy" style={{marginTop:20, overflowY: "scroll", height:300, borderWidth:1, borderColor:"#DEDEDE", borderStyle: "solid", borderRadius:5}}>
                                <div id="privacy-2" style={{margin:10}} >
                                    {reactElement}
                                </div>
                            </div>

                            <IonRow id="checkbox-privacy" className="ion-justify-content-center">
                                <IonItem key={"checkbox"} style={{width:"100%"}}>
                                    <IonCheckbox slot="start" checked={this.state.isCheckedPrivacy} onClick={() => this.setState({isCheckedPrivacy:!this.state.isCheckedPrivacy})} />
                                    <IonLabel className="ion-text-wrap">
                                        <IonText color="danger">{i18n.t('L_REQUIRED_SIGN')}&nbsp;</IonText>
                                        {i18n.t('L_PRIVACY_ACCEPT')}
                                    </IonLabel>
                                </IonItem>
                            </IonRow>

                            <IonRow id="checkbox-data-true" className="ion-justify-content-center">
                                <IonItem key={"checkbox"}>
                                    <IonCheckbox slot="start" checked={this.state.isCheckedDataTrue} onClick={() => this.setState({isCheckedDataTrue:!this.state.isCheckedDataTrue})} />
                                    <IonLabel className="ion-text-wrap">
                                        <IonText color="danger">{i18n.t('L_REQUIRED_SIGN')}&nbsp;</IonText>
                                        {data_true}
                                    </IonLabel>
                                </IonItem>
                            </IonRow>

                            <IonRow id="checkbox-newsletter" lassName="ion-justify-content-center">
                                <IonItem key={"checkbox"} style={{width:"100%"}}>
                                    <IonCheckbox slot="start" checked={this.state.isCheckedNewsletter} onClick={() => this.setState({isCheckedNewsletter:!this.state.isCheckedNewsletter})} />
                                    <IonLabel className="ion-text-wrap">{i18n.t("L_NEWSLETTERS_ACCEPT")}</IonLabel>
                                </IonItem>
                            </IonRow>

                            <IonRow id="summary-5" className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%", marginTop:40, marginBottom:20}} disabled={!this.state.isCheckedDataTrue || !this.state.isCheckedPrivacy} onClick={this.register}>
                                    {i18n.t('L_REGISTER')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>

                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonTextarea note style={{ padding:'2%', height: 80 }} >
                            {i18n.t('L_REGISTRATION.L_INFO') }
                            <a href={"mailto:" + i18n.t('SupportMail')}>{i18n.t('SupportMail')}</a>
                        </IonTextarea>

                        <p style={{fontSize:"small"}} className="ion-text-center">

                            <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                            <br/><br/>

                            {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                        </p>
                    </IonCard>

                    <br/>

                </IonContent>
            </IonPage>
        )
    }
}