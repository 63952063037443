/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */
import '../../global.js'

import React from 'react';

import { IonPage, IonButton, IonRow, IonRouterLink, IonContent, IonText, IonImg, IonCard, IonRippleEffect, IonRadioGroup,
         IonItem, IonRadio, IonLabel, IonItemDivider, IonCol, IonIcon } from '@ionic/react';

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpFrmItem      from '../form_components/cmp_frm_item'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'

export default class PageMFA extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : '',
                        name      : "otp",
                        value     : "",
                        type      : "otp",
                        label     : "L_GATE_USER_OTP",
                        error     : "",
                        required  : true,
                        regex     : "otp",
                        min       : 0,
                        max       : 6,
                        keyboard  : "text"
                    }
                ]
            },

            mfaType        : "",
            usedAppTFA     : "",
            username       : "",

            ready          : false,
            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.componentDidMount            = this.componentDidMount(this)
        this.verifyTOTP                   = this.verifyTOTP.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onValidate                   = this.onValidate.bind(this)
        this.resetMFA                     = this.resetMFA.bind(this)
        this.generateQRCode               = this.generateQRCode.bind(this)
    }

    async componentDidMount()
    {
        let data   = {}
        data.token = $a.Store.Get("token")

        //this.setState({ isLoading:true })

        let r = await $a.Api.Call("Gate/UserSettings/Read/",data)

        //this.setState({isLoading:false})

        if ($a.Api.ErrorCheck(r))
        {
            this.state.form.fields[0].value = ""
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        this.setState({mfaType: r.result[0]["otpType"], usedAppTFA: r.result[0]["usedTFA"], username: r.result[0]["username"]})
    }

    async generateQRCode()
    {
        await this.sleep(300)

        // GENERATE QR CODE USING //http://davidshimjs.github.io/qrcodejs/   - MIT LICENSE
        var qrcodeel = new (window)["QRCode"]("qr-code");

        console.log("this.props.history.location.state.secKey", this.context)

        let secKey  = this.state.secKey
        let email   = this.state.username.replace(/[@]/g, '%40');
        let appName = i18n.t('L_APP_NAME').replaceAll(' ', '%20')
        qrcodeel.makeCode("otpauth://totp/"+appName+":"+email+"?secret="+secKey+"&issuer="+appName);
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onChange(el)
    {
        console.log("onChange",el)

        let value = el.target.value
        let id    = parseInt(el.target.id)

        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return
            }
        }

        for (const f of formFields)
        {
            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)

                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return
            }
        }

        this.state.ready = true
    }

    async verifyTOTP(e)
    {
        e.preventDefault();
        e.stopPropagation();

        console.log("verifyTOTP")

        this.onValidate(this.state.form.fields);

        if(this.state.ready === true)
        {
            this.context.set("loading",true)

            let data   = {}
            data.token = $a.Store.Get("token")
            data.otp   = this.state.form.fields[0].value

            console.log(data)

            this.setState({ isLoading:true })

            let r = await $a.Api.Call("Gate/UserSettings/SetupAppMFA/",data)

            if ($a.Api.ErrorCheck(r))
            {
                this.setState({isLoading:false})
                this.state.form.fields[0].value = ""
                this.context.set("error_code", "A0002")
                this.context.set("error"     , true)
                return
            }

            this.setState({usedAppTFA: "1", isLoading:false})

            //this.props.history.replace({pathname: '/home'})
        }
    };

    async chooseDefaultMFA(e)
    {
        if(e.detail.value == this.state.mfaType)
        {
            return
        }

        let data        = {}
        data.token      = $a.Store.Get("token")
        data.defaultMFA = e.detail.value

        this.setState({ isLoading:true })

        let r = await $a.Api.Call("Gate/UserSettings/SetDefaultMFA/",data)

        this.setState({isLoading:false})

        if ($a.Api.ErrorCheck(r))
        {
            this.state.form.fields[0].value = ""
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        this.setState({mfaType:e.detail.value})

        console.log("this.state", this.state)

        if(this.state.mfaType == "App" && this.state.usedAppTFA == "0")
        {
            this.resetMFA()
        }
    }

    async resetMFA()
    {
        let data        = {}
        data.token      = $a.Store.Get("token")

        this.setState({ isLoading:true })

        let r = await $a.Api.Call("Gate/UserSettings/ResetAppMFA/",data)

        
        if ($a.Api.ErrorCheck(r))
        {
            this.setState({isLoading:false})
            this.state.form.fields[0].value = ""
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        this.setState({isLoading:false, usedAppTFA:"0", secKey:r.result.secKey})
        this.generateQRCode()
    }


    render() 
    {
        console.log("mfatype ", this.state.mfaType, this.state.usedAppTFA)
        return (
            <IonPage>
                <IonContent fullscreen="true">

                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/><br/>
                    
                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonRow className="ion-justify-content-center" style = {{marginLeft:20, marginRight:20}}>
                            <IonText>
                                <h4>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_TITLE")}</h4>
                            </IonText>
                        </IonRow>

                        <IonRow>
                            <IonText style={{marginLeft:20, marginRight:20}}>
                                {i18n.t("L_SETTINGS.L_MFA_TYPE.L_DESCRIPTION")}
                            </IonText>
                        </IonRow>

                        <IonRow>
                            <IonRadioGroup value={this.state.mfaType} onIonChange={e => this.chooseDefaultMFA(e)}>
                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonRadio slot="start" value="App" mode="ios"></IonRadio>
                                            <IonLabel>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_APP")}</IonLabel>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem>
                                            <IonRadio slot="start" value="Delivered" mode="ios"></IonRadio>
                                            <IonLabel>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_DELIVERED")}</IonLabel>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonRadioGroup>
                        </IonRow>

                        { this.state.mfaType == "Delivered" && <><br/><br/></>}

                        { this.state.mfaType == "App" && this.state.usedAppTFA == "1" &&
                            <>
                                <IonItemDivider/>

                                <IonRow className="ion-justify-content-center" style ={{marginTop:"20px", marginBottom:"20px"}}>
                                    <IonButton color="primary" style={{width:"30%"}} onClick={this.resetMFA}>
                                        {i18n.t('L_SETTINGS.L_MFA_TYPE.L_RESET_APP_TFA')}
                                        <IonRippleEffect type="unbounded"></IonRippleEffect>
                                    </IonButton>
                                </IonRow>
                            </>
                        }

                        { this.state.mfaType == "App" && this.state.usedAppTFA == "0" &&
                            <>
                                <IonItemDivider/>

                                <IonRow className="ion-justify-content-center" style = {{marginLeft:20, marginRight:20}}>
                                    <IonText>
                                        <h4>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_QR_INSTR_HEAD")}</h4>
                                    </IonText>
                                </IonRow>

                                <IonRow style = {{marginLeft:20, marginRight:20}}>

                                    <IonText>
                                        <h5>{i18n.t("L_APP_NAME") + " " + i18n.t("L_SETTINGS.L_MFA_TYPE.L_QR_INSTR_BODY1")}</h5>
                                    </IonText>

                                    <IonRow style = {{marginLeft:20, marginRight:20, marginBottom:-20}}>

                                        <IonText color="primary" style ={{ marginBottom:-20}}><h6>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_QR_INSTR_BODY2")}</h6></IonText>

                                        <ul style={{listStyleType : 'none'}}>
                                            <li><IonRouterLink style={{color:"#0000FF"}} href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"}><h6>Android</h6></IonRouterLink></li>
                                            <li><IonRouterLink style={{color:"#0000FF"}} href={"https://itunes.apple.com/gb/app/google-authenticator/id388497605"}><h6>Apple</h6></IonRouterLink></li>
                                            <li><IonRouterLink style={{color:"#0000FF"}} href={"https://www.microsoft.com/en-gb/store/p/microsoft-authenticator/9nblgggzmcj6"} ><h6>Windows</h6></IonRouterLink></li>
                                        </ul>

                                    </IonRow>

                                    <IonRow style = {{marginLeft:20, marginRight:20}}>

                                        <IonText color="primary"><h5>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_QR_INSTR_BODY3")}</h5></IonText>

                                        <IonRow className="ion-justify-content-center" style={{margin:"auto"}}>
                                            <p id="qr-code"></p>
                                        </IonRow>

                                        <IonText color="primary"><h5>{i18n.t("L_SETTINGS.L_MFA_TYPE.L_QR_INSTR_BODY4")}</h5></IonText>

                                    </IonRow>

                                </IonRow>

                                <form className="ion-padding">

                                    <CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>

                                    <br/><br/>

                                    <IonRow className="ion-justify-content-center">
                                        <IonButton color="primary" style={{width:"40%"}} onClick={this.verifyTOTP}>
                                            {i18n.t('L_SETTINGS.L_MFA_TYPE.L_VERIFY')}
                                            <IonRippleEffect type="unbounded"></IonRippleEffect>
                                        </IonButton>
                                    </IonRow>

                                </form>
                            </>
                        }
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    }
}