/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.css';

import AppContext  from './AppContext'

import ale         from './lib/ale-mobile'
import config      from './app.json'
import i18n        from './i18n'
import React       from 'react'
import { useState,useContext } from 'react';


//GLOBAL LIBRARIES
global.$c          = config
global.$a          = ale
global.i18n        = i18n
global.React       = React
//global.$g          = {}
global.AppContext  = AppContext
global.useState    = useState
global.useContext  = useContext