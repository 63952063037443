/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
import '../../global.js'

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton } from '@ionic/react'
import { IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonRow, IonRippleEffect } from '@ionic/react'
import i18n from '../../i18n.js'

import CmpErrorAlert   from '../cmp_error_alert'

export default class PagePropertySchedule extends React.Component
{
    static contextType  = AppContext
    static defaultProps =
    {
        property: {},
        onInit  : () => {}
    }

    constructor()
    {
        super()

        this.state = 
        {
            PrSchedule: 
            {
                slots      : 24,
                start      : "08:00",
                duration   : 30,
                timezone   : "Europe/Rome",
                concurrency: 1,
                Monday     : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                Tuesday    : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                Wednesday  : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                Thursday   : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                Friday     : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                Saturday   : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                Sunday     : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
            },


            end             : "20:00",
            isNewSchedule   : true,
            schedule_ID     : 0,
            isLoading       : false
        }

        this.PrScheduleInit = 
        {
            slots      : 24,
            start      : "08:00",
            duration   : 30,
            timezone   : "Europe/Rome",
            concurrency: 1,
            Monday     : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            Tuesday    : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            Wednesday  : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            Thursday   : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            Friday     : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            Saturday   : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            Sunday     : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        }

        this.componentDidMount  = this.componentDidMount.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
        this.getData()
    }

    componentDidUpdate(prevProps)
    {
        if(prevProps.PrProperty_ID !== this.props.PrProperty_ID)
        {
            this.getData()
        }
    }

    async getData()
    {
        if($a.isNull(this.props.PrProperty_ID) || this.props.PrProperty_ID == "0")
        {
            this.setState({
                isNewSchedule : true, 
                schedule_ID   : 0,
                PrSchedule    : this.PrScheduleInit,
                end           : "20:00"
            })

            this.props.onInit(this.PrScheduleInit, 0)

            return
        }

        let r = await $a.Api.Call("Calendar/Schedule/ReadByProperty/",{token:$a.Store.Get("token"), PrProperty_ID: this.props.PrProperty_ID})

        if ($a.Api.ErrorCheck(r))
        {
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        if(r.rs !== 0)
        {
            let id       = r.result[0].id
            let schedule = JSON.stringify(r.result[0].data)
            schedule     = JSON.parse(schedule)

            this.setState({PrSchedule:schedule, isNewSchedule:false, schedule_ID:id})
        }
        else
        {
            this.setState({
                isNewSchedule : true, 
                schedule_ID   : 0,
                PrSchedule    : this.PrScheduleInit,
                end           : "20:00"
            })
            
        }

        this.props.onInit(this.state.PrSchedule, this.state.schedule_ID)
    }

    async saveData()
    {
        if(this.state.isNewSchedule)
        {
            // schedule for this property doesn't exist yet - create new one
            let data           = this.state.PrSchedule
            data.token         = $a.Store.Get("token")
            data.PrProperty_ID = String(this.props.PrProperty_ID)

            let r = await $a.Api.Call("Calendar/Schedule/Create/",data)

            if ($a.Api.ErrorCheck(r))
            {
                this.context.set("error_code", r.error)
                this.context.set("error"     , true)
                return
            }
        }
        else
        {
            // schedule for this property exists - update
            let data   = this.state.PrSchedule
            data.token = $a.Store.Get("token")
            data.id    = this.state.schedule_ID

            let r = await $a.Api.Call("Calendar/Schedule/Update/",data)

            if ($a.Api.ErrorCheck(r))
            {
                this.context.set("error_code", r.error)
                this.context.set("error"     , true)
                return
            }
        }

        this.props.onClose(true)
    }

    onDurationChange(val)
    {
        console.log("onDurationChange: " + this.state.selectDurationFocus)

        let start = this.state.PrSchedule.start
        if(start.slice(-2) !== "00")
        {
            start = start.substring(0,3) + "00"
            console.log(this.state.PrSchedule.start)
        }

        let slots                       = this.calculateSlots(start, this.state.end, val)

        let day = []
        for(let i = 0; i < slots; i++)
        {
            day.push(1)
        }

        this.setState(prevState => ({ PrSchedule: 
            { 
                ...prevState.PrSchedule,
                Monday    : day.slice(),
                Tuesday   : day.slice(),
                Wednesday : day.slice(),
                Thursday  : day.slice(),
                Friday    : day.slice(),
                Saturday  : day.slice(),
                Sunday    : day.slice(),
                duration  : val,
                start     : start,
                slots     : slots
            }
        }))

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(this.state.PrSchedule, this.state.schedule_ID)
        }
    }

    onStartChange(val)
    {
        let slots = this.calculateSlots(val, this.state.end, this.state.PrSchedule.duration)

        let PrSchedule = this.state.PrSchedule

        if(slots < PrSchedule.slots)
        {
            for(let i = 0; i < PrSchedule.slots - slots; i++)
            {
                PrSchedule.Monday.shift()
                PrSchedule.Tuesday.shift()
                PrSchedule.Wednesday.shift()
                PrSchedule.Thursday.shift()
                PrSchedule.Friday.shift()
                PrSchedule.Saturday.shift()
                PrSchedule.Sunday.shift()
            }
        }
        else if(slots > PrSchedule.slots)
        {
            for(let i = 0; i < slots - PrSchedule.slots; i++)
            {
                PrSchedule.Monday.unshift(1)
                PrSchedule.Tuesday.unshift(1)
                PrSchedule.Wednesday.unshift(1)
                PrSchedule.Thursday.unshift(1)
                PrSchedule.Friday.unshift(1)
                PrSchedule.Saturday.unshift(1)
                PrSchedule.Sunday.unshift(1)
            }
        }

        PrSchedule.start = val
        PrSchedule.slots = slots
        this.setState({PrSchedule:PrSchedule})

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(PrSchedule, this.state.schedule_ID)
        }
    }

    calculateSlots(startTime, endTime, durationStr)
    {
        let duration      = parseInt(durationStr)
        let firstTimeMins = parseInt(startTime.split(":")[0])*60 + parseInt(startTime.split(":")[1])
        let lastTimeMins  = parseInt(endTime.split(":")[0])  *60 + parseInt(endTime.split(":")[1])
        let dayMins       = lastTimeMins - firstTimeMins

        return parseInt(dayMins / duration)
    }

    onEndChange(val)
    {
        let slots = this.calculateSlots(this.state.PrSchedule.start, val, this.state.PrSchedule.duration)

        let PrSchedule = this.state.PrSchedule

        if(slots < PrSchedule.slots)
        {
            PrSchedule.Monday.length    = slots
            PrSchedule.Tuesday.length   = slots
            PrSchedule.Wednesday.length = slots
            PrSchedule.Thursday.length  = slots
            PrSchedule.Friday.length    = slots
            PrSchedule.Saturday.length  = slots
            PrSchedule.Sunday.length    = slots
        }
        else if (slots > PrSchedule.slots)
        {
            for(let i = 0; i < slots - PrSchedule.slots; i++)
            {
                PrSchedule.Monday.push(1)
                PrSchedule.Tuesday.push(1)
                PrSchedule.Wednesday.push(1)
                PrSchedule.Thursday.push(1)
                PrSchedule.Friday.push(1)
                PrSchedule.Saturday.push(1)
                PrSchedule.Sunday.push(1)
            }
        }

        PrSchedule.slots            = slots
        this.setState({PrSchedule:PrSchedule, end:val})

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(PrSchedule, this.state.schedule_ID)
        }
    }

    generateStartTimeList()
    {
        let startTimeArr = []
        let firstTime    = "8:00"
        let lastTime     = "12:00"

        let firstTimeMins = parseInt(firstTime.split(":")[0])*60 + parseInt(firstTime.split(":")[1])
        let lastTimeMins  = parseInt(lastTime.split(":")[0]) *60 + parseInt(lastTime.split(":")[1])
        let duration      = parseInt(this.state.PrSchedule.duration)

        while(firstTimeMins <= lastTimeMins)
        {
            let timeString = parseInt(firstTimeMins / 60).toString()
            timeString     = String("0" + timeString).slice(-2);
            timeString    += ":"
            timeString    += String("0" + (firstTimeMins % 60).toString()).slice(-2)

            startTimeArr.push(
                <IonButton key={"starttime-"+timeString} color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.onStartChange(timeString)}> {timeString} </IonButton>
            )

            firstTimeMins = firstTimeMins + duration
        }

        return startTimeArr
    }

    generateEndTimeList()
    {
        let endTimeArr = []
        let firstTime  = "15:00"
        let lastTime   = "20:00"

        let firstTimeMins = parseInt(firstTime.split(":")[0])*60 + parseInt(firstTime.split(":")[1])
        let lastTimeMins  = parseInt(lastTime.split(":")[0]) *60 + parseInt(lastTime.split(":")[1])
        let duration      = parseInt(this.state.PrSchedule.duration)

        while(firstTimeMins <= lastTimeMins)
        {
            let timeString = parseInt(firstTimeMins / 60).toString()
            timeString     = String("0" + timeString).slice(-2);
            timeString    += ":"
            timeString    += String("0" + (firstTimeMins % 60).toString()).slice(-2)

            endTimeArr.push(
                <IonButton key={"endtime-"+timeString} color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.onEndChange(timeString)}> {timeString} </IonButton>
            )

            firstTimeMins = firstTimeMins + duration
        }

        return endTimeArr
    }

    endTime()
    {
        if($a.isNull(this.state.PrSchedule.start))
        {
            return
        }
        let firstTime      = this.state.PrSchedule.start
        let firstTimeMins  = parseInt(firstTime.split(":")[0])*60 + parseInt(firstTime.split(":")[1])
        firstTimeMins     += parseInt(this.state.PrSchedule.duration) * parseInt(this.state.PrSchedule.slots)

        let timeString     = parseInt(firstTimeMins / 60).toString()
        timeString         = String("0" + timeString).slice(-2);
        timeString        += ":"
        timeString        += String("0" + (firstTimeMins % 60).toString()).slice(-2)

        return timeString
    }

    getButtons()
    {
        let buttons = []
        let buttonsTime = []
        let buttonsMon = []
        let buttonsTue = []
        let buttonsWed = []
        let buttonsThu = []
        let buttonsFri = []
        let buttonsSat = []
        let buttonsSun = []

        for(let index = 0; index < this.state.PrSchedule.slots; index++)
        {
            let firstTime      = this.state.PrSchedule.start
            let firstTimeMins  = parseInt(firstTime.split(":")[0])*60 + parseInt(firstTime.split(":")[1])
            firstTimeMins     += parseInt(this.state.PrSchedule.duration) * index

            let timeString     = parseInt(firstTimeMins / 60).toString()
            timeString         = String("0" + timeString).slice(-2);
            timeString        += ":"
            timeString        += String("0" + (firstTimeMins % 60).toString()).slice(-2)

            buttonsTime.push(<IonButton key={"All-" + timeString}       color="primary"                                                             onClick={() => this.updateScheduleHour(index)}         >{timeString}</IonButton>)
            buttonsMon.push( <IonButton key={"Monday-" + timeString}    color={this.state.PrSchedule.Monday[index]    == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Monday"   , index)}>{timeString}</IonButton>)
            buttonsTue.push( <IonButton key={"Tuesday-" + timeString}   color={this.state.PrSchedule.Tuesday[index]   == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Tuesday"  , index)}>{timeString}</IonButton>)
            buttonsWed.push( <IonButton key={"Wednesday-" + timeString} color={this.state.PrSchedule.Wednesday[index] == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Wednesday", index)}>{timeString}</IonButton>)
            buttonsThu.push( <IonButton key={"Thursday-" + timeString}  color={this.state.PrSchedule.Thursday[index]  == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Thursday" , index)}>{timeString}</IonButton>)
            buttonsFri.push( <IonButton key={"Friday-" + timeString}    color={this.state.PrSchedule.Friday[index]    == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Friday"   , index)}>{timeString}</IonButton>)
            buttonsSat.push( <IonButton key={"Saturday-" + timeString}  color={this.state.PrSchedule.Saturday[index]  == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Saturday" , index)}>{timeString}</IonButton>)
            buttonsSun.push( <IonButton key={"Sunday-" + timeString}    color={this.state.PrSchedule.Sunday[index]    == 1 ? "success" : "danger"} onClick={() => this.updateSchedule("Sunday"   , index)}>{timeString}</IonButton>)
            
        }

        buttons.push(<div key="time">{buttonsTime}</div>)
        buttons.push(<div key="Monday">{buttonsMon}</div>)
        buttons.push(<div key="Tuesday">{buttonsTue}</div>)
        buttons.push(<div key="Wednesday">{buttonsWed}</div>)
        buttons.push(<div key="Thursday">{buttonsThu}</div>)
        buttons.push(<div key="Friday">{buttonsFri}</div>)
        buttons.push(<div key="Saturday">{buttonsSat}</div>)
        buttons.push(<div key="Sunday">{buttonsSun}</div>)

        return buttons
    }

    updateSchedule(day, index)
    {
        let PrSchedule = this.state.PrSchedule
        PrSchedule[day][index] = PrSchedule[day][index] == 1 ? 0 : 1
        this.setState({PrSchedule:PrSchedule})

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(PrSchedule, this.state.schedule_ID)
        }
    }

    updateScheduleDay(day)
    {
        let newstate = 1
        let PrSchedule = this.state.PrSchedule
        if(PrSchedule[day][0] == newstate)
        {
            newstate = 0
        }

        for(let i = 0; i < PrSchedule[day].length; i++)
        {
            PrSchedule[day][i] = newstate
        }

        this.setState({PrSchedule:PrSchedule})

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(PrSchedule, this.state.schedule_ID)
        }
    }

    updateScheduleAll()
    {
        let newstate = 1
        let PrSchedule = this.state.PrSchedule
        if(PrSchedule.Monday[0] == newstate)
        {
            newstate = 0
        }

        for(let i = 0; i < PrSchedule.Monday.length; i++)
        {
            PrSchedule.Monday[i]    = newstate
            PrSchedule.Tuesday[i]   = newstate
            PrSchedule.Wednesday[i] = newstate
            PrSchedule.Thursday[i]  = newstate
            PrSchedule.Friday[i]    = newstate
            PrSchedule.Saturday[i]  = newstate
            PrSchedule.Sunday[i]    = newstate
        }

        this.setState({PrSchedule:PrSchedule})

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(PrSchedule, this.state.schedule_ID)
        }
    }

    updateScheduleHour(index)
    {
        let newstate = 1
        let PrSchedule = this.state.PrSchedule
        if(PrSchedule.Monday[index] == newstate)
        {
            newstate = 0
        }

        PrSchedule.Monday[index]    = newstate
        PrSchedule.Tuesday[index]   = newstate
        PrSchedule.Wednesday[index] = newstate
        PrSchedule.Thursday[index]  = newstate
        PrSchedule.Friday[index]    = newstate
        PrSchedule.Saturday[index]  = newstate
        PrSchedule.Sunday[index]    = newstate

        this.setState({PrSchedule:PrSchedule})

        if(typeof this.props.onChange === "function")
        {
            this.props.onChange(PrSchedule, this.state.schedule_ID)
        }
    }

    render()
    {
        return (

            <div style={{overflowX: "auto"}}>

                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    { this.props.showHeader === true &&
                        <IonHeader translucent>
                            <IonToolbar>
                                <IonTitle>{i18n.t("L_PROPERTY_SCHEDULE_TITLE")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                    }

                    <IonList>

                        <IonItem>
                            <IonLabel>{i18n.t("L_PROPERTY_SCHEDULE_DURATION")}</IonLabel>
                            <IonButton key={"30min"} color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.onDurationChange(30)}> {i18n.t("L_PROPERTY_SCHEDULE_30_MIN")} </IonButton>
                            <IonButton key={"60min"} color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.onDurationChange(60)}> {i18n.t("L_PROPERTY_SCHEDULE_60_MIN")} </IonButton>
                        </IonItem>

                        <IonItem>
                            <IonLabel>{i18n.t("L_PROPERTY_SCHEDULE_START_TIME")}</IonLabel>
                            {this.generateStartTimeList()}
                        </IonItem>

                        <IonItem>
                            <IonLabel>{i18n.t("L_PROPERTY_SCHEDULE_END_TIME")}</IonLabel>
                            {this.generateEndTimeList()}
                        </IonItem>
                        
                        <IonItem>

                            <div style = {{height:300, marginRight:5}}>
                                <IonButton key={"All"}       color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleAll()}           >{i18n.t("L_ALL_ON_OFF")}      </IonButton>
                                <IonButton key={"Monday"}    color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Monday")}   >{i18n.t("L_WEEK.L_MONDAY")}   </IonButton>
                                <IonButton key={"Tuesday"}   color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Tuesday")}  >{i18n.t("L_WEEK.L_TUESDAY")}  </IonButton>
                                <IonButton key={"Wednesday"} color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Wednesday")}>{i18n.t("L_WEEK.L_WEDNESDAY")}</IonButton>
                                <IonButton key={"Thursday"}  color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Thursday")} >{i18n.t("L_WEEK.L_THURSDAY")} </IonButton>
                                <IonButton key={"Friday"}    color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Friday")}   >{i18n.t("L_WEEK.L_FRIDAY")}   </IonButton>
                                <IonButton key={"Saturday"}  color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Saturday")} >{i18n.t("L_WEEK.L_SATURDAY")} </IonButton>
                                <IonButton key={"Sunday"}    color="primary" expand="block" style={{marginBottom:8}} onClick={() => this.updateScheduleDay("Sunday")}   >{i18n.t("L_WEEK.L_SUNDAY")}   </IonButton>
                            </div>

                            <div style = {{overflowX: "auto", whiteSpace: "nowrap", height:300}}>
                                    {this.getButtons()}
                            </div>

                        </IonItem>

                        { this.props.showButtons === true &&
                            <IonItem style={{marginTop: 10}}>
                                <IonButton key={"Submit"} color="primary" expand="block" size="medium" style={{marginRight: 20, marginLeft:"auto"}} onClick={() => this.saveData()}>{i18n.t("L_SUBMIT")}</IonButton>
                                <IonButton key={"Cancel"} color="primary" expand="block" size="medium" onClick={() => this.props.onClose(false)}>{i18n.t("L_CANCEL")}</IonButton>
                            </IonItem>
                        }

                    </IonList>

                </div>
        )
    }
}
