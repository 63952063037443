/* global i18n:true, React:true, $a:true */
import '../global.js'

import { IonHeader,IonToolbar,IonTitle,IonImg,IonButton,IonIcon, IonText } from '@ionic/react';
import logo      from '../theme/img/logo.png'

export const CbHeader = ({title="",...props}) =>
{
    return(
        <IonHeader translucent>
            <IonToolbar >
                <IonButton key="main" fill={"clear"} style={{height:"46px"}}>
                    <IonImg src={logo} style={{height:"46px",width:"46px"}} className={"ion-align-self-start"}/> 
                    <IonTitle>{title}</IonTitle>
                </IonButton>
                { getButtons(props)
                }
            </IonToolbar>
        </IonHeader>
    )
}

function getButtons(props)
{
    let buttons = []

    if(!$a.isNull(props.buttons))
    {
        for(let i = 0; i < props.buttons.length; i++)
        {
            let button = props.buttons[i]

            buttons.push(
                <IonButton 
                    key     = {button.id + "-" + i}
                    style   = {{marginRight:10}} 
                    slot    = "end" 
                    onClick = {() => button.callback()} 
                >

                    <IonIcon 
                        slot = "start" 
                        icon = {button.icon} 
                        size = {"small"} 
                    />

                    <IonText>{i18n.t(button.label)}</IonText>

                </IonButton>
            )
        }
    }

    return buttons
}