import $config          from './app.json'
import i18n             from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend          from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import EN from './locales/en/translation.json';
import IT from './locales/it/translation.json';


const resources =
{
    en:
    {
        translation: EN
    },
    it:
    {
        translation: IT
    }
};

//see https://github.com/i18next/i18next-browser-languageDetector
const DetectionOtions =
{
    // order and from where user language should be detected
  //order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  order: ['querystring','navigator'],


  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  //lookupCookie: 'i18next',
  //lookupLocalStorage: 'i18nextLng',
  //lookupSessionStorage: 'i18nextLng',
  //lookupFromPathIndex: 0,
  //lookupFromSubdomainIndex: 0,

  // cache user language on
  //caches: ['localStorage', 'cookie'],
  //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  //cookieMinutes: 10,
  //cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  //htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  //cookieOptions: { path: '/', sameSite: 'strict' }
}




i18n.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({

    debug: $config.i18nLog,
    resources,
    fallbackLng: "en",
    //keySeparator: '.',

    interpolation:
    {
        escapeValue: false // react already safes from xss
    },

    /*
    backend: 
    {
        // for all available options read the backend's repository readme file
        loadPath: 'locales/{{lng}}.json'
    },
*/
    react:
    {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
    detection: DetectionOtions,
   // wait:          true,
});

i18n.loadNamespaces('translation', (err) => { /* resources have been loaded */ })

export default i18n;