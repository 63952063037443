/* global $c:true, $a:true, i18n:true, React:true */
import '../../global.js'
import AppContext from '../../AppContext'
import { IonRow, IonCol, IonImg } from '@ionic/react';

import GooglePlacesAutocomplete, {geocodeByAddress, geocodeByLatLng} from 'react-google-places-autocomplete';

import CmpFrmItem      from '../form_components/cmp_frm_item'

class CmpFrmAddress extends React.Component
{
    static contextType = AppContext

    static defaultProps =
    {
        address  : {},
        colSize  : 12,
        onChange : () => {}
    }

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn         : 'gate.agent.geo.post_code',
                        name       : 'post-code',
                        gname      : ["postal_code"],
                        value      : "",
                        type       : "post-code",
                        label      : "L_POST_CODE",
                        required   : true,
                        disabled   : true,
                        visibility : "hidden",
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 10,
                        keyboard   : "text"
                    },
                    {
                        dn         : 'gate.agent.geo.locality',
                        name       : "locality",
                        gname      : ["locality","postal_town"],
                        value      : "",
                        type       : "locality",
                        label      : "L_LOCALITY",
                        error      : "",
                        required   : true,
                        disabled   : true,
                        visibility : "hidden",
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 30,
                        keyboard   : "text"
                    },
                    {
                        dn         : 'gate.agent.geo.street_number',
                        name       : "street_number",
                        gname      : ["street_number"],
                        value      : "",
                        type       : "street_number",
                        label      : "L_STREET_NUMBER",
                        error      : "",
                        required   : true,
                        disabled   : true,
                        visibility : "hidden",
                        regex      : "string-strict",
                        min_value  : 1,
                        max_value  : 30,
                        keyboard   : "text"
                    },
                    {
                        dn         : 'gate.agent.geo.flat_number',
                        name       : "flat_number",
                        gname      : ["flat_number"],
                        value      : "",
                        type       : "flat_number",
                        label      : "L_FLAT_NUMBER",
                        error      : "",
                        required   : false,
                        disabled   : true,
                        visibility : "hidden",
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 10,
                        keyboard   : "text"
                    },
                    {
                        dn         : 'gate.agent.geo.street_name',
                        name       : "street_name",
                        gname      : ["route"],
                        value      : "",
                        type       : "street_name",
                        label      : "L_STREET_NAME",
                        error      : "",
                        required   : true,
                        disabled   : true,
                        visibility : "hidden",
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 100,
                        keyboard   : "text"
                    },
                    {
                        dn         : 'gate.agent.geo.country',
                        name       : "country",
                        gname      : ["country"],
                        code       : "",
                        value      : "",
                        type       : "country",
                        label      : "L_COUNTRY",
                        error      : "",
                        required   : true,
                        disabled   : true,
                        visibility : "hidden",
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 30,
                        keyboard   : "text"
                    },
                    {
                        name       : "address",
                        gname      : "",
                        value      : "",
                        required   : true,
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 100,
                    },
                    {
                        name       : "admin_level_1",
                        gname      : ["administrative_area_level_1"],
                        value      : "",
                        required   : true,
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 30,
                    }, 
                    { 
                        name       : "admin_level_2",
                        gname      : ["administrative_area_level_2"],
                        value      : "",
                        required   : true,
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 30,
                    },
                    {
                        name       : "neighborhood",
                        gname      : "",
                        value      : "",
                        required   : false,
                        regex      : "string-strict",
                        min_value  : 0,
                        max_value  : 30,
                    }, 
                    { 
                        name       : "latitude",
                        gname      : "",
                        value      : 0,
                        required   : true,
                        regex      : "coordinate",
                        min_value  : 0,
                        max_value  : 30,
                    },
                    {
                        name       : "longitude",
                        gname      : "",
                        value      : 0,
                        required   : true,
                        regex      : "coordinate",
                        min_value  : 0,
                        max_value  : 30,
                    }
                ]
            },

            imgURL : ""
        }

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onDismiss                    = this.onDismiss.bind(this)
        this.addressOnChange              = this.addressOnChange.bind(this)
        this.onValidate                   = this.onValidate.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMountAddress", this.constructor.name,this.props)
        let form = this.state.form

        if(!$a.isNull(this.props.address))
        {
            form.fields[0].value  = this.props.address.post_code
            form.fields[1].value  = this.props.address.locality
            form.fields[2].value  = this.props.address.street_number
            form.fields[3].value  = this.props.address.flat_number
            form.fields[4].value  = this.props.address.street_name
            form.fields[5].value  = this.props.address.country
            form.fields[5].code   = this.props.address.country_code
            form.fields[6].value  = this.props.address.address
            form.fields[7].value  = this.props.address.admin_level_1
            form.fields[8].value  = this.props.address.admin_level_2
            form.fields[9].value  = this.props.address.neighborhood
            form.fields[10].value = this.props.address.latitude
            form.fields[11].value = this.props.address.longitude

            form.fields[0].visibility  = "visible"
            form.fields[1].visibility  = "visible"
            form.fields[2].visibility  = "visible"
            form.fields[3].visibility  = "visible"
            form.fields[4].visibility  = "visible"
            form.fields[5].visibility  = "visible"
        }
        else
        {
            form.fields[6].value       = ""
            form.fields[0].visibility  = "hidden"
            form.fields[1].visibility  = "hidden"
            form.fields[2].visibility  = "hidden"
            form.fields[3].visibility  = "hidden"
            form.fields[4].visibility  = "hidden"
            form.fields[5].visibility  = "hidden"
        }

        this.setState({form : form, imgURL : ""})

    }

    componentDidUpdate(prevProps)
    {
        if(!$a.isNull(this.props.address))
        {
            // address combines all fields to uniquely identify address, 
            // so it's enough to compare this property to determine if address has changed
            if(this.props.address.address !== prevProps.address.address)
            {
                let form = this.state.form
                form.fields[0].value  = this.props.address.post_code
                form.fields[1].value  = this.props.address.locality
                form.fields[2].value  = this.props.address.street_number
                form.fields[3].value  = this.props.address.flat_number
                form.fields[4].value  = this.props.address.street_name
                form.fields[5].value  = this.props.address.country
                form.fields[5].code   = this.props.address.country_code
                form.fields[6].value  = this.props.address.address
                form.fields[7].value  = this.props.address.admin_level_1
                form.fields[8].value  = this.props.address.admin_level_2
                form.fields[9].value  = this.props.address.neighborhood
                form.fields[10].value = this.props.address.latitude
                form.fields[11].value = this.props.address.longitude

                form.fields[0].visibility  = "visible"
                form.fields[1].visibility  = "visible"
                form.fields[2].visibility  = "visible"
                form.fields[3].visibility  = "visible"
                form.fields[4].visibility  = "visible"
                form.fields[5].visibility  = "visible"

                this.setState({form : form, imgURL : ""})
            }
            else if(this.props.address.address === undefined)
            {
                let form = this.state.form
                form.fields[6].value       = ""

                if(form.fields[0].visibility  !== "hidden")
                {
                    form.fields[0].visibility  = "hidden"
                    form.fields[1].visibility  = "hidden"
                    form.fields[2].visibility  = "hidden"
                    form.fields[3].visibility  = "hidden"
                    form.fields[4].visibility  = "hidden"
                    form.fields[5].visibility  = "hidden"

                    this.setState({form : form, imgURL : ""})
                }
            }
        }
    }

    onChange(el)
    {
        console.log("onChange",el)
        let value = el.target.value
        let id    = parseInt(el.target.id)

        let form              = this.state.form
        form.fields[id].value = value

        this.setState({form: form})

        let data =
        {
            post_code     : form.fields[0].value,
            locality      : form.fields[1].value,
            street_number : form.fields[2].value,
            flat_number   : form.fields[3].value,
            street_name   : form.fields[4].value,
            country       : form.fields[5].value,
            country_code  : form.fields[5].code,
            address       : form.fields[6].value,
            admin_level_1 : form.fields[7].value,
            admin_level_2 : form.fields[8].value,
            neighborhood  : form.fields[9].value,
            latitude      : form.fields[10].value,
            longitude     : form.fields[11].value
        };

        this.props.onChange(data)
    }

    onDismiss(e)
    {
        let label = this.state.form.fields[5].value + ", " // country
        label    += this.state.form.fields[1].value + ", " // locality
        label    += this.state.form.fields[0].value + ", " // postcode

        if(e.target.dn === "gate.agent.geo.street_name")
        {
            label += e.target.value
        }
        else if (this.state.form.fields[4].value != "")
        {
            label    += this.state.form.fields[4].value + " "
        }
        else
        {
            // street name not populated -> DO NOT CALL GOOGLE APIS
            return
        }

        if(e.target.dn === "gate.agent.geo.street_number")
        {
            label += e.target.value
        }
        else if (this.state.form.fields[2].value != "")
        {
            label    += this.state.form.fields[2].value
        }
        else
        {
            // street number not populated -> DO NOT CALL GOOGLE APIS
            return
        }

        this.addressOnChange({label:label})
    }

    onValidate()
    {
        let formFields = this.state.form.fields

        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return false
            }
        }

        for (const f of formFields)
        {
            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)
                
                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return false
            }
        }

        return true
    }

    async addressOnChange(e)
    {
        let geocode = await geocodeByAddress(e.label);

        let addressComp = geocode[0].address_components;
        let form        = this.state.form

        for(let k = 0; k < form.fields.length; k++)
        {
            let found = false
            form.fields[k].value = ""

            for(let j = 0; j < form.fields[k].gname.length; j++)
            {
                let key = form.fields[k].gname[j]

                for(let i = 0; i < addressComp.length; i++)
                {
                    let idx = addressComp[i].types.indexOf(key)
                    if(idx >= 0)
                    {
                        found = true
                        form.fields[k].value = addressComp[i].long_name

                        if(key === "country")
                        {
                            form.fields[k].code = addressComp[i].short_name
                        }

                        break
                    }
                }

                if(found)
                {
                    break
                }
            }

            form.fields[k].disabled = found
        }

        form.fields[6].value  = geocode[0].formatted_address
        form.fields[10].value = geocode[0].geometry.location.lat().toString()
        form.fields[11].value = geocode[0].geometry.location.lng().toString()

        // do reverse geocoding to find neighborhood
        let goeAddress = await geocodeByLatLng({ lat: geocode[0].geometry.location.lat(), lng: geocode[0].geometry.location.lng() })

        let found = false
        for(let k = 0; k < goeAddress.length; k++)
        {
            let result = goeAddress[k]
            for(let j = 0; j < result.address_components.length; j++)
            {
                let addressComponent = result.address_components[j]
                for(let i = 0; i < addressComponent.types.length; i++)
                {
                    if(addressComponent.types[i] === "neighborhood")
                    {
                        form.fields[9].value = addressComponent.long_name
                        found = true
                        break
                    }
                }

                if(found)
                {
                    break
                }
            }

            if(found)
            {
                break
            }
        }


        form.fields[0].visibility  = this.props.address.post_code
        form.fields[1].visibility  = "visible"
        form.fields[2].visibility  = "visible"
        form.fields[3].visibility  = "visible"
        form.fields[4].visibility  = "visible"
        form.fields[5].visibility  = "visible"

        let zoom = 16

        if(this.props.zoom !== undefined)
        {
            zoom = this.props.zoom
        }

        let size = "500x200"
        if(this.props.size !== undefined)
        {
            size = this.props.size
        }

        let imgURL = $c.GoogleMapsUrl
        imgURL     = imgURL.replace(":ZOOM:", zoom)
        imgURL     = imgURL.replace(":SIZE:", size)
        imgURL     = imgURL.replace(":LAT:" , form.fields[10].value)
        imgURL     = imgURL.replace(":LNG:" , form.fields[11].value)
        imgURL     = imgURL.replace(":KEY:" , $c.GoogleMapKey)

        let data =
        {
            post_code     : form.fields[0].value,
            locality      : form.fields[1].value,
            street_number : form.fields[2].value,
            flat_number   : form.fields[3].value,
            street_name   : form.fields[4].value,
            country       : form.fields[5].value,
            country_code  : form.fields[5].code,
            address       : form.fields[6].value,
            admin_level_1 : form.fields[7].value,
            admin_level_2 : form.fields[8].value,
            neighborhood  : form.fields[9].value,
            latitude      : form.fields[10].value,
            longitude     : form.fields[11].value
        };

        this.props.onChange(data)

        this.setState({form:form, imgURL:imgURL})
    }

    render()
    {
        return (
            <>
                <GooglePlacesAutocomplete 
                    apiKey                = {$c.GoogleAutocompleteKey} 
                    minLengthAutocomplete = {3} 
                    debounce              = {500}

                    apiOptions = 
                    {{
                        language : i18n.translator.language.split("-")[0] // this should be based on country of address (probably would be good to do this in back end)
                    }}

                    selectProps           = 
                    {{
                        value        : this.state.form.fields[6].value,
                        onChange     : this.addressOnChange,
                        placeholder  : i18n.t("L_TYPE_ADDRESS"),
                        theme        : (provided) => ({...provided, borderRadius:5, colors: {...provided.colors, primary: "#FF2D56" }}),
                        styles       : 
                        {
                            menu    : (provided) => ({...provided, "z-index": 10000}),
                            control : (provided) => ({...provided, borderWidth: "0px 0px 1px 0px", borderRadius: 0}),
                        }
                    }}
                />

                <IonRow>
                    <IonCol size={this.props.colSize}><CmpFrmItem id="2" item={this.state.form.fields[2]} onChange={this.onChange} onDismiss={(val) => this.onDismiss(val)}/></IonCol>
                    <IonCol size={this.props.colSize}><CmpFrmItem id="3" item={this.state.form.fields[3]} onChange={this.onChange}/></IonCol>
                    <IonCol size={this.props.colSize}><CmpFrmItem id="4" item={this.state.form.fields[4]} onChange={this.onChange} onDismiss={(val) => this.onDismiss(val)}/></IonCol>
                    <IonCol size={this.props.colSize}><CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/></IonCol>
                    <IonCol size={this.props.colSize}><CmpFrmItem id="1" item={this.state.form.fields[1]} onChange={this.onChange}/></IonCol>
                    <IonCol size={this.props.colSize}><CmpFrmItem id="5" item={this.state.form.fields[5]} onChange={this.onChange}/></IonCol>
                </IonRow>

                { this.state.imgURL != "" &&
                    <>
                        <br/><br/>
                        <IonImg src={this.state.imgURL} />
                        <br/><br/>
                    </>
                }
            </>
        )
    }
}

export default CmpFrmAddress