/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */
import '../../global.js'

import React from 'react';

import { IonPage, IonButton, IonRow, IonContent, IonImg, IonCard, IonRippleEffect, IonText } from '@ionic/react';
import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpFrmItem      from '../form_components/cmp_frm_item'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'

export default class PageQrCode extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : '',
                        name      : "otp",
                        value     : "",
                        type      : "otp",
                        label     : "L_GATE_USER_OTP",
                        error     : "",
                        required  : true,
                        regex     : "otp",
                        min       : 0,
                        max       : 6,
                        keyboard  : "text"
                    }
                ]
            },

            ready          : false,
            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.componentDidMount            = this.componentDidMount(this)
        this.verifyTOTP                   = this.verifyTOTP.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onValidate                   = this.onValidate.bind(this)

    }

    componentDidMount()
    {
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onChange(el)
    {
        console.log("onChange",el)

        let value = el.target.value
        let id    = parseInt(el.target.id)

        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("L_GATE_USER_OTP_required")
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return
            }
        }

        for (const f of formFields)
        {
            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)

                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return
            }
        }

        this.state.ready = true
    }

    async verifyTOTP(e)
    {
        e.preventDefault();
        e.stopPropagation();

        console.log("verifyTOTP")

        this.onValidate(this.state.form.fields);

        if(this.state.ready === true)
        {
            this.context.set("loading",true)

            let data = this.props.history.location.state.data
            data.otp = this.state.form.fields[0].value

            console.log(data)

            this.setState({ isLoading:true })

            let r = await $a.Api.Call("Gate/User/Login/",data)

            this.setState({isLoading:false})

            if ($a.Api.ErrorCheck(r))
            {
                this.state.form.fields[0].value = ""
                this.context.set("error_code", "A0002")
                this.context.set("error"     , true)
                return
            }

            r=r.result;

            // USER LOGGED IN
            if(!$a.isNull(r.language))
            {
                i18n.changeLanguage(r.language)
            }

            this.props.history.replace({pathname: '/home'})
            $a.Store.Set("token",r.token)
            this.context.set("loggedIn", true)

            if(r.hasPendingDocuments == 1)
            {
                // USER HAS DOCUMENTS TO ACCEPT (T&C, PRIVACY ...)
                this.sleep(100).then(() =>
                {
                    this.context.set("pendingDocs", true)
                })
            }
        }
    };


    render() 
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">

                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/><br/>
                    
                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding">

                        <IonText>
                            <h5>{i18n.t("L_OTP_INSTR_BODY")}</h5>
                        </IonText>

                            <CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.verifyTOTP}>
                                    {i18n.t('L_VERIFY')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    }
}