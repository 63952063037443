/* global $c:true, i18n:true, React:true */
import './global.js'

import ReactDOM            from 'react-dom';
import { BrowserRouter }   from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import AppProvider         from './AppProvider'
import AppContext          from './AppContext'
import AppLoader           from './AppLoader';

if(!$c.jsLog)
{
    console.log = function(){};
}

// To set dark mode based on the user preferences

/////const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
/////toggleDarkTheme(prefersDark.matches);
////prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));


//TO SET DARK MODE IN ANY CASE
//toggleDarkTheme(true)


// Add or remove the "dark" class based on if the media query matches
/////function toggleDarkTheme(shouldAdd) {
  /////document.body.classList.toggle('dark', shouldAdd);
//////}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <AppProvider>
                <AppContext.Consumer>
                    {context => (<AppLoader />)}
                </AppContext.Consumer>
            </AppProvider>
        </BrowserRouter>
    </I18nextProvider>,
    document.getElementById('root')
)
    