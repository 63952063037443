/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonRow, IonImg, IonRouterLink, IonCard, IonPage, IonProgressBar, 
         IonCardTitle, IonTextarea, IonButton, IonRippleEffect } from '@ionic/react'

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'
import CmpFrmAddress   from '../form_components/cmp_frm_address'

export default class PageAddress extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            address        : {},

            ready          : false,

            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.stepNumber = 2
        this.steps      = 4

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.nextStep                     = this.nextStep.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
        // if address prepolutated
        if(!$a.isNull(this.props.history.location.state))
        {
            if(!$a.isNull(this.props.history.location.state.data.geohq))
            {
                this.setState({address : this.props.history.location.state.data.geohq})
            }
        }
        else
        {
            this.props.history.replace({pathname: '/register/step/one'})
        }
    }

    onChangeAddress(address)
    {
        console.log("DATA", address, this.props)
        this.setState({address:address})
    }

    onValidateAddres()
    {
        let address =
        [
            {
                name      : "address",
                value     : this.state.address.address,
                label     : "L_ADDRESS",
                required  : true,
                regex     : "string-strict",
                min_value : 0,
                max_value : 100,
            },
            {
                name      : "street_number",
                value     : this.state.address.street_number,
                label     : "L_STREET_NUMBER",
                required  : true,
                regex     : "string-strict",
                min_value : 1,
                max_value : 30,
            }
        ]

        return this.onValidate(address)
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                return false
            }

            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)

                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                return false
            }
        }

        return true
    }

    nextStep()
    {
        if(!this.onValidateAddres())
        {
            return
        }

        let data = this.props.history.location.state.data
        data.geohq = this.state.address

        this.props.history.replace({pathname: '/register/step/four', state: {data:data}})
    }

    render()
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/>
                    <IonProgressBar value={this.stepNumber/this.steps} style={{maxWidth:500, height:10, borderRadius:5, margin:"auto"}}/>
                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_REGISTRATION.L_HQ_ADDRESS')}
                                </IonCardTitle>
                            </IonRow>

                            <CmpFrmAddress 
                                ref      = {ref => this.addressRef = ref}
                                address  = {this.state.address}
                                onChange = {(e) => this.onChangeAddress(e)}
                            />

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.nextStep}>
                                    {i18n.t('L_CONTINUE')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>

                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonTextarea note style={{ padding:'2%', height: 80 }} >
                            {i18n.t('L_REGISTRATION.L_INFO') }
                            <a href={"mailto:" + i18n.t('SupportMail')}>{i18n.t('SupportMail')}</a>
                        </IonTextarea>

                        <p style={{fontSize:"small"}} className="ion-text-center">

                            <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                            <br/><br/>

                            {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                        </p>
                    </IonCard>

                    <br/>

                </IonContent>
            </IonPage>
        )
    }
}