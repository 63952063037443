/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonButton,IonRippleEffect, IonRow, IonImg, IonRouterLink, 
         IonCard, IonPage, IonProgressBar, IonCardTitle, IonTextarea } from '@ionic/react'

import LogoLogin        from '../../theme/img/logo_horizontal.png'
import CmpFrmItem       from '../form_components/cmp_frm_item'
import CmpFrmItemPhone  from '../form_components/cmp_frm_item_phone'
import CmpFrmItemSelect from '../form_components/cmp_frm_item_select'
import CmpErrorAlert    from '../cmp_error_alert'
import CmpLoading       from '../cmp.loading'

export default class PageRegisterStepCompany extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : 'gate.agent.name',
                        name      : "ouName",
                        value     : "",
                        type      : "name",
                        label     : "L_GATE_OU_NAME",
                        error     : "",
                        required  : true,
                        regex     : "string-proper-noun",
                        min_value : 3,
                        max_value : 140,
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.agent.vat_number',
                        name      : 'vat_number',
                        value     : "",
                        type      : "name",
                        label     : "L_GATE_OU_VAT_NUMBER",
                        required  : true,
                        regex     : "vat-number",
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.agent.registration_number',
                        name      : "registration_number",
                        value     : "",
                        type      : "name",
                        label     : "L_GATE_OU_REGISTRATION_NUMBER",
                        required  : true,
                        regex     : "string-alphanumeric-capital",
                        min_value : 3,
                        max_value : 30,
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.agent.contact_email',
                        name      : "contact_email",
                        value     : "",
                        type      : "name",
                        label     : "L_GATE_OU_CONTACT_EMAIL",
                        required  : true,
                        regex     : "email",
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.agent.registered_email',
                        name      : "registered_email",
                        value     : "",
                        type      : "name",
                        label     : "L_GATE_OU_REGISTERED_EMAIL",
                        required  : true,
                        regex     : "email",
                        keyboard  : "text"
                    },
                    {
                        dn        : 'gate.agent.phone.number',
                        name      : 'phone_number',
                        value     : "",
                        prefix    : "+39",
                        type      : "number",
                        label     : "L_GATE_USER_PHONENUMBER",
                        required  : true,
                        regex     : "phone-number",
                        keyboard  : "decimal"
                    },
                    {
                        dn        : 'gate.agent.languages',
                        name      : "languages",
                        value     : [],
                        selections: ["de", "en", "es", "fr", "it", "pl", "pt", "ro", "ru", "zh"],
                        multi     : true,
                        label     : "L_GATE_OU_LANGUAGES",
                        required  : true,
                        regex     : "string-proper-noun",
                    }
                ]
            },

            ready          : false,

            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.stepNumber = 1
        this.steps      = 4

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onChangePrefix               = this.onChangePrefix.bind(this)
        this.onValidate                   = this.onValidate.bind(this)
        this.nextStep                     = this.nextStep.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        if(!$a.isNull(this.props.history.location.state))
        {
            if(!$a.isNull(this.props.history.location.state.data))
            {
                this.state.form.fields[0].value  = this.props.history.location.state.data.ouName
                this.state.form.fields[1].value  = this.props.history.location.state.data.vat_number

                if(!$a.isNull(this.props.history.location.state.data.email))
                {
                    let email = this.props.history.location.state.data.email.filter(obj => {
                        return obj.dm_email_type_id == "2"
                    })

                    if(!$a.isEmpty(email))
                    {
                        this.state.form.fields[4].value  = email[0].email
                    }

                    email = this.props.history.location.state.data.email.filter(obj => {
                        return obj.dm_email_type_id == "1"
                    })

                    if(!$a.isEmpty(email))
                    {
                        this.state.form.fields[3].value  = email[0].email
                    }
                }


                this.state.form.fields[2].value  = this.props.history.location.state.data.registration_number

                if(!$a.isNull(this.props.history.location.state.data.phone))
                {
                    let phone_number = this.props.history.location.state.data.phone.filter(obj => {
                        return obj.dm_phone_type_id == "2"
                    })

                    if(!$a.isEmpty(phone_number))
                    {
                        this.state.form.fields[5].prefix = phone_number[0].country_code
                        this.state.form.fields[5].value  = phone_number[0].number
                    }
                }

                this.state.form.fields[6].value  = this.props.history.location.state.data.languages
            }
        }
        else
        {
            this.props.history.replace({pathname: '/register/step/one'})
        }
    }

    onChange(el)
    {
        console.log("onChange",el)
        let value = el.target.value
        let id    = parseInt(el.target.id)
        let regex = $a.RegEx[this.state.form.fields[id].regex]

        if(el.target.name === "vat_number" || el.target.name === "registration_number" )
        {
            if(el.target.name === "vat_number")
            {
                regex = /[0-9]+$/
            }

            if(!$a.isNull(value))
            {
                value = value.toUpperCase()
                value = value.match(regex)

                if(!$a.isNull(value))
                {
                    value = value.join("")
                }
                else
                {
                    value = this.state.form.fields[id].value
                }
            }
        }

        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    onChangePrefix(el)
    {
        console.log("onChange",el)
        let value = el.target.value
        let id    = parseInt(el.target.id)

        this.state.form.fields[id].prefix = value
        this.forceUpdate()
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return
            }
        }

        for (const f of formFields)
        {
            if(f.name === "vat_number")
            {
                let vat_number = f.value
                // TODO: add country code based on IP address
                f.value = "IT" + vat_number
                console.log(f.value)

                if(!$a.Form.Validate.Type(f))
                {
                    f.value = vat_number
                    this.context.set("loading",false)

                    let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                    this.context.set("error",true)
                    this.context.set("error_message", msg)
                    this.state.ready = false
                    return
                }

                f.value = vat_number

            }
            else if (f.name === "languages")
            {
                let languages = f.value
                console.log("languages", languages)

                for(let i = 0; i < languages.length; i++)
                {
                    f.value = languages[i]
                    console.log(f.value)

                    if(!$a.Form.Validate.Type(f))
                    {
                        f.value = languages
                        this.context.set("loading",false)

                        let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                        this.context.set("error",true)
                        this.context.set("error_message", msg)
                        this.state.ready = false
                        return
                    }
                }

                f.value = languages
            }
            else if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)
                
                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return
            }
        }

        this.state.ready = true
        console.log('Form is ready')
    }

    async nextStep(e)
    {
        e.preventDefault();
        e.stopPropagation();

        console.log("onLogin")

        //alert(this.context.loading)
        this.onValidate(this.state.form.fields);

        if(this.state.ready === true)
        {
            let data                 = this.props.history.location.state.data
            data.ouName              = this.state.form.fields[0].value
            data.vat_number          = this.state.form.fields[1].value

            data.email = []

            data.email.push({
                email            : this.state.form.fields[4].value,
                dm_email_type_id : "2"
            })

            data.email.push({
                email            : this.state.form.fields[3].value,
                dm_email_type_id : "1"
            })

            data.registration_number = this.state.form.fields[2].value

            if(data.phone === undefined)
            {
                data.phone        = {}
            }

            data.phone = 
            [
                {
                    country_code     : this.state.form.fields[5].prefix,
                    number           : this.state.form.fields[5].value,
                    dm_phone_type_id : "2"
                }
            ]

            data.languages           = this.state.form.fields[6].value

            this.props.history.replace({pathname: '/register/step/three', state: {data:data}})
        }
    }

    render()
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/>
                    <IonProgressBar value={this.stepNumber/this.steps} style={{maxWidth:500, height:10, borderRadius:5, margin:"auto"}}/>
                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_REGISTRATION.L_COMPANY_DETAILS')}
                                </IonCardTitle>
                            </IonRow>

                            <CmpFrmItem       id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>
                            <CmpFrmItem       id="1" item={this.state.form.fields[1]} onChange={this.onChange}/>
                            <CmpFrmItem       id="2" item={this.state.form.fields[2]} onChange={this.onChange}/>
                            <CmpFrmItem       id="3" item={this.state.form.fields[3]} onChange={this.onChange}/>
                            <CmpFrmItem       id="4" item={this.state.form.fields[4]} onChange={this.onChange}/>
                            <CmpFrmItemPhone  id="5" item={this.state.form.fields[5]} onChange={this.onChange} onChangePrefix={this.onChangePrefix}/>
                            <CmpFrmItemSelect id="6" item={this.state.form.fields[6]} onChange={this.onChange}/>

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.nextStep}>
                                    {i18n.t('L_CONTINUE')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>
                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonTextarea note style={{ padding:'2%', height: 80 }} >
                            {i18n.t('L_REGISTRATION.L_INFO') }
                            <a href={"mailto:" + i18n.t('SupportMail')}>{i18n.t('SupportMail')}</a>
                        </IonTextarea>

                        <p style={{fontSize:"small"}} className="ion-text-center">

                            <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                            <br/><br/>

                            {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                        </p>
                    </IonCard>

                    <br/>

                </IonContent>
            </IonPage>
        )
    }
}