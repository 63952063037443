/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonButton,IonRippleEffect, IonRow, IonImg, IonRouterLink, IonCard, IonPage} from '@ionic/react'

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpFrmItem      from '../form_components/cmp_frm_item'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'

export default class PageVerifyEmail extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : '',
                        name      : "key",
                        value     : "",
                        type      : "key",
                        label     : "L_GATE_USER_OTP_EMAIL",
                        error     : "",
                        required  : false,
                        regex     : "otp",
                        min       : 7,
                        max       : 7,
                        keyboard  : "text"
                    }
                ]
            },

            ready          : false,
            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onValidate                   = this.onValidate.bind(this)
        this.onLogin                      = this.onLogin.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
    }

    onChange(el)
    {
        console.log("onChange",el)

        let value = el.target.value
        let id    = parseInt(el.target.id)

        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return
            }
        }

        for (const f of formFields)
        {
            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)

                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return
            }
        }

        this.state.ready = true
    }


    async onLogin(e)
    {
        e.preventDefault();
        e.stopPropagation();

        console.log("onLogin")

        this.onValidate(this.state.form.fields);

        if(this.state.ready === true)
        {
            this.context.set("loading",true)

            let username = this.props.history.location.state.data.username
            let password = this.props.history.location.state.data.password

            var data =
            {
                username               : username,
                password               : password,
                key                    : this.state.form.fields[0].value,
            };

            this.setState({ isLoading:true })

            let r = await $a.Api.Call("Gate/User/Login/",data)

            this.setState({isLoading:false})

            if ($a.Api.ErrorCheck(r))
            {
                this.state.form.fields[0].value = ""
                this.context.set("error_code", "A0001")
                this.context.set("error"     , true)
                return
            }

            r=r.result;

            // USER LOGGED IN
            $a.Store.Set("token",r.token)
            this.context.set("loggedIn", true)

            if(r.hasPendingDocuments == 1)
            {
                // USER HAS DOCUMENTS TO ACCEPT (T&C, PRIVACY ...)
                this.context.set("pendingDocs", true)
            }

            this.props.history.replace({pathname: '/home'})
        }
    }


    render()
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/><br/>
                    
                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.onLogin}>
                                    {i18n.t('L_CONTINUE')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>
                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <p style={{fontSize:"small"}} className="ion-text-center">

                            <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>
                                <br/><br/>

                                {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                        </p>
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    }
}