/* global  i18n:true, React:true */
import '../global.js'
import AppContext from '../AppContext'
import { IonLoading} from '@ionic/react';

export default class CmpLoading extends React.Component
{
    static contextType = AppContext

    static defaultProps =
    {
        isLoading: false
    }

    constructor()
    {
        super()

        console.log(this.constructor.name,this.props)

        this.state =
        {
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
    }

    render()
    {
        return(
        <>
            {this.props.isLoading &&
                <IonLoading
                    translucent = "true"
                    isOpen={this.context.loading}
                    //onDidDismiss={() => this.context.set("loading",false)}
                    message={i18n.t('L_LOADING')}
                />
            }
        </>
        )
    }
}