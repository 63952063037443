/* global i18n:true, React:true */
import '../global.js'
import AppContext from '../AppContext'

import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react';
import {IonRow,IonCol} from '@ionic/react';
import {IonItem,IonIcon,IonLabel,IonButton} from '@ionic/react'
import { pin, wifi, wine, warning, chevronForwardOutline } from 'ionicons/icons';
import i18n from '../i18n.js';

export const ChartCard = ({title="Title",chart ="<></>",link="/",height="420px"}) =>
{
    return(
        <IonCard style={{height: height}}>

            <IonCardHeader style={{}}>
                {/* <IonCardSubtitle>{title}</IonCardSubtitle> */}
                <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent style={{height:"60%"}}>
                {chart}
            </IonCardContent>

            <IonRow>
                <IonCol>
                    <IonButton fill={"clear"} className={'ion-float-right'} href={link}>
                        {i18n.t("L_DETAILS")}
                        <IonIcon slot="end" icon={chevronForwardOutline} />
                    </IonButton>
                </IonCol>
            </IonRow>

        </IonCard>
    )
}

