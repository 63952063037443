/* global $a:true, i18n:true, React:true,AppContext: true */
import '../global.js'
import { IonAlert} from '@ionic/react';

export default class CmpErrorAlert extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.resetError        = this.resetError.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
    }

    resetError()
    {
        this.context.set("error"          , false)
        this.context.set("error_code"     , 0    )
        this.context.set("error_message"  , ""   )
        this.context.set("error_parameter", ""   )
    }

    render()
    {

        let my_message = ""
 
        if(!$a.isNull(this.context.error_message))
        {
            my_message = this.context.error_message
        }
        else
        {
            my_message = i18n.t('E_'+this.context.error_code)
        }

        if(!$a.isNull(this.context.error_parameter))
        {
            my_message += "<br><br>" + i18n.t('P_'+this.context.error_parameter)
        }

        return (

            <IonAlert
                isOpen       = {this.context.error}
                onDidDismiss = {this.resetError}
                header       = {i18n.t('L_APP_NAME')}
                //subHeader={'Subtitle'}
                message      = {my_message }
                buttons      = {['Ok']}
            />

        )
    }
}



// import {Alert}  from 'react-native';

// export default class CmpError extends React.Component
// {
//     static contextType = AppContext

//     static defaultProps =
//     {
//         errorState   : false,
//         errorCode    : -1,
//         errorMessage : "",
//         //onErrorReset :  
//     }

//     constructor()
//     {
//         super()

//         this.state = {
//         }

//         this.componentDidMount     = this.componentDidMount.bind(this)
//         this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this)
//         this.showAlert             = this.showAlert.bind(this);
//         this.onErrorReset          = this.onErrorReset.bind(this);
//     }

//     componentDidMount()
//     {
//     }
    
//     componentDidUpdate()
//     {
//     }
//     shouldComponentUpdate(nextProps)
//     {
//         if(nextProps.errorState != this.props.errorState)
//         {
//             return true
//         }

//         return false
//     }

//     onErrorReset()
//     {
//         console.log(this.constructor.name,'onErrorReset' )
//         this.props.onErrorReset()
//     }

//     showAlert()
//     {
//         if(this.props.errorMessage !== "" && this.props.errorCode === -1)
//         {
//             Alert.alert(
//                     i18n.t('appName'),
//                     this.props.errorMessage,
//                 [
//                 /* {text: 'Ask me later', onPress: () => console.log('Ask me later pressed')},
//                 {
//                     text: 'Cancel',
//                     onPress: () => console.log('Cancel Pressed'),
//                     style: 'cancel',
//                 },*/
//                     { 
//                         text: 'OK', onPress: () => 
//                             this.onErrorReset()
//                     },
//                 ],
//                 {cancelable: false},
//             )
//         }
//         else if(this.props.errorMessage === "" && this.props.errorCode !== -1)
//         {
//             Alert.alert(
//                 i18n.t('appName'),
//                 i18n.t('E_'+this.props.errorCode),
//             [
//                 {text: 'OK', onPress: () => 
//                     this.onErrorReset()
//                 },
//             ],
//             {cancelable: false},
//             )

//         }
//         else
//         {
//             return
//         }

//         // else if(this.props.errorMessage !== "" && this.props.errorCode !== -1)
//         // {
//         //     Alert.alert(
//         //         i18n.t('E_'+this.props.errorCode),
//         //         this.props.errorMessage,
               
//         //     [
//         //         {text: 'OK', onPress: () => 
//         //             this.onErrorReset()
//         //         },
//         //     ],
//         //     {cancelable: false},
//         //     )

//         // }
//     }

//     render()
//     {
//         return (
//             <>
//                 {this.props.errorState && this.showAlert()}
//             </>
//         );
//     }
// }