/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
import '../../global.js'

import { IonPage, IonContent, IonCardHeader,IonCardTitle, IonCard,IonCardContent  } from '@ionic/react';
import { IonGrid, IonCol, IonRow, IonItem, IonToast } from '@ionic/react';
import { IonToggle, IonBadge, IonLabel, IonIcon, IonButton, IonModal } from '@ionic/react';

import DataTable, { createTheme } from 'react-data-table-component';
import { calendarOutline as calendar, addCircleOutline as add, 
         imageOutline as image, createOutline as edit,
         listOutline as list } from 'ionicons/icons';

import {CbHeader} from '../cb.header'
import PagePropertySchedule from './page.property.schedule';
import i18n from '../../i18n.js';

class PagePropertyList extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        console.log("Component","ScreenHome")

        super()

        this.state = 
        {
            data              : [],
            dataFiltered      : [],
            selectedRows      : [],
            showModalSchedule : false,
            PrProperty_ID     : "0",

            errorState        : false,
            errorCode         : -1,
            errorMessage      : "",
            showToast         : false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.handleRowSelected = this.handleRowSelected.bind(this)
        this.onAddProperty     = this.onAddProperty.bind(this)
        this.onShowMine        = this.onShowMine.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
        this.getData()
    }

    componentDidUpdate()
    {
        if(!$a.isNull(this.props.history.location.state))
        {
            if(this.props.history.location.state.update)
            {
                this.props.history.location.state.update = false
                this.getData()
            }
        }
    }

    async getData()
    {

// PrProperty_SubType_ID: "1"
// address: "viale dei colli portuensi, 242, 00151 roma, italia"
// currency: "EUR"
// id: "195976"
// published
// latitude: "41.8682"
// longitude: "12.4434"
// price: "1250"
// rating: "0"
// rooms: "3"
// sqmt: "100"
// task_type: "2"

        let r = await $a.Api.Call("RealEstate/Property/List/",{token:$a.Store.Get("token")})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        r = r.result;
        this.setState({data:r, dataFiltered:r})
    }

    onAddProperty()
    {
        this.props.history.replace({pathname: '/Property/Form', state: {PrProperty_ID:"0"}})
    }

    onShowMine()
    {
        let filteredData = this.state.data.filter(a=>a.XaUser_ID==this.context.XaUser_ID)
        this.setState({dataFiltered: filteredData})
    }

    showMenu()
    {
        this.menu.open()
    }

    async handleRowSelected(e)
    {
        await this.setState({selectedRows:e.selectedRows})
    };
      
    async togglePublish(id,checked)
    {
        let data =
        {
            token   : $a.Store.Get("token"),
            id      : id,
            publish : checked ? 1 : 0
        }

        let idx = this.state.data.findIndex(x => x.id === id);

        let r = await $a.Api.Call("RealEstate/Property/Publish/",data)

        if ($a.Api.ErrorCheck(r))
        {
            this.state.data[idx].published = checked ? 0 : 1
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        this.state.data[idx].published = checked ? 1 : 0
    }

    getSchedule(e,row)
    {
        this.setState({showModalSchedule:true, PrProperty_ID:row.id})
    }

    updateImage(e, row)
    {
        this.props.history.push({pathname: '/Property/Images', state: {PrProperty_ID:row.id}})
    }

    async updateProperty(e,row)
    {
        this.props.history.replace({pathname: '/Property/Form', state: {PrProperty_ID:row.id}})
    }

    render()
    {
        createTheme('solarized', {
            text: {
                primary: "var(--ion-text-color)"
                //secondary: '#2aa198',
            },
            background: {
                default: "var(--bbc-table-background-color)"
            },

            divider: {
                default: "var(--ion-color-medium)",
            }
        });

          const customStyles = 
          {
            rows: {
                style: {
                    //minHeight: '72px', // override the row height
                }
            },
            headCells:
            {
                style: 
                {
                    textTransform:"uppercase"
                },
            },
            
            cells:
            {
                style: 
                {    
                    //lineHeight: "140%"
                },
            },
        };


        const columns = [
            {
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_ADDRESS"),
                selector : row => row.address,
                sortable : false,
                minWidth : "200px",
                cell     : (row) => <IonLabel style={{textTransform: "capitalize"}}> {$a.Dom.decodeHTMLEntities(row.address)} </IonLabel>

            },
            {
                selector : row => parseFloat(row.price),
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_PRICE"),
                sortable : true,
                right    : true,
                cell     : (row) => <div>{parseFloat(row.price).toLocaleString()} €</div>
            },
            {
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_SQMT"),
                selector : row => parseFloat(row.sqmt),
                sortable : true,
                right    : true,
                cell     : (row) => <div>{parseFloat(row.sqmt)}</div>
            },
            {
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_TASK_TYPE"),
                selector : row => parseFloat(row.task_type),
                sortable : true,
                cell     : (row) => <div> {i18n.t("D_TASK_TYPE."+row.task_type)}</div>
            },
            {
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_ROOMS"),
                selector : row => parseFloat(row.rooms),
                center   : true,
                sortable : true,
            },
            {
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_SUB_TYPE"),
                selector : row => parseFloat(row.PrProperty_SubType_ID),
                sortable : true,
                cell     : (row) => <div> {i18n.t("D_PRPROPERTY_SUBTYPE_ID."+row.PrProperty_SubType_ID)}</div>

            },
            {
                name     : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_RATING"),
                selector : row => parseFloat(row.rating),
                sortable : true,
                center   : true,
            },
            {
                name   : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_EDIT"),
                button : true,
                width  : "170px",
                cell   : (row) => 
                    <>
                        <IonButton fill="clear" color="success" onClick={(e) => {this.getSchedule(e,row)}}>
                            <IonIcon
                                icon = {calendar}
                                size = {"small"}
                            />
                        </IonButton>
                        <IonButton fill="clear" color="success" onClick={(e) => {this.updateImage(e,row)}}>
                            <IonIcon
                                icon = {image}
                                size = {"small"}
                            />
                        </IonButton>
                        <IonButton fill="clear" color="success" onClick={(e) => {this.updateProperty(e,row)}}>
                            <IonIcon
                                icon = {edit}
                                size = {"small"}
                            />
                        </IonButton>
                    </>
            },
            {
                name   : i18n.t("L_PROPERTY_LIST_TABLE.L_HEADERS.L_PUBLISHED"),
                button : true,
                cell   : (row) => 
                    <IonToggle
                        //style={{height:"45%"}} 
                        color="success" 
                        checked = {row.published == "1" ? true : false}
                        onIonChange={(e) => this.togglePublish(row.id,e.detail.checked)}
                    />
            }
        ];

        let buttons = 
        [
            {
                id       : "mine",
                icon     : list,
                label    : "L_PROPERTY_MINE",
                callback : this.onShowMine
            },
            {
                id       : "add",
                icon     : add,
                label    : "L_PROPERTY_ADD",
                callback : this.onAddProperty
            }
        ]

        console.log("dataFiltered", this.state.dataFiltered)

        return (

            <IonPage>
                { this.context.showAs == "page" &&
                    <CbHeader title ={i18n.t('L_PROPERTY_LIST')} buttons={buttons}/>
                }
                <IonContent style={{}}>

                    <IonGrid>
                        <IonRow >
                            <IonCol size={this.context.showAs == "page" ? 10 : 12} >
                            <IonCard>
                                <IonCardContent >
                                    { this.state.dataFiltered != [] &&
                                    <DataTable
                                        columns          = {columns}
                                        data             = {this.state.dataFiltered}
                                        responsive       = { true }
                                        theme            = "solarized"
                                        striped          = { true }
                                        customStyles     = { customStyles }
                                        highlightOnHover
                                        fixedHeader
                                        pagination
                                        noHeader
                                    />
                                    }
                                </IonCardContent>
                            </IonCard>
                            </IonCol>
                            { this.context.showAs == "page" &&
                                <IonCol size={2} >

                                    <IonCard>
                                        <IonCardHeader style={{}}>
                                            <IonCardTitle>{i18n.t("L_PORTFOLIO_DATA")}:</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>

                                        <IonItem  >
                                            <IonLabel>{i18n.t('L_PROPERTY_LIST_TOTAL')}</IonLabel> 
                                            <IonBadge slot="end" style={{fontSize:"large"}}>{this.state.dataFiltered.length}</IonBadge>
                                        </IonItem>

                                        <IonItem >
                                            <IonLabel>{i18n.t('L_PROPERTY_LIST_PUBLISHED')}</IonLabel> 
                                            <IonBadge slot="end" style={{fontSize:"large"}}>{this.state.dataFiltered.filter(a=>a.published==1).length}</IonBadge>
                                        </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            }
                        </IonRow>
                    </IonGrid>

                    <IonModal
                        isOpen        = {this.state.showModalSchedule}
                        //cssClass='my-custom-class'
                        swipeToClose  = {true}
                        //component ={PagePropertySchedule}
                        //presentingElement={pageRef.current}
                        onDidDismiss  = {() => this.setState({showModalSchedule:false})}
                        cssClass = {"page_property_list_modal"}
                    >
                        <PagePropertySchedule 
                            PrProperty_ID = {this.state.PrProperty_ID}
                            onClose       = {(showToast) => this.setState({showModalSchedule:false, showToast:showToast})}
                            showButtons   = {true}
                            showHeader    = {true}
                        />
                    {/* <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton> */}
                    </IonModal>

                </IonContent>

                <IonToast
                    isOpen       = {this.state.showToast}
                    onDidDismiss = {() => this.setState({showToast:false})}
                    message      = {i18n.t("L_SUCCESS")}
                    duration     = {2000}
                    color        = "success"
                />

            </IonPage>
        )
    }
}

export default React.memo(PagePropertyList);
