
import PageHome                       from './pages/page.home'
import PagePropertyList               from './pages/property/page.property.list'
import PagePropertyAddStepForm        from './pages/property/page.form'
import PagePropertyAddStepImages      from './pages/property/page.images'
import PageBilling                    from './pages/page.billing'
import PageMFA                        from './pages/settings/page.mfa'

import Task from './pages/task/Index'

export const routes = [
    {
        path: '/Home',
        component: PageHome
    },
    {
        path: '/Appointment/List',
        component: Task
    },
    {
        path: '/Property/List',
        component: PagePropertyList
    },
    {
        path: '/Property/Form',
        component: PagePropertyAddStepForm
    },
    {
        path: '/Property/Images',
        component: PagePropertyAddStepImages
    },
    {
        path: '/Billing',
        component: PageBilling
    },
    {
        path: '/Settings/MFA',
        component: PageMFA
    }
]