/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
import '../../global.js'

import { IonPage, IonContent, IonCard, IonRow, IonCardTitle, IonButton, IonRippleEffect,
         IonCardSubtitle, IonIcon, IonRouterLink, IonItem, IonLabel, IonCol, IonTextarea } from '@ionic/react';

import {CbHeader} from '../cb.header'
import i18n from '../../i18n.js';

//import {create } from 'ionicons/icons';

import CmpErrorAlert         from '../cmp_error_alert'
import CmpLoading            from '../cmp.loading'

import CmpFrmItem            from '../form_components/cmp_frm_item'
import CmpFrmItemPrice       from '../form_components/cmp_frm_item_price'
import CmpFrmItemToggle      from '../form_components/cmp_frm_item_toggle'
import CmpFrmItemSelect      from '../form_components/cmp_frm_item_select'
import CmpFrmAddress         from '../form_components/cmp_frm_address'
import CmpPropertySchedule   from './page.property.schedule'


export default class PagePropertyAddStepSummary extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            formGeneral:
            {
                fields :
                [
                    {
                        dn        : 'property.residential.PrProperty_SubType_ID',
                        name      : 'PrProperty_SubType_ID',
                        value     : "",
                        label     : "L_PRPROPERTY_SUBTYPE_ID",
                        selections: ["1", "2", "3"],
                        multi     : false,
                        required  : true,
                        regex     : "number-integer",
                    },
                    {
                        dn        : 'property.residential.dm_task_type',
                        name      : 'dm_task_type',
                        value     : "",
                        label     : "L_TASK_TYPE",
                        selections: ["1", "2", "3"],
                        multi     : false,
                        required  : true,
                        regex     : "number-integer",
                    },
                ]
            },

            formFeatures :
            {
                fields :
                [
                    {
                        dn        : 'property.residential.price',
                        name      : 'price',
                        value     : "",
                        currency  : "EUR",
                        type      : "number",
                        label     : "L_PROPERTY_PRICE",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 100000000,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.sqmt',
                        name      : 'sqmt',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_SQMT",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 10000,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.storey',
                        name      : 'storey',
                        value     : "",
                        label     : "L_PROPERTY_STOREY",
                        selections: ["-2", "-1", "0", "0.5", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60"],
                        required  : true,
                        regex     : "number-integer",
                        multi     : false
                    },
                    {
                        dn        : 'property.residential.rooms',
                        name      : 'rooms',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_ROOMS",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 50,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.bedrooms',
                        name      : 'bedrooms',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_BEDROOMS",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 50,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.bathrooms',
                        name      : 'bathrooms',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_BATHROOMS",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 20,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.dm_kitchen_type',
                        name      : 'dm_kitchen_type',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_KITCHEN",
                        selections: ["1", "2", "3", "4"],
                        required  : true,
                        regex     : "number-integer",
                        multi     : false
                    },
                    {
                        dn        : 'property.residential.garage',
                        name      : 'garage',
                        value     : "",
                        label     : "L_PROPERTY_GARAGES",
                        selections: ["0", "1", "2"],
                        required  : true,
                        regex     : "number-integer",
                        multi     : false
                    },
                    {
                        dn        : 'property.residential.parking_spot.spots',
                        name      : 'spots',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_PARKING_SPOT",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 100,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.garden.dm_garden_type',
                        name      : 'dm_garden_type',
                        value     : "",
                        label     : "L_PRPROPERTY_GARDEN_TYPE",
                        selections: ["0", "1", "2"],
                        multi     : false,
                        required  : true,
                        regex     : "number-integer",
                    },
                    {
                        dn        : 'property.residential.building.building_storeys',
                        name      : 'building_storeys',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_BUILDING_STOREYS",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 200,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.building.building_year',
                        name      : 'building_year',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_BUILDING_YEAR",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 1000,
                        max_value : 2100,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.dm_state',
                        name      : 'dm_state',
                        value     : "",
                        label     : "L_PROPERTY_STATE",
                        selections: ["20", "40", "60", "80"],
                        multi     : false,
                        required  : true,
                        regex     : "number-integer",
                    },
                ]
            },

            formOtherFeatures :
            {
                fields :
                [
                    {
                        dn        : 'property.residential.balcony',
                        name      : 'balcony',
                        type      : "toggle",
                        checked   : false,
                        label     : "L_PROPERTY_BALCONY",
                    },
                    {
                        dn        : 'property.residential.terrace',
                        name      : 'terrace',
                        type      : "toggle",
                        checked   : false,
                        label     : "L_PROPERTY_TERRACE",
                    },
                    {
                        dn        : 'property.residential.lift',
                        name      : 'lift',
                        type      : "toggle",
                        checked   : false,
                        label     : "L_PROPERTY_LIFT",
                    },
                    {
                        dn        : 'property.residential.cellar',
                        name      : 'cellar',
                        type      : "toggle",
                        checked   : false,
                        label     : "L_PROPERTY_CELLAR",
                    },
                    {
                        dn        : 'property.residential.pool',
                        name      : 'pool',
                        type      : "toggle",
                        checked   : false,
                        label     : "L_PROPERTY_POOL",
                    },
                    {
                        dn        : 'property.residential.furnished',
                        name      : 'furnished',
                        value     : "",
                        label     : "L_PROPERTY_FURNISHED",
                        selections: ["0", "1", "2"],
                        required  : true,
                        regex     : "number-integer",
                        multi     : false,
                    },
                ]
            },

            formEnergy :
            {
                fields :
                [
                    {
                        dn        : 'property.residential.dm_energy_class',
                        name      : 'dm_energy_class',
                        value     : "",
                        label     : "L_PROPERTY_ENERGY_CLASS",
                        selections: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
                        multi     : false,
                        required  : true,
                        regex     : "number-integer",
                    },
                    {
                        dn        : 'property.residential.energy.kWhsqmt',
                        name      : 'kWhsqmt',
                        value     : "",
                        type      : "number",
                        label     : "L_PROPERTY_ENERGY_kwhsqmt",
                        disabled  : true,
                        required  : true,
                        regex     : "number-float",
                        min_value : 0,
                        max_value : 10000,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.costs.management_cost',
                        name      : 'cost',
                        value     : "",
                        currency  : "EUR",
                        type      : "number",
                        label     : "L_PROPERTY_ENERGY_PRICE",
                        required  : true,
                        regex     : "number-integer",
                        min_value : 0,
                        max_value : 10000,
                        keyboard  : "numeric"
                    },
                    {
                        dn        : 'property.residential.dm_heating',
                        name      : 'dm_heating',
                        value     : "",
                        label     : "L_PROPERTY_HEATING",
                        selections: ["0", "1", "2"],
                        multi     : false,
                        required  : true,
                        regex     : "number-integer",
                    },
                    {
                        dn        : 'property.residential.air_condition',
                        name      : 'air_condition',
                        type      : "toggle",
                        checked   : false,
                        label     : "L_PROPERTY_AIR_CONDITION",
                    },
                ]
            },

            address             : {},
            description         : "",
            minDescription      : 100,
            maxDescription      : 2000,
            autogrowDescription : false,

            PrProperty_ID       : "0",
            ClSchedule_ID       : "0",
            PrSchedule          : {},

            initEmpty           : false,
            isLoading           : false,

            errorState          : false,
            errorCode           : -1,
            errorMessage        : "",
        }

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.callApi                      = this.callApi.bind(this)
        this.goBack                       = this.goBack.bind(this)

        this.renderFormHeader             = this.renderFormHeader.bind(this)
        this.renderForm                   = this.renderForm.bind(this)

        this.getPropertyGeneral           = this.getPropertyGeneral.bind(this)
        this.getPropertyFeatures          = this.getPropertyFeatures.bind(this)
        this.getPropertyOtherFeatures     = this.getPropertyOtherFeatures.bind(this)
        this.getPropertyEnergy            = this.getPropertyEnergy.bind(this)
    }

    async componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        if(!$a.isNull(this.props.history.location.state))
        {
            let PrProperty_ID = String(this.props.history.location.state.PrProperty_ID)
            if(!$a.isNull(PrProperty_ID))
            {
                this.initForm(PrProperty_ID)
            }
        }
    }

    async componentDidUpdate()
    {
        if(this.state.initEmpty)
        {
            this.initFormEmpty()
        }
        else
        {
            if(!$a.isNull(this.props.history.location.state))
            {
                let PrProperty_ID = String(this.props.history.location.state.PrProperty_ID)

                if(!$a.isNull(PrProperty_ID) && PrProperty_ID !== this.state.PrProperty_ID)
                {
                    if(PrProperty_ID === "0")
                    {
                        this.initFormEmpty()
                    }
                    else
                    {
                        this.initForm(PrProperty_ID)
                    }
                }
            }
        }
    }

    async initForm(PrProperty_ID)
    {
        console.log("PrProperty_ID", PrProperty_ID)

        let r = await $a.Api.Call("RealEstate/Property/Read/",{token:$a.Store.Get("token"), id: PrProperty_ID})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                initEmpty    :true,
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        r = r.result

        let formGeneral = this.state.formGeneral
        formGeneral.fields[0].value         = String(r.PrProperty_SubType_ID)
        formGeneral.fields[1].value         = String(r.dm_task_type)

        let formFeatures = this.state.formFeatures
        formFeatures.fields[0].value        = String(r.price)
        formFeatures.fields[0].currency     = String(r.currency)
        formFeatures.fields[1].value        = String(r.sqmt)
        formFeatures.fields[2].value        = String(r.storey)
        formFeatures.fields[3].value        = String(r.rooms)
        formFeatures.fields[4].value        = String(r.bedrooms)
        formFeatures.fields[5].value        = String(r.bathrooms)
        formFeatures.fields[6].value        = String(r.dm_kitchen_type)
        formFeatures.fields[7].value        = String(r.garage.garages)
        formFeatures.fields[8].value        = String(r.parking_spot.spots)
        formFeatures.fields[9].value        = String(r.garden.dm_garden_type)
        formFeatures.fields[10].value       = String(r.building.building_storeys)
        formFeatures.fields[11].value       = String(r.building.building_year)
        formFeatures.fields[12].value       = String(r.dm_state)

        let formOtherFeatures = this.state.formOtherFeatures
        formOtherFeatures.fields[0].checked = String(r.has_balcony) === "1"
        formOtherFeatures.fields[1].checked = String(r.has_terrace) === "1"
        formOtherFeatures.fields[2].checked = String(r.has_lift)    === "1"
        formOtherFeatures.fields[3].checked = String(r.has_cellar)  === "1"
        formOtherFeatures.fields[4].checked = String(r.has_pool)    === "1"
        formOtherFeatures.fields[5].value   = String(r.dm_furnished)
        
        let formEnergy = this.state.formEnergy
        formEnergy.fields[0].value          = String(r.energy.dm_energy_class)
        formEnergy.fields[1].value          = String(r.energy.kWhsqmt)
        formEnergy.fields[2].value          = String(r.costs.management_cost)
        formEnergy.fields[2].currency       = String(r.costs.management_currency)
        formEnergy.fields[3].value          = String(r.energy.dm_heating_type)
        formEnergy.fields[4].checked        = String(r.energy.has_air_conditioning) === "1"

        this.setState(
            {
                formGeneral         : formGeneral,
                formFeatures        : formFeatures,
                formOtherFeatures   : formOtherFeatures,
                formEnergy          : formEnergy,
                address             : r.geo,
                description         : $a.Dom.decodeHTMLEntities(r.description),
                autogrowDescription : true,
                PrProperty_ID       : r.id
            }
        )
    }

    initFormEmpty()
    {
        let formGeneral = this.state.formGeneral
        formGeneral.fields[0].value         = ""
        formGeneral.fields[1].value         = ""

        let formFeatures = this.state.formFeatures
        formFeatures.fields[0].value        = ""
        formFeatures.fields[0].currency     = "EUR"
        formFeatures.fields[1].value        = ""
        formFeatures.fields[2].value        = ""
        formFeatures.fields[3].value        = ""
        formFeatures.fields[4].value        = ""
        formFeatures.fields[5].value        = ""
        formFeatures.fields[6].value        = ""
        formFeatures.fields[7].value        = ""
        formFeatures.fields[8].value        = ""
        formFeatures.fields[9].value        = ""
        formFeatures.fields[10].value       = ""
        formFeatures.fields[11].value       = ""
        formFeatures.fields[12].value       = ""

        let formOtherFeatures = this.state.formOtherFeatures
        formOtherFeatures.fields[0].checked = false
        formOtherFeatures.fields[1].checked = false
        formOtherFeatures.fields[2].checked = false
        formOtherFeatures.fields[3].checked = false
        formOtherFeatures.fields[4].checked = false
        formOtherFeatures.fields[5].value   = ""

        let formEnergy = this.state.formEnergy
        formEnergy.fields[0].value          = ""
        formEnergy.fields[1].value          = ""
        formEnergy.fields[2].value          = ""
        formEnergy.fields[2].currency       = "EUR"
        formEnergy.fields[3].value          = ""
        formEnergy.fields[4].checked        = false

        this.setState(
            {
                formGeneral         : formGeneral,
                formFeatures        : formFeatures,
                formOtherFeatures   : formOtherFeatures,
                formEnergy          : formEnergy,
                address             : {},
                description         : "",
                autogrowDescription : false,
                PrProperty_ID       : "0",
                initEmpty           : false
            }
        )
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(f.type === "toggle")
            {
                continue
            }

            let res = this.onValidateField(f)

            if(res === false)
            {
                return res
            }
        }

        return true
    }

    onValidateField(f)
    {
        if(!$a.Form.Validate.Required(f))
        {
            this.context.set("loading",false)
            
            let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
            this.context.set("error",true)
            this.context.set("error_message",msg)
            return false
        }

        if(!$a.Form.Validate.Type(f))
        {
            this.context.set("loading",false)

            let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
            this.context.set("error",true)
            this.context.set("error_message", msg)
            return false
        }

        return true
    }

    onValidateAddres()
    {
        let address =
        [
            {
                name      : "address",
                value     : this.state.address.address,
                label     : "L_ADDRESS",
                required  : true,
                regex     : "string-strict",
                min_value : 0,
                max_value : 100,
            },
            {
                name      : "street_number",
                value     : this.state.address.street_number,
                label     : "L_STREET_NUMBER",
                required  : true,
                regex     : "string-strict",
                min_value : 1,
                max_value : 30,
            }
        ]

        return this.onValidate(address)
    }

    onValidateDescription()
    {
        let description =
        [
            {
                name      : "description",
                value     : this.state.description,
                label     : "L_PROPERTY_DESCRIPTION",
                required  : true,
                regex     : "string-free",
                min_value : this.state.minDescription,
                max_value : this.state.maxDescription,
            }
        ]

        return this.onValidate(description)
    }

    onValidateBuilding()
    {
        let level   = Number(this.state.formFeatures.fields[2].value)
        let storeys = Number(this.state.formFeatures.fields[10].value)

        if(level > storeys)
        {
            this.context.set("loading",false)

            let msg  = i18n.t("E_A0003")
            this.context.set("error",true)
            this.context.set("error_message", msg)
            return false
        }

        return true
    }

    async callApi()
    {
        if(!this.onValidate           (this.state.formGeneral.fields)         ) return
        if(!this.onValidateBuilding   ()                                      ) return
        if(!this.onValidateAddres     ()                                      ) return
        if(!this.onValidate           (this.state.formFeatures.fields)        ) return
        if(!this.onValidateField      (this.state.formOtherFeatures.fields[5])) return
        if(!this.onValidate           (this.state.formEnergy.fields)          ) return
        if(!this.onValidateDescription()                                      ) return

        let data   = 
        {
            token                   : $a.Store.Get("token"),
            PrProperty_Type_ID      : "1",
            PrProperty_SubType_ID   : this.state.formGeneral.fields[0].value,
            dm_task_type            : this.state.formGeneral.fields[1].value,
            price                   : this.state.formFeatures.fields[0].value,
            currency                : this.state.formFeatures.fields[0].currency,
            sqmt                    : this.state.formFeatures.fields[1].value,
            storey                  : this.state.formFeatures.fields[2].value,
            rooms                   : this.state.formFeatures.fields[3].value,
            bedrooms                : this.state.formFeatures.fields[4].value,
            bathrooms               : this.state.formFeatures.fields[5].value,
            dm_kitchen_type         : this.state.formFeatures.fields[6].value,
            garage :
            {
                garages             : this.state.formFeatures.fields[7].value,
            },
            parking_spot : 
            {
                spots               : this.state.formFeatures.fields[8].value
            },
            garden :
            {
                dm_garden_type      : this.state.formFeatures.fields[9].value
            },
            building :
            {
                building_storeys        : this.state.formFeatures.fields[10].value,
                building_year           : this.state.formFeatures.fields[11].value,
            },
            dm_state                : this.state.formFeatures.fields[12].value,
            has_balcony             : this.state.formOtherFeatures.fields[0].checked ? "1" : "0",
            has_terrace             : this.state.formOtherFeatures.fields[1].checked ? "1" : "0",
            has_lift                : this.state.formOtherFeatures.fields[2].checked ? "1" : "0",
            has_cellar              : this.state.formOtherFeatures.fields[3].checked ? "1" : "0",
            has_pool                : this.state.formOtherFeatures.fields[4].checked ? "1" : "0",
            dm_furnished            : this.state.formOtherFeatures.fields[5].value,
            energy :
            {
                kWhsqmt             : this.state.formEnergy.fields[1].value,
                dm_energy_class     : this.state.formEnergy.fields[0].value,
                dm_heating_type     : this.state.formEnergy.fields[3].value,
                has_air_conditioning: this.state.formEnergy.fields[4].checked ? "1" : "0"
            },
            costs :
            {
                management_cost     : this.state.formEnergy.fields[2].value,
                management_currency : this.state.formEnergy.fields[2].currency
            },
            geo                     : this.state.address,
            description             : this.state.description
        }

        this.setState({isLoading:true})

        let rProperty = {}

        if(String(this.state.PrProperty_ID)==="0")
        {
            rProperty = await $a.Api.Call("RealEstate/Property/Create/",data)
        }
        else
        {
            //if(this.state.updated)
            //{
                data.id   = this.state.PrProperty_ID
                rProperty = await $a.Api.Call("RealEstate/Property/Update/",data)
            //}
            //else
            //{
                // no update
            //    this.props.history.replace({pathname: '/Property/List'})
            //    return
            //}
        }

        if ($a.Api.ErrorCheck(rProperty))
        {
            this.setState({isLoading:false})
            this.context.set("error_code"     , rProperty.error)
            this.context.set("error_parameter", rProperty.parameter)
            this.context.set("error"          , true)
            return
        }

        let rSchedule = {}
        let dataSchedule           = this.state.PrSchedule
        dataSchedule.token         = $a.Store.Get("token")

        if(String(this.state.PrProperty_ID)==="0")
        {
            dataSchedule.PrProperty_ID = rProperty.result.id
            rSchedule = await $a.Api.Call("Calendar/Schedule/Create/",dataSchedule)
        }
        else
        {
            dataSchedule.id = this.state.ClSchedule_ID
            rSchedule       = await $a.Api.Call("Calendar/Schedule/Update/",dataSchedule)
        }

        this.setState({isLoading:false})

        if ($a.Api.ErrorCheck(rSchedule))
        {
            this.context.set("error_code", rSchedule.error)
            this.context.set("error"     , true)
            return
        }

        if(!$a.isNull(this.scrollRef))
        {
            this.scrollRef.scrollToTop(10)
        }

        if(String(this.state.PrProperty_ID) === "0")
        {
            this.props.history.replace({pathname: '/Property/Images', state: {PrProperty_ID:rProperty.result.id}})
        }
        else
        {
            this.props.history.replace({pathname: '/Property/List'})
        }
    }

    onChange(formName, el)
    {
        console.log("onChange",el, formName)
        let value = el.target.value
        let id    = parseInt(el.target.id)
        let name  = this.state[formName].fields[id].name

        this.state[formName].fields[id].value = value

        if(name === "dm_energy_class" && (value === "1" || value === "2" || value === "3"))
        {
            this.state[formName].fields[1].value    = "0"
            this.state[formName].fields[1].disabled = true
        }
        else if(name === "dm_energy_class")
        {
            this.state[formName].fields[1].disabled = false
        }

        this.forceUpdate()
    }

    onChangeToggle(formName, el)
    {
        console.log("onChangeToggle",el, formName)
        let checked = el.target.checked
        let id      = parseInt(el.target.id)

        this.state[formName].fields[id].checked = checked

        this.forceUpdate()
    }

    onChangeCurrency(formName, el)
    {
        console.log("onChange",el)
        let value = el.target.value
        let id    = parseInt(el.target.id)

        this.state[formName].fields[id].currency = value

        this.forceUpdate()
    }

    onChangeAddress(address)
    {
        this.setState({address: address})
    }

    async goBack()
    {
        if(!$a.isNull(this.scrollRef))
        {
            await this.scrollRef.scrollToTop(10)
        }
        this.props.history.replace({pathname: '/Property/List'})
    }

    renderForm()
    {
        let form = []

        form.push(this.renderFormHeader("L_ADD_PROPERTY.L_GENERAL", "General"))
        form.push(this.getPropertyGeneral())

        form.push(this.renderFormHeader("L_ADD_PROPERTY.L_FEATURES", "Features"))
        form.push(this.getPropertyFeatures())

        form.push(this.renderFormHeader("L_ADD_PROPERTY.L_OTHER_FEATURES", "OtherFeatures"))
        form.push(this.getPropertyOtherFeatures())

        form.push(this.renderFormHeader("L_ADD_PROPERTY.L_ENERGY_AND_COSTS", "Energy"))
        form.push(this.getPropertyEnergy())

        form.push(this.renderFormHeader("L_ADD_PROPERTY.L_DESCRIPTION", "Description", true))
        form.push(this.getPropertyDescription())

        form.push(this.renderFormHeader("L_ADD_PROPERTY.L_SCHEDULE", "Schedule"))
        form.push(this.getPropertySchedule())

        return form
    }

    getPropertyGeneral()
    {
        return (
            <div key="GeneralDiv">
                <IonRow key = "GeneralRow-0">
                    <IonCol size={6}><CmpFrmItemSelect key="General-0" id="0" item={this.state.formGeneral.fields[0]} onChange={(el) => this.onChange("formGeneral", el)}/></IonCol>
                    <IonCol size={6}><CmpFrmItemSelect key="General-1" id="1" item={this.state.formGeneral.fields[1]} onChange={(el) => this.onChange("formGeneral", el)}/></IonCol>
                </IonRow>
                <IonRow key = "GeneralRow-1">
                    <IonCol size={12}><CmpFrmAddress    key="General-2" colSize={6} zoom={16} size={"1000x400"} onChange={(e) => this.onChangeAddress(e)} address={this.state.address}/></IonCol>
                </IonRow>
            </div>
        )
    }

    getPropertyFeatures()
    {
        let colSize = 6

        return (
            <div key="FeaturesDiv">
                <IonRow key = "FeaturesRow-0">
                    <IonCol size = {colSize}                      ><CmpFrmItemPrice  key="Features-0"  id="0"  item={this.state.formFeatures.fields[0]}  onChange={(el) => this.onChange("formFeatures", el)} onChangeCurrency={(el) => this.onChangeCurrency("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize} style={{marginTop:3}}><CmpFrmItem       key="Features-1"  id="1"  item={this.state.formFeatures.fields[1]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                </IonRow>
                <IonRow key = "FeaturesRow-1">
                    <IonCol size = {colSize}><CmpFrmItemSelect key="Features-2"  id="2"  item={this.state.formFeatures.fields[2]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize}><CmpFrmItem       key="Features-3"  id="3"  item={this.state.formFeatures.fields[3]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                </IonRow>
                <IonRow key = "FeaturesRow-2">
                    <IonCol size = {colSize}><CmpFrmItem       key="Features-4"  id="4"  item={this.state.formFeatures.fields[4]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize}><CmpFrmItem       key="Features-5"  id="5"  item={this.state.formFeatures.fields[5]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                </IonRow>
                <IonRow key = "FeaturesRow-3">
                    <IonCol size = {colSize}><CmpFrmItemSelect key="Features-6"  id="6"  item={this.state.formFeatures.fields[6]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize}><CmpFrmItemSelect key="Features-7"  id="7"  item={this.state.formFeatures.fields[7]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                </IonRow>
                <IonRow key = "FeaturesRow-4">
                    <IonCol size = {colSize}><CmpFrmItem       key="Features-8"  id="8"  item={this.state.formFeatures.fields[8]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize}><CmpFrmItemSelect key="Features-9"  id="9"  item={this.state.formFeatures.fields[9]}  onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                </IonRow>
                <IonRow key = "FeaturesRow-5">
                    <IonCol size = {colSize}><CmpFrmItem       key="Features-10" id="10" item={this.state.formFeatures.fields[10]} onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize}><CmpFrmItem       key="Features-11" id="11" item={this.state.formFeatures.fields[11]} onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                </IonRow>
                <IonRow key = "FeaturesRow-6">
                    <IonCol size = {colSize}><CmpFrmItemSelect key="Features-12" id="12" item={this.state.formFeatures.fields[12]} onChange={(el) => this.onChange("formFeatures", el)}/></IonCol>
                    <IonCol size = {colSize}/>
                </IonRow>
            </div>
        )
    }

    getPropertyOtherFeatures()
    {
        let colSize = 4
        return (
            <div key="OtherFeaturesDiv">
                <IonRow key = "OtherFeaturesRow-0">
                    <IonCol size={colSize}>                      <CmpFrmItemToggle   key="OtherFeatuers-0" id="0" item={this.state.formOtherFeatures.fields[0]} onChange={(el) => this.onChangeToggle("formOtherFeatures", el)} /></IonCol>
                    <IonCol size={colSize}>                      <CmpFrmItemToggle   key="OtherFeatuers-1" id="1" item={this.state.formOtherFeatures.fields[1]} onChange={(el) => this.onChangeToggle("formOtherFeatures", el)} /></IonCol>
                    <IonCol size={colSize}>                      <CmpFrmItemToggle   key="OtherFeatuers-2" id="2" item={this.state.formOtherFeatures.fields[2]} onChange={(el) => this.onChangeToggle("formOtherFeatures", el)} /></IonCol>
                    <IonCol size={colSize} style={{marginTop:8}}><CmpFrmItemToggle   key="OtherFeatuers-3" id="3" item={this.state.formOtherFeatures.fields[3]} onChange={(el) => this.onChangeToggle("formOtherFeatures", el)} /></IonCol>
                    <IonCol size={colSize} style={{marginTop:8}}><CmpFrmItemToggle   key="OtherFeatuers-4" id="4" item={this.state.formOtherFeatures.fields[4]} onChange={(el) => this.onChangeToggle("formOtherFeatures", el)} /></IonCol>
                    <IonCol size={colSize}>                      <CmpFrmItemSelect   key="OtherFeatures-5" id="5" item={this.state.formOtherFeatures.fields[5]} onChange={(el) => this.onChange("formOtherFeatures", el)}/></IonCol>
                </IonRow>
            </div>
        )

    }

    getPropertyEnergy()
    {
        let colSize = 6

        return (
            <div key="EnergyDiv">
                <IonRow key = "EnergyRow-0">
                    <IonCol size = {colSize}><CmpFrmItemSelect key="Energy-0"  id="0"  item={this.state.formEnergy.fields[0]}  onChange={(el) => this.onChange("formEnergy", el)}/></IonCol>
                    <IonCol size = {colSize}><CmpFrmItem       key="Energy-1"  id="1"  item={this.state.formEnergy.fields[1]}  onChange={(el) => this.onChange("formEnergy", el)}/></IonCol>
                </IonRow>
                <IonRow key = "EnergyRow-1">
                    <IonCol size = {colSize}                      ><CmpFrmItemPrice  key="Energy-2"  id="2"  item={this.state.formEnergy.fields[2]}  onChange={(el) => this.onChange("formEnergy", el)} onChangeCurrency={(el) => this.onChangeCurrency("formEnergy", el)}/></IonCol>
                    <IonCol size = {colSize} style={{marginTop:3}}><CmpFrmItemSelect key="Energy-3"  id="3"  item={this.state.formEnergy.fields[3]}  onChange={(el) => this.onChange("formEnergy", el)}/></IonCol>
                </IonRow>
                <IonRow key = "EnergyRow-2">
                    <IonCol size = {colSize}><CmpFrmItemToggle key="Energy-3"  id="4"  item={this.state.formEnergy.fields[4]}  onChange={(el) => this.onChangeToggle("formEnergy", el)}/></IonCol>
                </IonRow>
            </div>
        )

    }

    getPropertyDescription()
    {
        let label = ""
        let color = "primary"
        let len   = this.state.description.length

        if(len < this.state.minDescription)
        {
            label = i18n.t("L_PROPERTY_DESCRIPTION_MIN")
            label = label.replace(":MIN_CHAR:", this.state.minDescription)
            label = label.replace(":MIN_REMAIN:", this.state.minDescription - len)
        }
        else
        {
            label = i18n.t("L_PROPERTY_DESCRIPTION_MAX")
            label = label.replace(":MAX_CHAR:", this.state.maxDescription)
            label = label.replace(":MAX_REMAIN:", this.state.maxDescription - len)
        }

        return (
            <IonItem key = "Description-0">
                <IonLabel key="Description-Label" position="stacked" /*color={color}*/ >{label}</IonLabel>
                <IonTextarea 
                    rows        = {2}
                    autoGrow    = {this.state.autogrowDescription} // this is set dynamically through state for visual effect otherwise if autoGrow is true the placeholder is not visible
                    key         = "Description-Textarea"
                    value       = {this.state.description} 
                    onIonChange = {(e) => this.setState({description : e.target.value, autogrowDescription: true})}
                    placeholder = {i18n.t("L_PROPERTY_DESCRIPTION_placeholder")}
                    maxlength   = {this.state.maxDescription}
                    minlength   = {this.state.minDescription}
                />
            </IonItem>
        )
    }

    getPropertySchedule()
    {
        return(
            <CmpPropertySchedule
                key           = "Schedule-0"
                PrProperty_ID = {this.state.PrProperty_ID}
                onChange      = {(schedule, id) => this.setState({PrSchedule:schedule, ClSchedule_ID: id})}
                onInit        = {(schedule, id) => this.setState({PrSchedule:schedule, ClSchedule_ID: id})}
            />
        )
    }

    renderFormHeader(label, step, required)
    {
        return(
            <IonRow 
                id        = {"summary-"+step}
                key       = {"summary-"+step}
                className = "ion-justify-content-center"
                style     = {{marginBottom:10,marginTop:20}}
            >
                <IonCardSubtitle style = {{marginTop:10, fontSize:20}} color = "primary">
                    {i18n.t(label)}
                    { required &&
                        <ion-text color="danger">&nbsp;{i18n.t('L_REQUIRED_SIGN')}</ion-text>
                    }
                </IonCardSubtitle>

                {
                //<IonButton 
                //    color="primary" 
                //    fill="clear" 
                //    style={{position: "absolute", right:"0px",marginTop:"-1px"}} 
                //    onClick={() => {this.props.history.replace({pathname: '/Property/Add/Page/'+step, state: {data:this.props.history.location.state.data, pathname:"/Property/Add/Page/Six"}})}}
                //>
                //    <IonIcon icon={create} />
                //</IonButton>
                }
            </IonRow>
        )
    }

    render()
    {
        return (
            <IonPage>
                <CbHeader title ={i18n.t('L_PROPERTY_ADD')} />

                <IonContent ref = {ref => this.scrollRef=ref} fullscreen="true">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/>
                    <br/>

                    <IonCard style = {{maxWidth:1000,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" key= "form-0" >

                            <IonRow id="summary-0" key="summary-0" className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_ADD_PROPERTY.L_FORM')}
                                </IonCardTitle>
                            </IonRow>

                            {this.renderForm()}

                            <br/><br/>

                            <IonRow id="summary-button" key="summary-button" className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.callApi} >
                                    {this.state.PrProperty_ID === "0" ? i18n.t('L_PROPERTY_ADD') : i18n.t('L_UPDATE')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>

                                { this.state.PrProperty_ID !== "0" &&
                                    <IonButton color="primary" style={{width:"40%", marginLeft:20}} onClick={this.goBack}>
                                        { i18n.t('L_CANCEL') }
                                        <IonRippleEffect type="unbounded"></IonRippleEffect>
                                    </IonButton>
                                }
                            </IonRow>

                        </form>

                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                    <p style={{fontSize:"small"}} className="ion-text-center">

                        <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                        <br/><br/>

                        {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                    </p>
                    </IonCard>
                    <br/><br/>
                </IonContent>
            </IonPage>
        )
    }
}