/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonRow, IonImg, IonRouterLink, IonCard, IonPage, IonCheckbox, 
         IonItem, IonLabel, IonButton, IonRippleEffect, IonProgressBar, IonCardTitle,
         IonTextarea } from '@ionic/react'

import LogoLogin       from '../../theme/img/logo_horizontal.png'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'
import CmpFrmAddress   from '../form_components/cmp_frm_address'
import i18n            from '../../i18n.js'

export default class PageTradingAddress extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            address        : {},
            hqSameAsOffice : true,

            ready          : false,

            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.stepNumber = 3
        this.steps      = 4

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.checkboxChange               = this.checkboxChange.bind(this)
        this.nextStep                     = this.nextStep.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        if(!$a.isNull(this.props.history.location.state))
        {
            let hqSameAsOffice = this.state.hqSameAsOffice
            let address        = this.state.address

            if(!$a.isNull(this.props.history.location.state.data.geo))
            {
                address = this.props.history.location.state.data.geo
            }

            if(!$a.isNull(this.props.history.location.state.data.hqSameAsOffice))
            {
                hqSameAsOffice = this.props.history.location.state.data.hqSameAsOffice
            }

            this.setState({address : this.props.history.location.state.data.geo, hqSameAsOffice: hqSameAsOffice})
        }
        else
        {
            this.props.history.replace({pathname: '/register/step/one'})
        }
    }

    checkboxChange()
    {
        this.setState({hqSameAsOffice: !this.state.hqSameAsOffice})
    }

    onChangeAddress(address)
    {
        this.setState({address:address})
    }

    onValidateAddres()
    {
        let address =
        [
            {
                name      : "address",
                value     : this.state.address.address,
                label     : "L_ADDRESS",
                required  : true,
                regex     : "string-strict",
                min_value : 0,
                max_value : 100,
            },
            {
                name      : "street_number",
                value     : this.state.address.street_number,
                label     : "L_STREET_NUMBER",
                required  : true,
                regex     : "string-strict",
                min_value : 1,
                max_value : 30,
            }
        ]

        return this.onValidate(address)
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                return false
            }

            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)

                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                return false
            }
        }

        return true
    }

    async nextStep()
    {
        let data = this.props.history.location.state.data
        data.hqSameAsOffice = this.state.hqSameAsOffice

        if(this.state.hqSameAsOffice)
        {
            data.geo = data.geohq
        }
        else
        {
            if(!this.onValidateAddres())
            {
                return
            }

            data.geo = this.state.address
        }

        console.log("DATA", data)

        this.props.history.replace({pathname: '/register/step/five', state: {data:data}})
    }

    render()
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className="bgimage">
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{width:"20%"}}/>
                    </IonRow>

                    <br/>

                    <IonProgressBar value={this.stepNumber/this.steps} style={{maxWidth:500, height:10, borderRadius:5, margin:"auto"}}/>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary color = "primary">
                                    {i18n.t('L_REGISTRATION.L_COMPANY_ADDRESS')}
                                </IonCardTitle>
                            </IonRow>

                            <IonItem
                            >
                                <IonCheckbox 
                                    checked = {this.state.hqSameAsOffice}
                                    style   = {{marginRight:20}}
                                    onIonChange = {() => this.checkboxChange()}
                                />
                                <IonLabel>{i18n.t('L_HQ_ADDRESS_CHECKBOX')}</IonLabel>
                            </IonItem>
                            <br/>

                            {!this.state.hqSameAsOffice &&
                                <CmpFrmAddress
                                    onChange = {(e) => this.onChangeAddress(e)} 
                                    address  = {this.state.address}
                                />
                            }

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={() => this.nextStep()}>
                                    {i18n.t('L_CONTINUE')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>
                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                        <IonTextarea note style={{ padding:'2%', height: 80 }} >
                            {i18n.t('L_REGISTRATION.L_INFO') }
                            <a href={"mailto:" + i18n.t('SupportMail')}>{i18n.t('SupportMail')}</a>
                        </IonTextarea>

                        <p style={{fontSize:"small"}} className="ion-text-center">

                            <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>

                            <br/><br/>

                            {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                        </p>
                    </IonCard>

                    <br/>

                </IonContent>
            </IonPage>
        )
    }
}