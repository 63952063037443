/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
/* eslint no-restricted-globals:0 */

import '../../global.js'
import { IonContent, IonButton,IonRippleEffect, IonRow, IonImg, IonRouterLink, IonCard, IonLabel, IonPage, IonItem, IonSelect, IonSelectOption} from '@ionic/react'

import LogoLogin          from '../../theme/img/logo_horizontal.png'
import LogoApimo          from '../../theme/img/logo_apimo.png'
import LogoGerardoPaterna from '../../theme/img/logo_gerardopaterna.png'

import CmpFrmItem      from '../form_components/cmp_frm_item'
import CmpErrorAlert   from '../cmp_error_alert'
import CmpLoading      from '../cmp.loading'

export default class PageLogin extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)
        this.state =
        {
            form:
            {
                fields :
                [
                    {
                        dn        : 'gate.user.username',
                        name      : 'username',
                        value     : "",
                        type      : "email",
                        label     : "L_GATE_USER_USERNAME",
                        required  : true,
                        regex     : "email",
                        min       : 5,
                        max       : 50,
                        keyboard  : "email"
                    },
                    {
                        dn        : 'gate.user.password',
                        name      : "password",
                        value     : "",
                        type      : "password",
                        label     : "L_GATE_USER_PASSWORD",
                        error     : "",
                        required  : true,
                        regex     : "password",
                        min       : 5,
                        max       : 50,
                        keyboard  : "text"
                    }
                ]
            },

            ready          : false,
            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.onChange                     = this.onChange.bind(this)
        this.onValidate                   = this.onValidate.bind(this)
        this.onLogin                      = this.onLogin.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        if(!$a.isNull(this.props.history.location.state))
        {
            if(!$a.isNull(this.props.history.location.state.data))
            {
                if(!$a.isNull(this.props.history.location.state.data.username))
                {
                    this.state.form.fields[0].value = this.props.history.location.state.data.username
                    this.state.form.fields[1].value = this.props.history.location.state.data.password
                    this.onLogin()
                }
            }
        }
    }

    componentDidUpdate()
    {
        console.log("componentDidUpdate", this.constructor.name,this.props)

        if ((this.context.referrer=="frimm" || this.context.referrer=="replat") && this.state.form.fields[0].label !== "L_GATE_USER_USERNAME_FRIMM")
        {
            this.state.form.fields[0].label = "L_GATE_USER_USERNAME_FRIMM"
            this.forceUpdate()
        }
    }

    onChange(el)
    {
        console.log("onChange",el)
        let value = el.target.value
        let id    = parseInt(el.target.id)
        
        this.state.form.fields[id].value = value
        this.forceUpdate()
    }

    onValidate(formFields)
    {
        for (const f of formFields)
        {
            if(!$a.Form.Validate.Required(f))
            {
                this.context.set("loading",false)
                
                let msg = i18n.t("R_REQUIRED") + i18n.t(f.label)
                this.context.set("error",true)
                this.context.set("error_message",msg)
                this.state.ready = false
                return
            }
        }

        for (const f of formFields)
        {
            if(f.name === "username")
            {
                if(f.value.indexOf("@") < 0)
                {
                    // FRIMM users are not in format of email but emails are created by adding suffix "@replat.info"
                    f.regex = "email-prefix"
                }
                else
                {
                    f.regex = "email"
                }
            }

            if(!$a.Form.Validate.Type(f))
            {
                this.context.set("loading",false)
                
                let msg  = i18n.t("R_REGEX") + i18n.t(f.label) + " (" +i18n.t(f.label+"_regex") +")"
                this.context.set("error",true)
                this.context.set("error_message", msg)
                this.state.ready = false
                return
            }
        }

        this.state.ready = true
        console.log('Form is ready')
    }

    async onLogin(e)
    {
        console.log("onLogin", e)
        if(e !== undefined)
        {
            e.preventDefault();
            e.stopPropagation();
        }


        this.onValidate(this.state.form.fields);

        if(this.state.ready === true)
        {
            this.context.set("loading",true)

            let username = this.state.form.fields[0].value
            if(username.indexOf("@") < 0)
            {
                username += "@replat.info"
            }

            var data =
            {
                username               : username,
                password               : this.state.form.fields[1].value
            };

            this.setState({isLoading:true})

            let r = await $a.Api.Call("Gate/User/Login/",data)

            this.setState({isLoading:false})

            if ($a.Api.ErrorCheck(r))
            {
                this.context.set("error_code", r.error)
                this.context.set("error"     , true)
                return
            }

            let defaultMFAType=r.defaultMFAType;
            r=r.result;

            // In case end user tries to login
            if(Number(r.XaUserRole_ID) == 4)
            {
                this.context.set("error_code", 45)
                this.context.set("error"     , true)
                return
            }

            if(r===true && defaultMFAType=="App")
            {
                // USER NEEDS TO PUT IN OTP FROM APP
                this.props.history.replace({pathname: '/otpapp', state: {data:data}})
            }
            else if(r===true && defaultMFAType=="Delivered")
            {
                // USER NEEDS TO PUT IN OTP FROM APP
                this.props.history.replace({pathname: '/otpdelivered', state: {data:data}})
            }
            else if(r.hasOwnProperty('token'))
            {
                // USER LOGGED IN
                if(!$a.isNull(r.language))
                {
                    i18n.changeLanguage(r.language)
                }

                $a.Store.Set("token",r.token)
                this.context.set("loggedIn", true)
                this.context.set("XaUser_ID", r.XaUser_ID)

                if(r.hasPendingDocuments == 1)
                {
                    // USER HAS DOCUMENTS TO ACCEPT (T&C, PRIVACY ...)
                    this.context.set("pendingDocs", true)
                }
            }
            else if(r.hasOwnProperty('validateEmail'))
            {
                // USER NEEDS TO VERIFY EMAIL
                this.props.history.replace({pathname: '/verifyemail', state: {data:data}})
            }
            /*else if(r.hasOwnProperty('secKey'))
            {
                // FIRST LOGIN - GENERATE QR CODE
                this.props.history.replace({pathname: '/qrcode', state: {data:data, secKey: r.secKey}})
            }*/
        }
    }

    render()
    {
        return (
            <IonPage>
                <IonContent fullscreen="true" className={this.context.showAs =="page" ? "bgimage" :""}>
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/><br/>

                    <IonRow className="ion-justify-content-center">
                        <IonImg src={LogoLogin} style={{height:"50px"}}/>
                    </IonRow>

                    <br/><br/>
                    
                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                            <IonSelect
                                style       = { { maxWidth:200, marginRight:5, marginLeft:"auto", color:"#000000" } }
                                value       = { $a.String.formatLanguage(i18n.translator.language) }
                                onIonChange = { (abc) => { i18n.changeLanguage(abc.detail.value); this.forceUpdate()} }
                            >
                                <IonSelectOption key={"selection-en"} value={"en-GB"}>English (United Kingdom)</IonSelectOption>
                                <IonSelectOption key={"selection-it"} value={"it-IT"}>Italiano</IonSelectOption>
                            </IonSelect>

                        { (this.context.referrer=="frimm" || this.context.referrer=="replat") &&
                            <div style={{paddingTop:"40px",paddingLeft:"40px",paddingRight:"40px",paddingBottom:"20px"}}>
                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>Sei FRIMM, REplat o Agent RE? {<br/>}</IonLabel>
                                <br/>
                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>Fai il login con le tue credenziali MLS!</IonLabel>

                            </div>
                        
                        }

                        {
                            (this.context.referrer=="apimo") && 
                            <div style={{paddingTop:"40px",paddingLeft:"40px",paddingRight:"40px",paddingBottom:"20px"}}>

                                <IonRow className="ion-justify-content-center">
                                    <IonImg src={LogoApimo} style={{height:"50px"}}/>
                                </IonRow>
                                <br/>

                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>{i18n.t("L_LOGIN_PAGE.L_APIMO_LABEL_1")} {<br/>}</IonLabel>
                                <br/>
                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>{i18n.t("L_LOGIN_PAGE.L_APIMO_LABEL_2")}</IonLabel>

                            </div>
                        }

                        {
                            (this.context.referrer=="gerardopaterna") && 
                            <div style={{paddingTop:"40px",paddingLeft:"40px",paddingRight:"40px",paddingBottom:"20px"}}>

                                <IonRow className="ion-justify-content-center">
                                    <IonImg src={LogoGerardoPaterna} style={{height:"50px"}}/>
                                </IonRow>
                                <br/>

                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>{i18n.t("L_LOGIN_PAGE.L_GERARDOPATERNA_LABEL_1")}</IonLabel>
                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>{i18n.t("L_LOGIN_PAGE.L_GERARDOPATERNA_LABEL_2")}</IonLabel>
                                <br/><br/>
                                <IonLabel style={{fontSize:"120%",lineHeight:"120%"}}>{i18n.t("L_LOGIN_PAGE.L_GERARDOPATERNA_LABEL_3")}</IonLabel>
                            </div>
                        }

                        <form className="ion-padding" >

                            <CmpFrmItem id="0" item={this.state.form.fields[0]} onChange={this.onChange}/>
                            <CmpFrmItem id="1" item={this.state.form.fields[1]} onChange={this.onChange}/>

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.onLogin}>
                                    {i18n.t('L_LOGIN')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                            <br/>
                            <IonButton color="primary" fill="clear" onClick={() => {this.props.history.replace({pathname: '/register/step/one'})}}>{i18n.t("L_REGISTER")}</IonButton>

                            <br/>
                            <IonButton color="primary" fill="clear" onClick={() => {this.props.history.replace({pathname: '/forgotpassword'})}}>{i18n.t("L_FORGOT")}</IonButton>
                            
                            {
                                (this.context.referrer=="gerardopaterna") && 
                                <div style={{paddingTop:"20px",paddingLeft:"20px",paddingRight:"40px",paddingBottom:"20px"}}>
                                    <IonLabel color="primary" style={{fontSize:"100%",lineHeight:"100%"}}>{i18n.t("L_LOGIN_PAGE.L_GERARDOPATERNA_LABEL_4")}</IonLabel>
                                </div>
                            }

                        </form>
                    </IonCard>

                    <br/>

                    <IonCard style = {{maxWidth:500,margin:"auto"}} className="ion-justify-content-center">

                    <p style={{fontSize:"small"}} className="ion-text-center">

                        <IonRouterLink href={"https://" + i18n.t('L_WEB_SITE')} class="underline" target={"_blank"}>{i18n.t('L_WEB_SITE')}</IonRouterLink>
                            <br/><br/>

                            {i18n.t('L_VERSION')} {$c.version} | {i18n.t('L_COPYRIGHT') }

                    </p>
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    }
}