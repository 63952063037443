/* global $c:true, $a:true, i18n:true, React:true, AppContext:true, useContext:true*/

import '../../global.js'
import {MyContext} from './MyContext'

import { IonButton, IonIcon, IonRow,IonCard, IonCardContent, IonCardTitle, IonCol } from '@ionic/react';

import { refreshSharp as refresh } from 'ionicons/icons';
import i18n from '../../i18n.js';

const Filter =()=>
{
    const appContext = useContext(AppContext);
    const myContext = useContext(MyContext);

    async function onClickStatus(v)
    {
        await myContext.setTaskFilter("dm_task_status",v)
        myContext.onFilterTaskData()
    }

    async function onRefreshData()
    {
        myContext.onRefreshData()
    }

    return (
        <IonCard>
            <IonCardTitle className={"ion-padding"} style ={{display:"flex",alignItems:"center"}}>{i18n.t("TASK.FILTER.SHOW")}
                <IonButton fill={"solid"} style={{marginLeft: "auto"}} size={"small"} onClick ={()=>onRefreshData()}>
                    <IonIcon slot="icon-only" icon={refresh} />
                   
                </IonButton>
            </IonCardTitle>

            <IonCardContent>

                <IonRow >

                    <IonCol>
                        <IonButton 
                            onClick ={ () => onClickStatus(0) }
                            fill={myContext.filterTaskData.dm_task_status == 0 ? "solid":"outline"} 
                            size={"small"}
                            style={{width:"90%"}}
                        >
                            {i18n.t("TASK.FILTER.PENDING")}
                        </IonButton>
                        <IonButton
                            onClick ={ () => onClickStatus(2) }
                            fill={myContext.filterTaskData.dm_task_status == 2 ? "solid":"outline"} 
                            size={"small"}
                            style={{width:"90%"}}
                        >
                            {i18n.t("TASK.FILTER.REJECTED")}

                        </IonButton>
                       
                    </IonCol>
                    <IonCol>
                    <IonButton 
                            onClick ={ () => onClickStatus(1) }
                            fill={myContext.filterTaskData.dm_task_status == 1 ? "solid":"outline"}
                            size={"small"}
                            style={{width:"90%"}}
                        >
                            {i18n.t("TASK.FILTER.CONFIRMED")}
                        </IonButton>
                      
                        
                        <IonButton
                            onClick ={ () => onClickStatus(-1) }
                            fill={myContext.filterTaskData.dm_task_status ==-1? "solid":"outline"}
                            size={"small"}
                            style={{width:"90%"}}
                        >
                            {i18n.t("TASK.FILTER.ALL")}

                        </IonButton>
                    </IonCol>
                </IonRow>

            </IonCardContent>
        </IonCard>
    )
}

export default Filter