/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
import '../../global.js'

import { IonPage, IonContent, IonCard, IonRow, IonCol, IonCardTitle, 
         IonButton, IonImg, IonIcon, IonLabel, IonSpinner, IonModal, IonCardSubtitle,
         IonCardContent, IonRippleEffect, IonText, IonItem, IonCardHeader, IonItemDivider } from '@ionic/react';

import {CbHeader} from '../cb.header'
import i18n from '../../i18n.js';

import CmpErrorAlert         from '../cmp_error_alert'
import CmpLoading            from '../cmp.loading'

import {image, checkmarkCircle, closeCircle, close, trash as trash, eye, map } from 'ionicons/icons';


class PagePropertyAddStepAdditional extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        console.log("Component","ScreenHome")

        super()

        this.state = 
        {
            imagesUrls        : [],
            imagesUrlsOld     : [],
            floorPlanUrls     : [],
            floorPlanUrlsOld  : [],

            showModal         : false,
            modalUrl          : "",
            isLoading         : false,
            imageError        : "",
            imageErrorDetails : {},

            errorState        : false,
            errorCode         : -1,
            errorMessage      : ""
        }

        this.stepNumber = 6
        this.steps      = 7

        this.componentDidMount            = this.componentDidMount.bind(this)
        this.nextStep                     = this.nextStep.bind(this)
        this.images                       = this.images.bind(this)
        this.getPictures                  = this.getPictures.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        this.getPictures()
    }

    componentDidUpdate()
    {
        if(!$a.isNull(this.props.history.location.state))
        {
            if(!$a.isNull(this.props.history.location.state.PrProperty_ID))
            {
                if(this.props.history.location.state.PrProperty_ID !== this.state.PrProperty_ID)
                {
                    this.getPictures()
                }
            }
        }
    }

    async nextStep()
    {
        if(!$a.isNull(this.scrollRef))
        {
            await this.scrollRef.scrollToTop(10)
        }

        this.props.history.push({pathname:  '/Property/List'})
    }

    async getPictures()
    {
        if($a.isNull(this.props.history.location.state.PrProperty_ID) || this.props.history.location.state.PrProperty_ID === "0")
        {
            return
        }

        let data   = 
        {
            token : $a.Store.Get("token"),
            id    : this.props.history.location.state.PrProperty_ID
        }

        this.setState({isLoading:true, PrProperty_ID: this.props.history.location.state.PrProperty_ID})

        let r = await $a.Api.Call("RealEstate/Property/Read/",data)

        this.setState({isLoading:false})

        if ($a.Api.ErrorCheck(r))
        {
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        console.log("result", r)

        let imagesUrlsOld = []
        if(!$a.isNull( r.result.images))
        {
            for(let i = 0; i < r.result.images.length; i++)
            {
                let newItem = 
                {
                    url     : r.result.images[i],
                    loading : false,
                    status  : "old"
                }

                imagesUrlsOld.push(newItem)
            }
        }

        let floorPlanUrlsOld = []
        if(!$a.isNull( r.result.floor_plan))
        {
            for(let i = 0; i < r.result.floor_plan.length; i++)
            {
                let newItem = 
                {
                    url     : r.result.floor_plan[i],
                    loading : false,
                    status  : "old"
                }

                floorPlanUrlsOld.push(newItem)
            }
        }

        this.setState({imagesUrlsOld: imagesUrlsOld, imagesUrls: [], floorPlanUrlsOld: floorPlanUrlsOld, floorPlanUrls: []})
    }

    images(imageType)
    {
        let images      = []
        let urlsType    = imageType === "image" ? "imagesUrls" : "floorPlanUrls"
        let urlsOldType = imageType === "image" ? "imagesUrlsOld" : "floorPlanUrlsOld"

        for(let i = 0; i < this.state[urlsType].length; i++)
        {
            console.log(this.state[urlsType][i])
            
            let loading = this.state[urlsType][i].loading
            let status  = this.state[urlsType][i].status
            let name    = this.state[urlsType][i].name
            images.push(

                <IonCard 
                    key={"image-" + i} id={"image-" + i}
                    style   = {{width:200, height:210 }}
                >

                    <IonImg style={{width:200, height:150,position: "relative", opacity: loading ? 0.4 : 1}} src = {this.state[urlsType][i].url}/>
                    { loading &&
                        <IonSpinner style={{position: "absolute", left:90, top:90}} name="circles"/>
                    }

                    <IonText>{name}</IonText>

                    { !loading && status === "OK" && 
                        <>
                            <IonIcon style={{position: "absolute", right:0, top:0, fontSize:30}} color="success" size = {100} icon={checkmarkCircle}/>
                            <IonItem lines="none">
                                <IonIcon style={{ marginLeft:"auto", marginRight:20, fontSize:25}} color="primary" icon={eye} onClick={() => {this.setState({modalUrl:this.state[urlsType][i].url, showModal:true, imageError:"", imageErrorDetails: ""})}}/>
                                <IonIcon style={{ marginRight:"auto", fontSize:25}} color="primary" icon={trash} onClick={() => this.removeImage(this.state[urlsType][i].url, imageType)}/>
                            </IonItem>
                        </>
                    }

                    { !loading && status === "NOK" && 
                        <>
                            <IonIcon style={{position: "absolute", right:0, top:0, fontSize:30}} color="danger" size = {100} icon={closeCircle} />
                            <IonItem lines="none">
                                <IonIcon style={{ marginLeft:"auto", marginRight:"auto", fontSize:25}} color="primary" icon={eye} onClick={() => {this.setState({modalUrl:this.state[urlsType][i].url, showModal:true, imageError:this.state[urlsType][i].imageError, imageErrorDetails: this.state[urlsType][i].imageErrorDetails})}}/>
                            </IonItem>
                        </>
                    }

                </IonCard>
            )
        }

        for(let i = 0; i < this.state[urlsOldType].length; i++)
        {
            images.push(
                <IonCard 
                    key={"imageOld-" + i} id={"imageOld-" + i}
                    style = {{width:200, height:210, }}
                >
                    <IonImg style={{width:200, height:150,position: "relative"}} key={"image-" + i} id={"imageOld-" + i} src = {this.state[urlsOldType][i].url}/>
                    <IonText>{}</IonText>

                    <IonItem lines="none" style={{position:"absolute", bottom:0, width:"100%"}}>
                        <IonIcon style={{ marginLeft:"auto", marginRight:20, fontSize:25}} color="primary" icon={eye} onClick={() => {this.setState({modalUrl:this.state[urlsOldType][i].url, showModal:true})}}/>
                        <IonIcon style={{ marginRight:"auto", fontSize:25}} color="primary" icon={trash} onClick={() => this.removeImage(this.state[urlsOldType][i].url, imageType)}/>
                    </IonItem>
                </IonCard>
            )
        }

        return images
    }

    async removeImage(url, imageType)
    {
        let urlsType    = imageType === "image" ? "imagesUrls"    : "floorPlanUrls"
        let urlsOldType = imageType === "image" ? "imagesUrlsOld" : "floorPlanUrlsOld"
        let data        = 
        {
            token         : $a.Store.Get("token"),
            PrProperty_ID : this.props.history.location.state.PrProperty_ID.toString(),
            imageType     : imageType,
            url           : url
        }

        let r = await $a.Api.Call("BcMedia/Image/Remove/",data, $c.ApiMediaUrl)

        if ($a.Api.ErrorCheck(r))
        {
            console.log("ERROR")
            this.context.set("error_code", r.error)
            this.context.set("error",true)
            return
        }

        for (var i=0; i < this.state[urlsOldType].length; i++)
        {
            if (this.state[urlsOldType][i].url === url)
            {
                this.state[urlsOldType].splice(i,1)
                this.forceUpdate()
                return
            }
        }

        for (var i=0; i < this.state[urlsType].length; i++)
        {
            if (this.state[urlsType][i].url === url)
            {
                this.state[urlsType].splice(i,1)
                this.forceUpdate()
                return
            }
        }
    }

    loadImageFromDevice(event, imageType) {

        let self = this
        let urlsType = imageType === "image" ? "imagesUrls" : "floorPlanUrls"

        for(let file_idx = 0; file_idx < event.target.files.length; file_idx++)
        {
            let reader = new FileReader();
            let file = event.target.files[file_idx];

            reader.readAsArrayBuffer(file);

            reader.onload = () => {

                // get the blob of the image:
                let blob = new Blob([new Uint8Array((reader.result))]);

                let imageBase64 = self.base64ArrayBuffer(reader.result)

                // create blobURL, such that we could use it in an image element:
                let blobURL = URL.createObjectURL(blob);

                let imageId = blobURL.substring(blobURL.lastIndexOf('/') + 1)
                let newItem = {
                    name    : file.name,
                    url     : blobURL,
                    loading : true,
                    id      : imageId,
                    status  : "progress"
                }

                if(file.size > 1e7)
                {
                    // file is too big we support images up to 10MB
                    newItem.status     = "NOK"
                    newItem.loading    = false
                    newItem.imageError = 8100002

                    self.state[urlsType].push(newItem)
                    self.forceUpdate()
                }
                else
                {
                    self.state[urlsType].push(newItem)
                    self.forceUpdate()

                    let data   = 
                    {
                        token         : $a.Store.Get("token"),
                        PrProperty_ID : self.props.history.location.state.PrProperty_ID.toString(),
                        myID          : imageId,
                        imageType     : imageType,
                        image         : imageBase64
                    }

                    let c1 = $a.Api.Call("BcMedia/Image/Add/",data, $c.ApiMediaUrl)

                    c1.then(function(r) 
                    {
                        for(let i = 0; i < self.state[urlsType].length; i++)
                        {
                            if(self.state[urlsType][i].id === imageId)
                            {
                                self.state[urlsType][i].loading = false

                                if ($a.Api.ErrorCheck(r))
                                {
                                    self.state[urlsType][i].status            = "NOK"
                                    self.state[urlsType][i].imageError        = r.error
                                    self.state[urlsType][i].imageErrorDetails = r.details
                                }
                                else
                                {
                                    self.state[urlsType][i].status            = "OK"
                                    self.state[urlsType][i].url               = r.url
                                    self.state[urlsType][i].imageError        = ""
                                    self.state[urlsType][i].imageErrorDetails = ""
                                }

                                break;
                            }
                        }

                        self.forceUpdate()

                    }).catch(function(e)
                    {
                        //Ale.Ui.Loader.Hide();
                    });
                }
            };

            reader.onerror = (error) => {
            //handle errors
            };
        }
    };

    base64ArrayBuffer(arrayBuffer) {
        var base64    = ''
        var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
      
        var bytes         = new Uint8Array(arrayBuffer)
        var byteLength    = bytes.byteLength
        var byteRemainder = byteLength % 3
        var mainLength    = byteLength - byteRemainder
      
        var a, b, c, d
        var chunk
      
        // Main loop deals with bytes in chunks of 3
        for (var i = 0; i < mainLength; i = i + 3) {
          // Combine the three bytes into a single integer
          chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
      
          // Use bitmasks to extract 6-bit segments from the triplet
          a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
          b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
          c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
          d = chunk & 63               // 63       = 2^6 - 1
      
          // Convert the raw binary segments to the appropriate ASCII encoding
          base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }
      
        // Deal with the remaining bytes and padding
        if (byteRemainder == 1) {
          chunk = bytes[mainLength]
      
          a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
      
          // Set the 4 least significant bits to zero
          b = (chunk & 3)   << 4 // 3   = 2^2 - 1
      
          base64 += encodings[a] + encodings[b] + '=='
        } else if (byteRemainder == 2) {
          chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
      
          a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
          b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4
      
          // Set the 2 least significant bits to zero
          c = (chunk & 15)    <<  2 // 15    = 2^4 - 1
      
          base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }
        
        return base64
    }

    getErrors(err)
    {
        let errors = []

        /*        "adult":"UNLIKELY",
        "spoof":"VERY_UNLIKELY",
        "medical":"VERY_UNLIKELY",
        "violence":"UNLIKELY",
        "racy":"LIKELY"*/

        if(err.adult === "LIKELY" || err.adult === "VERY_LIKELY" )
        {
            errors.push(<IonRow><IonText color="danger">{i18n.t("L_IMAGES_ERRORS.ADULT")}</IonText></IonRow>)
        }

        if(err.spoof === "LIKELY" || err.spoof === "VERY_LIKELY" )
        {
            errors.push(<IonRow><IonText color="danger">{i18n.t("L_IMAGES_ERRORS.SPOOF")}</IonText></IonRow>)
        }

        if(err.medical === "LIKELY" || err.medical === "VERY_LIKELY" )
        {
            errors.push(<IonRow><IonText color="danger">{i18n.t("L_IMAGES_ERRORS.MEDICAL")}</IonText></IonRow>)
        }

        if(err.violence === "LIKELY" || err.violence === "VERY_LIKELY" )
        {
            errors.push(<IonRow><IonText color="danger">{i18n.t("L_IMAGES_ERRORS.VIOLENCE")}</IonText></IonRow>)
        }

        if(err.racy === "LIKELY" || err.racy === "VERY_LIKELY" )
        {
            errors.push(<IonRow><IonText color="danger">{i18n.t("L_IMAGES_ERRORS.RACY")}</IonText></IonRow>)
        }

        return errors
    }

    render()
    {
        return (

            <IonPage>
                <CbHeader title ={i18n.t('L_PROPERTY_ADD')} />

                <IonContent ref = {ref => this.scrollRef=ref}>
                    <CmpLoading    isLoading  = {this.state.isLoading} />
                    <CmpErrorAlert errorState = {this.state.errorState} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage}/>

                    <br/>
                    <br/>

                    <IonCard style = {{maxWidth:"80%",margin:"auto"}} className="ion-justify-content-center">

                        <form className="ion-padding" >

                            <IonCardHeader >

                                <IonRow className="ion-justify-content-center">
                                    <IonCardTitle primary >
                                        {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_HEADER')}
                                    </IonCardTitle>
                                </IonRow>

                                <IonCardSubtitle primary >
                                    {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_TYPES')}
                                </IonCardSubtitle>

                            </IonCardHeader>

                            <IonRow>
                                <IonCol size={6}>
                                    <IonButton expand = "full" style = {{maxWidth:420, height:200, marginLeft:"auto", marginRight:"auto"}}>

                                    {//<IonIcon lazy="true" slot="start" icon={image} size="large"/>
                                    }
                                    <IonLabel slot="end" className="ion-text-wrap">

                                        {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_BUTTON_IMAGES')}

                                        <br/><br/>
                                        <IonIcon lazy="true" slot="start" icon={image} size="large"/>

                                        <br/><br/>
                                        {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_UPLOAD_IMAGE')}

                                    </IonLabel>

                                    <input 
                                        type     = "file"
                                        multiple = {true}
                                        onChange = {(event) => this.loadImageFromDevice(event, "image")}
                                        id       = "file-input"
                                        accept   = "image/png, image/jpeg, image/heic, image/gif, image/jpg, image/bmp"
                                        style    = {{opacity: 0, position:"absolute", height:200, width:500}}
                                    />

                                    </IonButton>
                                </IonCol>

                                <IonCol>
                                    <IonButton expand = "full" style = {{maxWidth:420, height:200, marginLeft:"auto", marginRight:"auto"}}>

                                    {//<IonIcon lazy="true" slot="start" icon={map} size="large"/>
                                    }
                                    <IonLabel slot="end" className="ion-text-wrap">

                                        {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_BUTTON_FLOORPLAN')}

                                        <br/><br/>
                                        <IonIcon lazy="true" slot="start" icon={map} size="large"/>

                                        <br/><br/>
                                        {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_UPLOAD_FLOORPLAN')}

                                    </IonLabel>

                                    <input 
                                        type     = "file"
                                        multiple = {true}
                                        onChange = {(event) => this.loadImageFromDevice(event, "floorPlan")}
                                        id       = "file-input"
                                        accept   = "image/png, image/jpeg, image/heic, image/gif, image/jpg, image/bmp"
                                        style    = {{opacity: 0, position:"absolute", height:200, width:500}}
                                    />

                                    </IonButton>

                                </IonCol>

                            </IonRow>

                            <IonItemDivider />

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary >
                                    {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_LIST_FLOORPLAN')}
                                </IonCardTitle>
                            </IonRow>

                            <IonRow className="ion-justify-content-center">
                            {this.images("floorPlan")}
                            </IonRow>

                            <br/><br/>

                            <IonItemDivider />

                            <br/><br/>

                            <IonRow className="ion-justify-content-center">
                                <IonCardTitle primary >
                                    {i18n.t('L_ADD_PROPERTY.L_IMAGES.L_LIST_IMAGES')}
                                </IonCardTitle>
                            </IonRow>

                            <IonRow className="ion-justify-content-center">
                                {this.images("image")}
                            </IonRow>

                            <br/><br/>

                            <IonRow id="summary-button" key="summary-button" className="ion-justify-content-center">
                                <IonButton color="primary" style={{width:"40%"}} onClick={this.nextStep}>
                                    { i18n.t('L_OK')}
                                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                                </IonButton>
                            </IonRow>

                        </form>
                    </IonCard>

                    <IonModal
                        isOpen        = {this.state.showModal}
                        swipeToClose  = {true}
                        onDidDismiss  = {() => this.setState({showModal:false})}
                        cssClass      = {"page_property_list_modal"}
                        //style         = {{maxHeight:1000, height:1000}}
                        
                    >
                        <IonCard style={{margin:0, height:"100%"}}>
                            <div style = {{overflowY: "auto", whiteSpace: "nowrap", height:"100%"}}>

                            <IonCardTitle primary style={{marginTop:10}} >
                                <IonRow className="ion-justify-content-center">

                                    {i18n.t('L_ADD_PROPERTY.L_IMAGE_PREVIEW')}

                                    <IonIcon 
                                        style   = {{position: "absolute", right:0, top:0, marginRight:10, fontSize:30}}
                                        color   = "danger"
                                        size    = {100}
                                        icon    = {close}
                                        onClick = {() => this.setState({showModal:false})}
                                    />

                                </IonRow>
                            </IonCardTitle>

                            <IonCardContent >
                                <IonImg style={{ maxHeight:450, height:450}} src = {this.state.modalUrl}/>
                            </IonCardContent>

                            { this.state.imageError !== "" &&
                                <IonCardContent>
                                    <IonText color="danger">{i18n.t("E_"+this.state.imageError)}</IonText>

                                    {
                                        !$a.isNull(this.state.imageErrorDetails) &&
                                        this.getErrors(this.state.imageErrorDetails)
                                    }
                                </IonCardContent>
                            }

                            <IonCardContent>
                                <IonRow id="summary-button" key="summary-button" className="ion-justify-content-center">
                                    <IonButton color="primary" style={{width:"40%", bottom:10, top:"auto"}} onClick = {() => this.setState({showModal:false})}>
                                        {i18n.t('L_CLOSE')}
                                        <IonRippleEffect type="unbounded"></IonRippleEffect>
                                    </IonButton>
                                </IonRow>
                            </IonCardContent>

                            </div>
                        </IonCard>

                    </IonModal>

                </IonContent>

            </IonPage>
        )
    }
}

export default React.memo(PagePropertyAddStepAdditional);
