/* global $c:true, $a:true, i18n:true, React:true, AppContext:true, useState:true,useContext:true */

import '../../global.js'
import Moment from 'moment'
import MomentTZ from 'moment-timezone';
import { alertOutline } from 'ionicons/icons';

/*
PrProperty_ID: "3192"
XaUserOu_ID: "40311"
address: "Via Roccamena, 13, 00132 Roma RM, Italia"
created: "2020-11-08 23:52"
dm_task_status: "1"
dm_task_type: "1"
end_date: "2020-11-10"
end_time: "04:00"
id: 292
latitude: "41.8822"
localCalendarEventId: ""
longitude: "12.6641"
name: "Krystian2"
start_date: "2020-11-10"
start_time: "15:00"
timezone: "Europe/Rome"
title: "Property tour in person"
updated: "2020-11-08 23:56"
username: "krystian@pasich2.com"
tools:[]
*/

const getDateTime = (date,time,timezone="") =>
{
    if(timezone="")
    {
        let t = Moment(date + " " + time)
        return t
    }
    else
    {
        let tTz  = MomentTZ.tz(date + " " + time, timezone)
        tTz = Moment(tTz).local()
        return tTz
    }
}

export const handleGmail = (e) =>
{
    //see: https://kloudless.com/blog/monday-mentorship-how-to-create-a-link-to-add-an-event-in-the-google-calendar-api/
    //DATE FORMAT : 20201116T160000

    // EXAMPLE
    // https://calendar.google.com/calendar/r/eventedit?
    // text=Ciao+ciao+BBQ&
    // dates=20201116T160000/20201116T180000
    // &details=For+details,+link+here:+https://babacasa.com/companyBBQ-43251100920&
    // location=Kloudless+-+555+Main+Street+-+Berkeley,+CA+94705
    
    let tStart = ""
    let tEnd   = ""

    if($a.isEmpty(e.tools))
    {
        // For physical visit always show the time in timezone of the property
        tStart = getDateTime (e.start_date,e.start_time)
        tEnd   = getDateTime (e.end_date  ,e.end_time)
        //console.log(tStart,tEnd)
    }
    else
    {
        // For virtual visit always show the time in timezone where user currently is
        tStart = getDateTime (e.start_date,e.start_time, e.timezone)
        tEnd   = getDateTime (e.end_date  ,e.end_time, e.timezone)
    }

    tStart = Moment(tStart).format("YYYYMMDDTHHmmSS")
    tEnd   = Moment(tEnd).format("YYYYMMDDTHHmmSS")

    let endPoint = " https://calendar.google.com/calendar/r/eventedit?"

    let qSt = ""
    qSt += "dates="+ tStart +"/" + tEnd + "&"
    qSt += "location=" + e.address + "&"
    qSt += "text=" +e.name + " " + e.surname + " : " + e.title + "&"
    qSt += "add=" + e.username.replace("+", "%2B") + "&"
    qSt += "details=" + "From BabaCasa: https://www.babacasa.com" 
    

    let url = endPoint + qSt
    //console.log(url)
    openUrl(url)
}

export const handleMicrosoft = (e,type) =>
{
    //see: https://interactiondesignfoundation.github.io/add-event-to-calendar-docs/services/outlook-web.html

    let endPoint =
    {
        "hotmail"  :"https://outlook.live.com/calendar/0/deeplink/compose?",
        "office365":"https://outlook.office.com/calendar/0/deeplink/compose?"
    }

    let path = "path=/calendar/action/compose"
    
    let tStart = ""
    let tEnd   = ""

    if($a.isEmpty(e.tools))
    {
        // For physical visit always show the time in timezone of the property
        tStart = getDateTime (e.start_date,e.start_time)
        tEnd   = getDateTime (e.end_date  ,e.end_time)
    }
    else
    {
        // For virtual visit always show the time in timezone where user currently is
        tStart = getDateTime (e.start_date,e.start_time, e.timezone)
        tEnd   = getDateTime (e.end_date  ,e.end_time, e.timezone)
    }

    tStart = Moment(tStart).format("YYYY-MM-DDTHH:mm")
    tEnd   = Moment(tEnd).format("YYYY-MM-DDTHH:mm")

    let qSt = ""//endPoint[type] + path

    qSt += "&rru=addevent"
    qSt += "&startdt=" + tStart
    qSt += "&enddt="   + tEnd
    qSt += "&subject=" + e.name + " " + e.surname + " : " + e.title
    qSt += "&body="    + "From BabaCasa: https://www.babacasa.com" 
    qSt += "&location="+ e.address
    qSt += "&to="       + e.username.replace("+", "%2B")

    let url = endPoint[type] + path + qSt

    openUrl(url)


    // https://outlook.live.com/calendar/0/deeplink/compose?
    // path=/calendar/action/compose
    // &rru=addevent
    // &startdt=2020-11-10T15:30:00+00:00
    // &enddt=2020-11-10T16:30:00+00:00
    // &subject=Krystian2 : Property tour in person
    // &body=From BabaCasa: https://www.babacasa.com
    // &location=Via Roccamena, 13, 00132 Roma RM, Italia


    //https://outlook.live.com/calendar/0/deeplink/compose?
    //path=/calendar/action/compose
    
    // &rru=addevent
    // &startdt=2020-12-31T19:30:00Z
    // &enddt=2020-12-31T22:30:00Z
    // &subject=Birthday
    // &body=With%20clowns%20and%20stuff
    // &location=North%20Pole

    // https://outlook.live.com/calendar/0/deeplink/compose?
    // path=/calendar/action/compose
    // &rru=addevent
    // &startdt=2020-11-10T15:30
    // &enddt=2020-11-10T16:30
    // &subject=Krystian2%20:%20Property%20tour%20in%20person
    // &body=From%20BabaCasa:%20https://www.babacasa.com
    // &location=Via%20Roccamena,%2013,%2000132%20Roma%20RM,%20Italia
}

export const openUrl = (url) =>
{
    const win = window.open(url, '_blank');

    if (win != null)
    {
        win.focus();
    }
}