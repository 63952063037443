/* global React:true, $a:true, useContext:true */

import {MyProvider,MyContext} from './MyContext'
import Container from './Container'
import Widget    from './Widget'


class Index extends React.Component
{
    static defaultProps =
    {
        type : "container"
    }

    //MANAGE DEFAULT PROPS
    constructor()
    {
        super()

        this.state =
        {
            originalTaskData : [],
            filteredTaskData : [],
            userData         : {},
            filterTaskData   :
            {
                dm_task_status: -1, //-1:all, 0:pending, 1: accepted, 2: rejected
                start_date    : ""
            },
            
            setState: (obj) =>
            {
                console.log("CONTEXT:",this.state)
                console.log ("CONTEXT setState:",obj)
                this.setState(obj)
                console.log("CONTEXT:", this.state)
            },

            setTaskFilter:(k,v) =>
            {
                this.state.filterTaskData[k]=v
                //this.forceUpdate()
            },

            onRefreshData: () =>
            {
                this.onRefreshData ()
            },

            onFilterTaskData: () =>
            {
                this.onFilterTaskData ()
            }
        }

        this.onRefreshData    = this.onRefreshData.bind(this)
        this.getTaskData      = this.getTaskData.bind(this)
        this.getUserData      = this.getUserData.bind(this)
        this.onFilterTaskData = this.onFilterTaskData.bind(this)
    }

    async componentDidMount()
    {
        await this.getTaskData()
        this.getUserData()
        this.onFilterTaskData()
    }

    async onRefreshData()
    {
        await this.getTaskData()
        this.getUserData()
        this.onFilterTaskData()
    }

    async getTaskData()
    {
        let r = await $a.Api.Call("Calendar/Task/AgentList/",{token:$a.Store.Get("token")})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }
        this.setState({originalTaskData:r.result})
    }

    async getUserData()
    {
        let r = await $a.Api.Call("Gate/User/Get/",{token:$a.Store.Get("token")})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        this.setState({userData:r.result[0]})
    }

    async onFilterTaskData()
    {
        let f = this.state.filterTaskData   //filters
        //console.log(9999999999999,this.state)
        let o = this.state.originalTaskData //original array
        let n = o //local container for filtered data

        if(f.dm_task_status == -1) n = o
        if(f.dm_task_status != -1) n = n.filter( (e)=>{ return e.dm_task_status==f.dm_task_status } )
        if(f.start_date     != "") n = n.filter( (e)=>{ return e.start_date==f.start_date } )

        this.setState({filteredTaskData:n})
    }

    render()
    {

        if(this.props.type == "container")
        {
            return (
                <MyProvider value={this.state}>
                    <MyContext.Consumer>
                        {myContext => (<Container />)}
                    </MyContext.Consumer>
                </MyProvider>
            )
        }

        if(this.props.type == "widget")
        {
            return (
                <MyProvider value={this.state}>
                    <MyContext.Consumer>
                        {myContext => (<Widget />)}
                    </MyContext.Consumer>
                </MyProvider>
            )
        }
    }
}

export default React.memo(Index);

