/* global $c:true, $a:true, i18n:true, React:true, AppContext:true, useContext:true, useState:true*/
import '../../global.js'
import {MyContext} from './MyContext'
import { IonCardHeader,IonCard, IonCardContent, IonCardTitle } from '@ionic/react';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Moment from 'moment'
import i18n from '../../i18n.js';

const MyCalendar =()=>
{
    const appContext = useContext(AppContext);
    const myContext = useContext(MyContext);

    const [date,setDate] = useState(new Date())

    async function onChange (d)
    {    
        console.log(d)
        //2020-11-10

        let newDate =  Moment(d).local().format("YYYY-MM-DD")

        if(newDate == myContext.filterTaskData.start_date)
        {
            await myContext.setTaskFilter("start_date","")
            setDate(new Date())
        }
        else
        {
            await myContext.setTaskFilter("start_date",newDate)
        }

        myContext.onFilterTaskData()
    }

    return (
        <IonCard>

            <IonCardHeader>
                <IonCardTitle>{i18n.t("TASK.MYCALENDAR.L_ARCHIVE")}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <Calendar
                    onChange   = { onChange }
                    value      = { date }
                    //onClickDay = {onClickDay}
                />
            </IonCardContent>

        </IonCard>
    )
}

export default MyCalendar