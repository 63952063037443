/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */
import '../global.js'

import { IonPage, IonContent, IonCardHeader,IonCardTitle, IonCard,IonCardContent  } from '@ionic/react';
import { IonGrid, IonCol, IonRow, IonItem } from '@ionic/react';
import { IonBadge, IonLabel } from '@ionic/react';

import DataTable, { createTheme } from 'react-data-table-component';

import {CbHeader} from './cb.header'
import i18n from '../i18n.js';

import moment from 'moment';

class PageBilling extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        console.log("Component","ScreenHome")

        super()

        this.state = 
        {
            data           : [],
            selectedRows   : [],
            balance        : 0,

            isLoading      : false,

            errorState     : false,
            errorCode      : -1,
            errorMessage   : "",
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.handleRowSelected = this.handleRowSelected.bind(this)
        //this.onSort            = this.onSort.bind(this)

    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)

        this.getData()
    }

    async getData()
    {
        let r = await $a.Api.Call("Payment/Movements/ReadDetails/",{token:$a.Store.Get("token")})

        this.setState({isLoading:true})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState(
            {
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        r = r.result

        if(r.length > 0)
        {
            this.setState({data:r, balance:r[0].balance / 100})
        }
    }

    async handleRowSelected(e)
    {
        await this.setState({selectedRows:e.selectedRows})
    };

    render()
    {
        createTheme('solarized',
        {
            text:
            {
                primary: "var(--ion-text-color)"
            },
            background:
            {
                default: "var(--bbc-table-background-color)"
            },
            divider:
            {
                default: "var(--ion-color-medium)",
            }
        });

        const customStyles = 
        {
            rows:
            {
                style:
                {
                    //minHeight: '72px', // override the row height
                }
            },
            headCells:
            {
                style: 
                {
                    textTransform:"uppercase"
                }
            },
            cells:
            {
                style: 
                {
                    //lineHeight: "140%"
                }
            }
        };


        const columns = 
        [
            {
                name     : i18n.t("L_BILLING_LIST_TABLE.L_HEADERS.L_TIMESTAMP"),
                selector : 'timestamp',
                sortable : false,
                cell: (row) => <div> { moment(row.updated).local().format("DD-MM-YYYY hh:mm")}</div>
            },
            {
                name     : i18n.t("L_BILLING_LIST_TABLE.L_HEADERS.L_DESCRIPTION"),
                selector : 'debit_type',
                sortable : false,
                cell: (row) => <div> {i18n.t("D_DEBIT_TYPE."+row.dm_debit_type)}</div>
            },

            {
                name     : i18n.t("L_BILLING_LIST_TABLE.L_HEADERS.L_AMMOUNT"),
                selector : 'amount',
                sortable : false,
                cell     : (row) => <div>{row.dm_movement_type===2 ? "-" : "+"} {parseFloat(row.amount / 100).toLocaleString()} €</div>
                //cell     : (row) => <IonLabel style={{textTransform: "capitalize"}}> {$a.Dom.decodeHTMLEntities(row.address)} </IonLabel>
            },
            {
                name     : i18n.t("L_BILLING_LIST_TABLE.L_HEADERS.L_BALANCE"),
                selector : 'balance',
                sortable : false,
                cell     : (row) => <div>{parseFloat(row.balance / 100).toLocaleString()} €</div>
                //cell     : (row) => <IonLabel style={{textTransform: "capitalize"}}> {$a.Dom.decodeHTMLEntities(row.address)} </IonLabel>
            },
            {
                name     : i18n.t("L_BILLING_LIST_TABLE.L_HEADERS.L_USER_EMAIL"),
                selector: 'address',
                sortable: false,
                //grow    : 3,
                cell: (row) => <IonLabel > {$a.Dom.decodeHTMLEntities(row.client_details.email)} </IonLabel>
            },
            {
                name     : i18n.t("L_BILLING_LIST_TABLE.L_HEADERS.L_PROPERTY"),
                selector : 'address',
                sortable : false,
                minWidth : "200px",
                cell     : (row) => <IonLabel style={{textTransform: "capitalize"}}> {row.dm_movement_type==2 ? $a.Dom.decodeHTMLEntities(row.property_details.address) + " - " + parseFloat(row.property_details.price).toLocaleString() + " €" : ""} </IonLabel>
            },
        ];

        return (

            <IonPage>
                {this.context.showAs == "page" &&
                    <CbHeader title ={i18n.t('L_BILLING')} />
                }
                <IonContent style={{}}>
                
                    <IonGrid>
                        <IonRow >
                            <IonCol size={10} >
                            <IonCard>
                                <IonCardContent>
                                    <DataTable
                                        //style={{border:"1px solid green",height:"50%"}}
                                        //title="Lista Immobili"
                                        columns = {columns}
                                        data    = {this.state.data}
                                        //selectableRows // add for checkbox selection
                                        highlightOnHover
                                        overflowY
                                        //overflowYOffset = "100px"
                                        //selectableRowsHighlight
                                        //onSelectedRowsChange={this.handleRowSelected}
                                        noHeader
                                        fixedHeader
                                        //fixedHeaderScrollHeight="320px"
                                        theme    = "solarized"
                                        //striped
                                        onSort={this.onSort}
                                        sortServer
                                        //clearSelectedRows
                                        //Clicked
                                        //Selected={this.handleChange}
                                        //pagination
                                        customStyles={customStyles}
                                        responsive
                                    />
                                </IonCardContent>
                            </IonCard>
                            </IonCol>
                            
                            <IonCol size={2} >

                                <IonCard>
                                    <IonCardHeader style={{}}>
                                        <IonCardTitle>{i18n.t("L_ACCOUNT_BALANCE")}:</IonCardTitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <IonBadge color="light" style={{fontSize:"175%",width:"100%", color:"rgb(0,0,0)"}} className={"ion-padding"}>€ {(this.state.balance).toFixed(2)}</IonBadge>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </IonContent>

            </IonPage>
        )
    }
}

export default React.memo(PageBilling);
