/* global $c:true, $a:true, i18n:true, React:true, AppContext:true */

import '../global.js'
import Moment from 'moment'

import { IonPage, IonContent, IonImg, IonItem, IonItemDivider, IonCardSubtitle } from '@ionic/react'
import { IonGrid, IonRow, IonCol, IonText, IonIcon, IonLabel     } from '@ionic/react'
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonList } from '@ionic/react'

import logo      from '../theme/img/logo_horizontal.png'

import { logOut as iconLogout, settings as iconSettings, lockOpen as iconLockOpen } from 'ionicons/icons'

import { Doughnut,Bar } from 'react-chartjs-2'
import { ChartCard    } from './chart.card'
import { WidgetTopup  } from './widget.topup'
import WidgetTask       from './task/Index'
import i18n from '../i18n.js'

class PageHome extends React.Component
{
    static contextType = AppContext

    getColor(type,size)
    {
        let c = []
        this.chartStyle.colors.forEach(color => c.push(color[type]))
        return c.slice(0, size)
    }


    constructor()
    {
        console.log("Component","ScreenHome")

        super()

        this.chartStyle =
        {
            colors:
            [
                {
                    backgroundColor     : 'rgba(75,192,192,0.50)',
                    borderColor         : 'rgba(75,192,192,0.50)',
                    borderWidth         : 1,
                    hoverBackgroundColor: 'rgba(75,192,192,1)',
                    hoverBorderColor    : 'rgba(75,192,192,1)',
                },
                {
                    backgroundColor     : 'rgba(255,99,132,0.50)',
                    borderColor         : 'rgba(255,99,132,0.50)',
                    borderWidth         : 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.95)',
                    hoverBorderColor    : 'rgba(255,99,132,1)',
                },
                {
                    backgroundColor     : 'rgba(255,206,86,0.50)',
                    borderColor         : 'rgba(255,206,86,0.50)',
                    borderWidth         : 1,
                    hoverBackgroundColor: 'rgba(255,206,86,0.95)',
                    hoverBorderColor    : 'rgba(255,206,86,1)',
                },
                {
                    backgroundColor     : 'rgba(54,162,235,0.50)',
                    borderColor         : 'rgba(54,162,235,0.50)',
                    borderWidth         : 1,
                    hoverBackgroundColor: 'rgba(54,162,235,0.95)',
                    hoverBorderColor    : 'rgba(54,162,235,1)',
                }
            ]
        }

        this.state = 
        {
            date         : new Date(),
            accountBalance : 0,
            appointments : [],
            properties   : [],
            DoughnutDataAppointmentsTotal :
            {
                labels: [
                    i18n.t('L_GRAPHS.L_ACCEPTED'),
                    i18n.t('L_GRAPHS.L_REJECTED'),
                    i18n.t('L_GRAPHS.L_PENDING')
                ],
                datasets: 
                [{
                    data                : [0, 0, 0],
                    backgroundColor     : [...this.getColor("backgroundColor"     , 3)],
                    hoverBackgroundColor: [...this.getColor("hoverBackgroundColor", 3)],
                    hoverBorderColor    : [...this.getColor("hoverBorderColor", 3)],
                }]
            },

            DoughnutDataAppointmentsType :
            {
                labels: [
                    i18n.t('L_GRAPHS.L_IN_PERSON'),
                    i18n.t('L_GRAPHS.L_VIRTUAL')
                ],
                datasets:
                [{
                    data                : [0, 0],
                    backgroundColor     : [...this.getColor("backgroundColor"     , 2)],
                    hoverBackgroundColor: [...this.getColor("hoverBackgroundColor", 2)],
                    hoverBorderColor    : [...this.getColor("hoverBorderColor", 2)],
                }]
            },

            DoughnutDataLastMonth :
            {
                labels: [
                    i18n.t('L_GRAPHS.L_ACCEPTED'),
                    i18n.t('L_GRAPHS.L_REJECTED'),
                    i18n.t('L_GRAPHS.L_PENDING')
                ],
                datasets:  
                [{
                    data                : [0, 0, 0],
                    backgroundColor     : [...this.getColor("backgroundColor"     , 3)],
                    hoverBackgroundColor: [...this.getColor("hoverBackgroundColor", 3)]
                }]
            },

            PieDataPropertiesPublished :
            {
                labels: [
                    i18n.t('L_GRAPHS.L_PUBLISHED'),
                    i18n.t('L_GRAPHS.L_UNPUBLISHED'),
                ],
                datasets:
                [{
                    data                : [0, 0, 0],
                    backgroundColor     : [...this.getColor("backgroundColor"     , 3)],
                    hoverBackgroundColor: [...this.getColor("hoverBackgroundColor", 3)],
                    hoverBorderColor    : [...this.getColor("hoverBorderColor", 3)],
                }]
            },

            PieDataPropertiesRatings :
            {
                labels: [
                    '< 2.0',
                    '2.0 - 3.0',
                    '3.0 - 4.0',
                    '4.0 - 5.0',
                ],
                datasets: [{
                    data: [0, 0, 0, 0],
                    backgroundColor     : [...this.getColor("backgroundColor"     , 4)],
                    hoverBackgroundColor: [...this.getColor("hoverBackgroundColor", 4)],
                    hoverBorderColor    : [...this.getColor("hoverBorderColor", 4)],
                }]
            },

            BarDataAppointments :
            {
                labels: ['Gen','Feb','Mar','Apr','Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov','Dic'],
                datasets: [
                    {
                        label: i18n.t('L_GRAPHS.L_ACCEPTED'),
                        ...this.chartStyle.colors[0],
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                      },
                      {
                        label: i18n.t('L_GRAPHS.L_REJECTED'),
                        ...this.chartStyle.colors[1],
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                      },
                      {
                        label: i18n.t('L_GRAPHS.L_PENDING'),
                        ...this.chartStyle.colors[2],
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                      }
                ]
            },

            BarDataPropertiesPrice :
            {
                labels: ['< 100k','100k-250k','250k-500k','500k-750k','750k-1M','> 1M'],
                datasets: [
                    {
                        label: i18n.t('L_GRAPHS.L_PROPERTIES'),
                        ...this.chartStyle.c1,
                        data: [0, 0, 0, 0, 0, 0]
                      }
                ]
            }
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
        
        this.getDataAppointments()
        this.getDataProperties()
        this.getAccountBalance()
    }

    async getAccountBalance()
    {
        let r = await $a.Api.Call("Payment/Movements/ReadBalance/",{token:$a.Store.Get("token")})

        this.setState({isLoading:true})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        r = r.result;
        console.log("ACCOUNT BALANCE", r)
        this.setState(
            {
                accountBalance:r.balance
            }
        )
    }

    async getDataProperties()
    {
        let r = await $a.Api.Call("RealEstate/Property/List/",{token:$a.Store.Get("token")})

        this.setState({isLoading:true})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        let published = 0, unpublished = 0
        let prices = [0,0,0,0,0,0]
        let ratings = [0,0,0,0]

        for(let i=0; i < r.result.length; i++)
        {
            // calculate published/unpublised properties
            if (Number(r.result[i].published) === 1) published++
            else unpublished++

            // calculate properties per price
            let price = parseInt(r.result[i].price)
            if( price < 100000)         prices[0]++
            else if ( price < 250000)   prices[1]++
            else if ( price < 500000)   prices[2]++
            else if ( price < 750000)   prices[3]++
            else if ( price < 1000000)  prices[4]++
            else                        prices[5]++

            // calculate properties per rating
            let rating = Number(r.result[i].rating)
            if ( rating < 2.0 )      ratings[0]++
            else if ( rating < 3.0 ) ratings[1]++
            else if ( rating < 4.0 ) ratings[2]++
            else                     ratings[3]++

        }

        // update bar chart for properties price
        let BarDataPropertiesPrice = this.state.BarDataPropertiesPrice
        BarDataPropertiesPrice.datasets[0].data = prices

        // update pie chart for properties published
        let PieDataPropertiesPublished = this.state.PieDataPropertiesPublished
        PieDataPropertiesPublished.datasets[0].data = [published, unpublished]

        // update pie chart for properties ratings
        let PieDataPropertiesRatings = this.state.PieDataPropertiesRatings
        PieDataPropertiesRatings.datasets[0].data = ratings

        r = r.result;
        this.setState(
            {
                properties:r, 
                BarDataPropertiesPrice     : BarDataPropertiesPrice,
                PieDataPropertiesPublished : PieDataPropertiesPublished,
                PieDataPropertiesRatings   : PieDataPropertiesRatings
            }
        )
    }

    async getDataAppointments()
    {
        let r = await $a.Api.Call("Calendar/Task/AgentList/",{token:$a.Store.Get("token")})

        if ($a.Api.ErrorCheck(r))
        {
            this.setState({
                errorState   :true,
                errorCode    :r.error
            })
            return
        }

        let accepted = 0, rejected = 0, pending = 0
        let acceptedLastMonth = 0, rejectedLastMonth = 0, pendingLastMonth = 0
        let inperson = 0, virtual = 0
        let acceptedMonth = [0,0,0,0,0,0,0,0,0,0,0,0], rejectedMonth = [0,0,0,0,0,0,0,0,0,0,0,0], pendingMonth = [0,0,0,0,0,0,0,0,0,0,0,0]
        let nowYearMonth = Moment.utc().format('YYYY MM')
        nowYearMonth = Moment(nowYearMonth)

        let nowDate = Moment.utc().format('YYYY MM DD')
        nowDate     = Moment(nowDate)

        for(let i=0; i < r.result.length; i++)
        {
            let YYYYMM        = r.result[i].start_date.split('-')[0] + " " + r.result[i].start_date.split('-')[1]
            let timeYearMonth = Moment(YYYYMM)
            let months        = nowYearMonth.diff(timeYearMonth, 'months')

            let YYYYMMDD = r.result[i].start_date.split('-')[0] + " " + r.result[i].start_date.split('-')[1] + " " + r.result[i].start_date.split('-')[2]
            let timeDate = Moment(YYYYMMDD)
            let days     = nowDate.diff(timeDate, 'days')

            // calculate accepted appointments - total, in last 12 months, in last 30 days
            if(Number(r.result[i].dm_task_status)===1)
            {
                accepted++

                if(months<12)
                {
                    acceptedMonth[acceptedMonth.length-months-1]++
                }

                if(days < 30)
                {
                    acceptedLastMonth++
                }
            }
            // calculate rejected appointments - total, in last 12 months, in last 30 days
            else if(Number(r.result[i].dm_task_status)===2)
            {
                rejected++
                if(months<12)
                {
                    rejectedMonth[acceptedMonth.length-months-1]++
                }

                if(days < 30)
                {
                    rejectedLastMonth++
                }
            }
            // calculate pending appointments - total, in last 12 months, in last 30 days
            else
            {
                pending++
                if(months<12)
                {
                    pendingMonth[acceptedMonth.length-months-1]++
                }

                if(days < 30)
                {
                    pendingLastMonth++
                }
            }

            // calculate appointments - in person / virtual
            if($a.isEmpty(r.result[i].tools))
            {
                inperson++
            }
            else
            {
                virtual++
            }
        }

        // get last 12 months labels
        let labels = []
        for(let i=0; i < 12; i++)
        {
            let prev = Moment(nowYearMonth).subtract(i, 'months')
            labels.unshift(prev.format('YYYY-MM'))
        }

        // update total doughnut chart
        let DoughnutDataAppointmentsTotal = this.state.DoughnutDataAppointmentsTotal
        DoughnutDataAppointmentsTotal.datasets[0].data = [accepted,rejected,pending]

        // update type doughnut chart
        let DoughnutDataAppointmentsType = this.state.DoughnutDataAppointmentsType
        DoughnutDataAppointmentsType.datasets[0].data = [inperson,virtual]

        // update last month doughnut chart
        let DoughnutDataLastMonth = this.state.DoughnutDataLastMonth
        DoughnutDataLastMonth.datasets[0].data = [acceptedLastMonth,rejectedLastMonth,pendingLastMonth]

        // update bar chart
        let BarDataAppointments = this.state.BarDataAppointments
        BarDataAppointments.labels = labels
        BarDataAppointments.datasets[0].data = acceptedMonth
        BarDataAppointments.datasets[1].data = rejectedMonth
        BarDataAppointments.datasets[2].data = pendingMonth

        this.setState(
            {
                appointments                  : r.result,
                DoughnutDataAppointmentsTotal : DoughnutDataAppointmentsTotal,
                DoughnutDataAppointmentsType  : DoughnutDataAppointmentsType,
                DoughnutDataLastMonth         : DoughnutDataLastMonth,
                BarDataAppointments           : BarDataAppointments
            }
        )
        this.forceUpdate()
    }

    async onLogout()
    {
        console.log("logout")

        let r = await $a.Api.Call("Gate/User/Logout/",{token:$a.Store.Get("token")})

        if ($a.Api.ErrorCheck(r))
        {
            this.context.set("error_code", r.error)
            this.context.set("error"     , true)
            return
        }

        $a.Store.ClearAll()
        this.context.set("loggedIn", false)
    }

    render()
    {

        return (
            <IonPage>
                {this.context.showAs == "page" &&
                <IonHeader translucent="true">
                    <IonToolbar className={"ion-align-self-start"}>
                        {/* <IonTitle color={"primary"}>BabaCasa</IonTitle> */}
                        <IonImg src={logo} className={"ion-align-self-start"} style={{height:"46px",width:"13%",paddingLeft:"8px"}} />
                        <IonIcon color="primary" src={iconSettings} slot={"end"} style={{height:"32px",width:"32px",marginRight:"12px"}} onClick={() =>{ this.menuRef.open() }}/>
                    </IonToolbar>
                </IonHeader>
                }

                <IonMenu side="end" ref = {ref => this.menuRef=ref}>

                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{i18n.t("L_SETTINGS.L_TITLE")}</IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <IonList>

                            <IonItem onClick={() => {this.props.history.replace({pathname: '/Settings/MFA'}); this.menuRef.close()}}  button={true}>
                                <IonIcon src={iconLockOpen} style={{height:"25px",width:"25px",marginRight:"12px"}} color="primary"/>
                                <IonText>{i18n.t("L_SETTINGS.L_MFA")}</IonText>
                            </IonItem>

                            <IonItem onClick={() =>{this.onLogout(); this.menuRef.close()}} button={true}>
                                <IonIcon src={iconLogout} style={{height:"25px",width:"25px",marginRight:"12px"}} color="primary"/>
                                <IonLabel>{i18n.t("L_SETTINGS.L_LOGOUT")}</IonLabel>
                            </IonItem>

                        </IonList>
                    </IonContent>
                </IonMenu>

                <IonContent fullscreen="true">

                    {/* <IonHeader collapse="condense">
                        <IonToolbar className="ion-padding">
                            <IonImg src={logo} style={{width:"20%"}}/>
                        </IonToolbar>
                    </IonHeader> */}

                    <IonGrid>
                        <IonRow>
                            <IonCol size={3}>
                                <WidgetTopup height={"340px"} accountBalance={this.state.accountBalance}/>
                            </IonCol>

                            <IonCol size={3} style={{}}>
                                <WidgetTask type={"widget"}/>
                            </IonCol>

                            <IonCol size={3} style={{}}>
                                <ChartCard
                                    height = "340px"
                                    title  = {i18n.t('L_GRAPHS.L_APPOINTMENTS_TOTAL')}
                                    link   = {"Appointment/List"}
                                    chart  =
                                    {
                                        <Doughnut 
                                            data = {this.state.DoughnutDataAppointmentsTotal}
                                            options= {{responsive: true, maintainAspectRatio: false, legend: { position: "top" } }}
                                        />
                                    }
                                />
                            </IonCol>
                            
                            <IonCol size={3}>

                                <ChartCard
                                    height = "340px"
                                    title  = {i18n.t('L_GRAPHS.L_PROPERTIES_PUBLISHED')}
                                    link   = {"/Property/List"}
                                    chart  =
                                    { 
                                        <Doughnut 
                                            data   = {this.state.PieDataPropertiesPublished} 
                                            options= {{responsive: true, maintainAspectRatio: false, legend: { position: "top" } }}
                                        />
                                    }
                                />
                            </IonCol>

                        </IonRow>

                        <IonRow>
                            <IonCol size={6}>
                                <ChartCard
                                    height = "340px"
                                    title  = {i18n.t('L_GRAPHS.L_APPOINTMENTS_MONTHLY')}
                                    link   = {"Appointment/List"}
                                    chart  =
                                    { 
                                        <Bar 
                                            data   = {this.state.BarDataAppointments} 
                                            options= {{responsive: true, maintainAspectRatio: false, legend: {position: "top"} }}
                                        />
                                    }
                                    />
                            </IonCol>

                            <IonCol size={3}>
                                <ChartCard
                                        height = "340px"
                                        title  = {i18n.t('L_GRAPHS.L_APPOINTMENTS_TYPE')}
                                        link   = {"Appointment/List"}
                                        chart  =
                                        {
                                            <Doughnut 
                                                data   = {this.state.DoughnutDataAppointmentsType} 
                                                options= {{responsive: true, maintainAspectRatio: false, legend: { position: "top" } }}
                                            />
                                        }
                                />
                            </IonCol>
                            <IonCol size={3}>
                                <ChartCard
                                        height = "340px"
                                        title  = {i18n.t('L_GRAPHS.L_PROPERTIES_RATINGS')}
                                        link   = {"Property/List"}
                                        chart  =
                                        {
                                            <Doughnut 
                                                data   = {this.state.PieDataPropertiesRatings} 
                                                options= {{responsive: true, maintainAspectRatio: false, legend: { position: "top" } }}
                                            />
                                        }
                                />
                            </IonCol>
                        </IonRow>

                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}

export default React.memo(PageHome);
