/* global i18n:true, React:true */
import '../../global.js'
import AppContext from '../../AppContext'
import { IonItem,IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

/*


<CmpFrmItem key="0" item={this.state.form.fields[0]} onChange={this.onChange}/>


{
    dn        : 'property.residential.house.PrProperty_SubType_ID',
    name      : 'PrProperty_SubType_ID',
    value     : "",
    label     : "L_PRPROPERTY_SUBTYPE_ID",
    selections: ["1", "2", "3"],
    multi     : false,
    required  : true,
    regex     : "number-integer",
}
*/



class CmpFrmItemSelect extends React.Component
{
    static contextType = AppContext

    constructor()
    {
        super()

        console.log("Component", this.constructor.name)

        this.state =
        {
            position : "stacked" //floating fixed stacked
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.getSelectOptions  = this.getSelectOptions.bind(this)
    }

    componentDidMount()
    {
        console.log("componentDidMount", this.constructor.name,this.props)
    }

    getSelectOptions(selections)
    {
        let selectOptions = []

        let label = "D" + this.props.item.label.substring(1)

        for(let i = 0; i < this.props.item.selections.length; i++)
        {
            selectOptions.push(<IonSelectOption key={"selection"+i} value={this.props.item.selections[i]}>{i18n.t(label+"."+this.props.item.selections[i])}</IonSelectOption>)
        }

        return selectOptions
    }

    render()
    {
        return (

            <IonItem >

                <IonLabel 
                    position = { this.state.position}
                >
                    { i18n.t(this.props.item.label ) }
                    
                    { this.props.item.required &&
                        <ion-text color="danger">&nbsp;{i18n.t('L_REQUIRED_SIGN')}</ion-text>
                    }

                </IonLabel>

                <IonSelect
                    //interface        = { "popover" }
                    //interfaceOptions = {{cssClass: 'bc alert'}}
                    multiple         = { this.props.item.multi } // - ONLY ALERT INTERFACE WORKS WITH MULTIPLE SET TO TRUE
                    //style            = { {width:"100%"}}
                    id               = { this.props.id }
                    key              = { this.props.id }
                    name             = { this.props.item.name }
                    value            = { this.props.item.value }
                    disabled         = { this.props.item.disabled }
                    onIonChange      = { this.props.onChange }
                    placeholder      = { i18n.t(this.props.item.label+'_placeholder') }
                >
                    {this.getSelectOptions()}
                </IonSelect>

            </IonItem>
        )
    }
}

export default CmpFrmItemSelect